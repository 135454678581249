import { useState } from "react"
import { toast } from "react-toastify"

import { POST_FORM } from "modules/Core/api/REST"
import useAuthorizationHeader from "modules/Core/hooks/useAuthorizationHeader"
import {
    AdminForm,
    AdminFormButtons,
    AdminFormItem,
} from "pages/AdminPanelPage/modules/helpers/components/AdminForm/AdminForm.styled"
import Required from "pages/AdminPanelPage/modules/helpers/components/Required/Required"
import { CategoryType } from "types/common"
import { Button } from "ui/Button/Button.styled"
import { Input } from "ui/Input/Input.styled"

interface CreateCategoryFormProps {
    setCurrentState: React.Dispatch<React.SetStateAction<string>>
    categoryEdit?: CategoryType
}

const CreateCategoryForm: React.FC<CreateCategoryFormProps> = ({
    setCurrentState,
    categoryEdit,
}) => {
    const [categoryName, setCategoryName] = useState(categoryEdit?.name || "")
    const [selectedFile, setSelectedFile] = useState<File>()
    const headers = useAuthorizationHeader()

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target?.files

        if ((!files || files.length === 0) && !selectedFile) {
            setSelectedFile(undefined)
        } else if (files) {
            const file = files[0]
            setSelectedFile(file)
        }
    }

    const handleSubmit = async () => {
        if (!categoryName) {
            toast.warning("Заполните все обязательные поля!")
            return
        }

        try {
            const formData = new FormData()

            formData.append("name", categoryName)
            if (selectedFile && typeof selectedFile !== "string")
                formData.append("icon", selectedFile)

            const { data } = await POST_FORM(
                "/api/admin/categories/" + (categoryEdit?.id ? categoryEdit?.id : ""),
                formData,
                headers
            )

            if (data.ok === 1) {
                toast.success(`Категория успешно ${categoryEdit?.id ? "обновлена" : "создана"}!`)
            } else {
                throw new Error(JSON.stringify(data?.detail))
            }
        } catch (error) {
            toast.error("Произошла ошибка" + error)
        }
    }

    return (
        <AdminForm>
            <AdminFormItem>
                <label htmlFor="">
                    Название: <Required />
                </label>
                <Input
                    value={categoryName}
                    onChange={(e) => setCategoryName(e.currentTarget.value)}
                />
            </AdminFormItem>
            <AdminFormItem>
                <label htmlFor="">
                    Иконка: <Required />
                </label>
                <Input type="file" onChange={handleFileChange} accept="image/*" />
            </AdminFormItem>
            {categoryEdit?.icon && (
                <AdminFormItem>
                    <label htmlFor="">Текущая иконка: </label>
                    <img src={categoryEdit?.icon} width="36px" />
                </AdminFormItem>
            )}

            <AdminFormButtons>
                <Button
                    type="button"
                    btnColor="darkestGray"
                    onClick={() => setCurrentState("list")}
                >
                    Вернуться к списку
                </Button>
                <Button type="submit" onClick={handleSubmit}>
                    Сохранить
                </Button>
            </AdminFormButtons>
        </AdminForm>
    )
}

export default CreateCategoryForm
