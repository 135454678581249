import styled from "styled-components"

interface ImageProps {
    image?: string
}

export const StyledPostImages = styled.div`
    display: none;

    @media (min-width: 1024px) {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
    }
`

export const MainImage = styled.div<ImageProps>`
    width: 100%;
    min-height: 31.25rem;
    border-radius: ${({ theme }) => theme.radius.small};
    background: url("${({ image }) => image}") center center / contain no-repeat;
    transition: all 0.3s linear;
    position: relative;
`

export const ImageList = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1.875rem;
`

export const SmallImage = styled.div<ImageProps>`
    width: 64px;
    height: 64px;
    background: url("${({ image }) => image}") center center / cover no-repeat;
    border-radius: ${({ theme }) => theme.radius.small};
    cursor: pointer;

    &:hover {
        border: 1px solid ${({ theme }) => theme.colors.main};
    }
`
