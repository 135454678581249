import { useEffect, useState } from "react"

import { SearchResultType } from "types/common"

const useShowResults = (searchResults: SearchResultType[]) => {
    const [showResults, setShowResults] = useState(searchResults.length > 0)

    useEffect(() => {
        setShowResults(searchResults.length > 0)
    }, [searchResults])

    return { showResults, setShowResults }
}

export default useShowResults
