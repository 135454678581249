import styled from "styled-components"

import closeIcon from "assets/icons/close.svg"

interface SliderImageProps {
    image: string
}

export const StyledPostImageSlider = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: ${({ theme }) => theme.colors.darkBlue};
    z-index: 9999;

    display: flex;
    justify-content: center;
    align-items: center;
`

export const CloseButton = styled.div`
    width: 1.5rem;
    height: 1.5rem;
    background: url(${closeIcon}) center / contain no-repeat;
    position: absolute;
    right: 5%;
    top: 3%;
    cursor: pointer;
`

export const SliderImage = styled.div<SliderImageProps>`
    width: 100%;
    min-height: 31.25rem;
    background: url("${({ image }) => image}") center / contain no-repeat;
`
