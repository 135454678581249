import ReactStars from "react-stars"

import { Row } from "styles/Global.styled"

import { Note, StyledRating, Text } from "./Rating.styled"

import NoRating from "../NoRating/NoRating"

interface Rating {
    rating: number
}

const Rating: React.FC<Rating> = ({ rating }) => {
    return (
        <>
            {rating && rating !== 0 ? (
                <StyledRating>
                    <section>
                        <Row justify="space-between">
                            <ReactStars value={rating} size={30} edit={false} />
                            <Text>{rating.toFixed(1)}</Text>
                        </Row>
                        <Note>Рейтинг пользователя как Заказчика на платформе</Note>
                    </section>
                    <section>
                        <Row justify="space-between">
                            <ReactStars value={4} size={30} edit={false} />
                            <Text>{4}</Text>
                        </Row>
                        <Note>Рейтинг пользователя как Исполнителя на платформе</Note>
                    </section>
                </StyledRating>
            ) : (
                <NoRating />
            )}
        </>
    )
}

export default Rating
