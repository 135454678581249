import styled from "styled-components"

import trashIcon from "assets/icons/trash.svg"

export const StyledTrashButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.gray};
    padding: 0.625rem;
    border-radius: 16px;

    i {
        width: 1.4375rem;
        height: 1.5625rem;
        mask: url("${trashIcon}") center / contain no-repeat;
        background-color: ${({ theme }) => theme.colors.crimson};
    }
`
