import { useEffect, useState } from "react"

import { GET } from "modules/Core/api/REST"

export interface ComplaintType {
    message: string
    type: {
        key: number
        val: string
    }
}

const useComplaintList = () => {
    const [complaintList, setComplaintList] = useState<ComplaintType[]>([])

    useEffect(() => {
        const fGetList = async () => {
            const response = await GET("/api/admin/complaints")
            if (response.data) setComplaintList(response.data.data.complaint)
        }

        fGetList()
    }, [])

    return { complaintList }
}

export default useComplaintList
