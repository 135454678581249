import styled from "styled-components"

import Logo from "modules/components/Logo/Logo"

const StyledMobileImageDummy = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    min-height: 20.25rem;
    background-color: ${({ theme }) => theme.colors.black};

    @media all and (min-width: 480px) and (max-width: 1024px) {
        min-height: 31.25rem;
    }
`

const MobileImageDummy = () => {
    return (
        <StyledMobileImageDummy>
            <Logo color="main" />
        </StyledMobileImageDummy>
    )
}

export default MobileImageDummy
