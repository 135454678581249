import styled from "styled-components"

import banner from "assets/banner/banner.jpg"

export const StyledBanner = styled.div`
    display: none;

    @media (min-width: 1024px) {
        background: url(${banner}) center center / cover no-repeat;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 0 0 2.5rem;

        > div {
            gap: 24px;
        }
    }
`
