import styled from "styled-components"

import handshake from "assets/icons/handshake.svg"
import { GHeader, Row } from "styles/Global.styled"

interface UserAvatarProps {
    postCustomer?: boolean
}

export const StyledInboxMessagePage = styled.section``

export const Header = styled(GHeader)`
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
`

export const MessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 1rem 0 1.5rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid ${({ theme }) => theme.colors.grayHrE};
`

export const UserInfo = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`

export const UserAvatar = styled.div<UserAvatarProps>`
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme, postCustomer }) =>
        theme.colors[postCustomer ? "blue" : "purple"]};
    font-weight: 700;
    font-size: 1.0625rem;
`

export const UserName = styled.div`
    color: ${({ theme }) => theme.colors.lightBlackB};
`

export const ButtonRow = styled(Row)`
    margin-top: 1rem;
    justify-content: space-between;

    gap: 1rem;

    > button {
        @media (min-width: 1024px) {
            width: 30%;
        }

        i {
            display: flex;
            width: 0.95rem;
            height: 0.95rem;
            background: url(${handshake}) center/contain no-repeat;
        }
    }
`

export const Icons = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
`
