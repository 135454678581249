import { StyledEditButton } from "./EditButton.styled"

interface EditButtonProps {
    onClick?: React.MouseEventHandler<HTMLDivElement>
}

const EditButton: React.FC<EditButtonProps> = ({ onClick }) => {
    return (
        <StyledEditButton onClick={onClick}>
            <i />
        </StyledEditButton>
    )
}

export default EditButton
