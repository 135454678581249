import BottomBanner from "modules/components/BottomBanner/BottomBanner"
import Breadcrumbs from "modules/components/Breadcrumbs/Breadcrumbs"
import { Container, Page, TextPageContent, TextPageHeader } from "styles/Global.styled"

import { Image, StyledPage, VideoRow } from "./AboutCompanyPage.styled"

const routes = ["info", "about"]

const AboutCompanyPage = () => {
    return (
        <Page>
            <Container>
                <Breadcrumbs routes={routes} />
                <StyledPage>
                    <TextPageContent>
                        <h1>О сервисах JBF</h1>

                        <TextPageHeader style={{ marginTop: "2rem" }}>
                            Сайт объявлений JBF.kz создан для всех строителей Казахстана
                        </TextPageHeader>

                        <TextPageHeader>Для вашего удобства: </TextPageHeader>
                        <p>
                            База самых актуальных объявлений в строительной сфере от физических и
                            юридических лиц
                            <br />
                            Ежедневные обновления. <br />
                            Разнообразные опции для продвижения ваших объявлений. <br />
                            Отзывчивая и внимательная служба поддержки.
                            <br />
                        </p>
                        <TextPageHeader>
                            Используйте JBF.kz для дополнительного заработка:
                        </TextPageHeader>
                        <p>
                            JBF может стать отличным помощником для запуска собственного бизнеса.
                            Масштабируйте и развивайте свой бизнес.
                        </p>
                        <p>Доска объявлений JBF работает по всему Казахстану.</p>

                        <p>
                            Увеличивайте поток потенциальных заявок на свои объявления. Размещение
                            информации о ваших товарах или услугах на JBF приведет новых клиентов!
                        </p>
                        <TextPageHeader>JBF.kz в социальных сетях:</TextPageHeader>
                        <p>
                            В наших соцсетях мы рассказываем о новых возможностях платформы,
                            обсуждаем новости и отвечаем на ваши вопросы.
                        </p>

                        <TextPageHeader>Instagram:</TextPageHeader>
                        <p>
                            <a href="https://www.instagram.com/jbforcekz/">
                                https://www.instagram.com/jbforcekz/
                            </a>
                        </p>

                        <TextPageHeader>Facebook:</TextPageHeader>
                        <p>
                            <a href="https://www.facebook.com/jbfkz">
                                https://www.facebook.com/jbfkz
                            </a>
                        </p>
                    </TextPageContent>
                    <Image />
                </StyledPage>
                <VideoRow>
                    <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/2uwBUq165Vw?controls=0"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                    />

                    <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/R-MWdmMe6rc?controls=0"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                    />
                </VideoRow>
            </Container>

            <BottomBanner type="large" />
        </Page>
    )
}

export default AboutCompanyPage
