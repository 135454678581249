import { Carousel } from "react-responsive-carousel"

import { ImageType } from "types/common"

import { StyledPostImageSlider, CloseButton, SliderImage } from "./PostImageSlider.styled"

interface PostImageSliderProps {
    images?: ImageType[]
    handleClose: () => void
}

const PostImageSlider: React.FC<PostImageSliderProps> = ({ images, handleClose }) => {
    const settings = {
        swipeable: true,
        emulateTouch: true,
        showThumbs: false,
        showIndicators: false,
    }

    return (
        <StyledPostImageSlider>
            <CloseButton onClick={handleClose} />
            <Carousel {...settings}>
                {images && images.map((image) => <SliderImage image={image.img} key={image.id} />)}
            </Carousel>
        </StyledPostImageSlider>
    )
}

export default PostImageSlider
