import { useNavigate } from "react-router-dom"

import { SearchResultType } from "types/common"

import {
    Line,
    PostCategory,
    PostDescription,
    ResultList,
    ResultItem,
    StyledSearchResult,
} from "./SearchResult.styled"

interface SearchResultProps {
    data?: SearchResultType[]
}

const SearchResult: React.FC<SearchResultProps> = ({ data }) => {
    const navigate = useNavigate()

    return (
        <StyledSearchResult>
            <ResultList>
                {data &&
                    data.map((item) => (
                        <ResultItem
                            key={item.id}
                            onClick={() => navigate(`/catalogue/post/${item.id}`)}
                        >
                            <PostDescription>{item.description}</PostDescription>
                            <PostCategory>{item.category_name}</PostCategory>
                            <Line />
                        </ResultItem>
                    ))}
            </ResultList>
        </StyledSearchResult>
    )
}

export default SearchResult
