import styled from "styled-components"

import error from "assets/icons/error.svg"
import { fadeIn } from "styles/Animation.styled"

const StyledNoDataFound = styled.div`
    display: flex;
    align-items: center;
    margin-top: 2rem;
    gap: 1.1875rem;
    font-size: 1.5rem;
    font-family: "Montserrat";
    font-weight: 600;
    width: 100%;

    animation: ${fadeIn} 1s ease 0s 1 normal forwards;

    @media (min-width: 1024px) {
        margin-top: unset;
    }

    > i {
        width: 1.875rem;
        height: 1.875rem;
        background: url(${error}) center center/contain no-repeat;
    }
`

const NoDataFound = () => {
    return (
        <StyledNoDataFound>
            <i />
            Мы нашли 0 объявлений
        </StyledNoDataFound>
    )
}

export default NoDataFound
