import { createContext, useState } from "react"

import { PostType } from "types/common"

//eslint-disable-next-line
export const PostContext = createContext<any>({})

export const PostProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [page, setPage] = useState(1)
    const [postPerPage, setPostPerPage] = useState(5)
    const [inputValue, setInputValue] = useState("")

    const handleFirstPage = () => {
        setPage(1)
    }

    const handlePostPerPageChange = (value: number) => {
        setPage(1)
        setPostPerPage(value)
    }

    const handleInputValueChange = (value: string) => {
        setPage(1)
        setInputValue(value)
    }

    const handleNextPage = (list: PostType[]) => {
        if (list.length >= postPerPage) {
            setPage((current) => current + 1)
        }
    }

    const handlePrevPage = () => {
        if (page !== 1) setPage((current) => current - 1)
    }

    return (
        <PostContext.Provider
            value={{
                page,
                setPage,
                postPerPage,
                setPostPerPage,
                inputValue,
                handleInputValueChange,
                handleFirstPage,
                handlePostPerPageChange,
                handleNextPage,
                handlePrevPage,
            }}
        >
            {children}
        </PostContext.Provider>
    )
}

export default PostContext
