import styled from "styled-components"

import starIcon from "assets/icons/star.svg"
import starFillIcon from "assets/icons/starFill.svg"
import trashIcon from "assets/icons/trash.svg"
import { Button } from "ui/Button/Button.styled"

interface StarIconProps {
    liked: boolean
}

export const MessageHeaderRow = styled.div`
    display: none;

    @media (min-width: 1024px) {
        display: flex;
        align-items: center;
        gap: 1.3rem;
        padding: 0.75rem 0rem 0.75rem 1rem;
        margin-top: 0.75rem;
        color: ${({ theme }) => theme.colors.lightBlackB};
        font-weight: 400;
    }
`

export const MessageRow = styled(MessageHeaderRow)`
    padding: 0.75rem 1rem;
    background-color: ${({ theme }) => theme.colors.lightGray};
    border-radius: ${({ theme }) => theme.radius.large};
`

export const MessageIcons = styled.div`
    display: flex;
    gap: 1rem;
    min-width: 60px;
    max-width: 60px;
    width: 60px;
`

export const SenderName = styled.div`
    display: flex;
    max-width: 150px;
    width: 150px;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`

export const MessageContent = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
`

export const PostTitle = styled.div`
    font-weight: 600;
    max-width: 200px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    @media (min-width: 1340px) {
        max-width: 300px;
    }
`

export const MessageText = styled.div`
    max-width: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media (min-width: 1340px) {
        max-width: 240px;
    }
`

export const MessageDate = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    white-space: nowrap;
    width: fit-content;
    gap: 0.25rem;
    font-weight: 400;
    font-size: 0.75rem;
`

export const MessagesButton = styled(Button)`
    width: unset;
    font-size: 0.75rem;
    padding: 0.625rem 0.9375rem;
`

const Icon = styled.svg`
    width: 20px;
    height: 18px;
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.lightBlackB};

    &:hover {
        background-color: ${({ theme }) => theme.colors.main};
    }
`

export const TrashIcon = styled(Icon)`
    mask: url(${trashIcon}) center / contain no-repeat;
`

export const StarIcon = styled(Icon)<StarIconProps>`
    mask: url(${({ liked }) => (liked ? starFillIcon : starIcon)}) center / contain no-repeat;
    ${({ theme, liked }) => (liked ? `background-color: ${theme.colors.main};` : "")}
`
