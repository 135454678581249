import styled from "styled-components"

import starIcon from "assets/icons/star.svg"
import starFillIcon from "assets/icons/starFill.svg"

interface StarIconProps {
    liked: boolean
}

export const StyledMobileMessage = styled.div`
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 4px;
`

export const MessageRow = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1.5rem;
    background-color: ${({ theme }) => theme.colors.lightGray};
    padding: 1rem;
`

export const MessageContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    color: ${({ theme }) => theme.colors.lightBlackB};
    flex-grow: 1;
`

export const PostTitle = styled.div`
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 700;
`

export const MessageText = styled.div`
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
`

export const MessageIcons = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
`

export const MessageDate = styled.div`
    font-size: 0.8rem;
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    gap: 4px;
`

const Icon = styled.svg`
    width: 20px;
    height: 18px;
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.lightBlackB};

    &:hover {
        background-color: ${({ theme }) => theme.colors.main};
    }
`

export const StarIcon = styled(Icon)<StarIconProps>`
    mask: url(${({ liked }) => (liked ? starFillIcon : starIcon)}) center / contain no-repeat;
    ${({ theme, liked }) => (liked ? `background-color: ${theme.colors.main};` : "")}
`
