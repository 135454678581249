import { useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import Breadcrumbs from "modules/components/Breadcrumbs/Breadcrumbs"
import Like from "modules/components/Like/Like"
import SimillaPosts from "modules/components/SimillarPosts/SimillarPosts"
import useAuth from "modules/Core/hooks/useAuth"
import useMainContext from "modules/Core/hooks/useMainContext"
import { Container, Page, Row } from "styles/Global.styled"
import { PostType } from "types/common"
import { Button } from "ui/Button/Button.styled"

import ComplaintModal from "./modules/components/ComplaintModal/ComplaintModal"
import LeaveRequest from "./modules/components/LeaveRequest/LeaveRequest"
import MobilePostImages from "./modules/components/MobilePostImages/MobilePostImages"
import PostImages from "./modules/components/PostImages/PostImages"
import PostUserProfile from "./modules/components/PostUserProfile/PostUserProfile"
import { Price } from "./modules/components/PostUserProfile/PostUserProfile.styled"
import Rating from "./modules/components/Rating/Rating"
import Reviews from "./modules/components/Reviews/Reviews"
import Toggle from "./modules/components/Toggle/Toggle"
import usePostDetails from "./modules/hooks/usePostDetails"
import {
    Edit,
    EditAbsolute,
    Header,
    PostDescription,
    StyledPostPage,
    MobileLine,
    ReviewsLeaveRequestWrap,
    Title,
    MobileContent,
    CityTitle,
    MobileButtons,
    MobileHeader,
} from "./PostPage.styled"

const routes = ["post"]
const defaultToggle = "promote"

const PostPage = () => {
    const { auth } = useAuth()
    const { id } = useParams()
    const navigate = useNavigate()
    const { postDetails } = usePostDetails(Number(id))

    const isOwner = auth?.accessToken ? auth?.user?.email === postDetails?.partner?.email : false
    const refToLeaveRequest = useRef() as React.MutableRefObject<HTMLDivElement | null>

    const [activeToggle, setActiveToggle] = useState(defaultToggle)
    const [showComplaintModal, setShowComplaintModal] = useState(false)

    const { favouritePosts } = useMainContext()
    const isPostLiked =
        id && favouritePosts.some((post: PostType) => (post?.post_id || post?.id) === Number(id))

    const handleShowModal = () => {
        setShowComplaintModal(true)
    }

    const handleSendMessage = () => {
        if (!auth?.accessToken) navigate("/auth", { state: { from: location } })

        refToLeaveRequest?.current &&
            refToLeaveRequest.current.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "start",
            })
    }

    const handleCallPhone = () => {
        window.open(`tel:${postDetails?.partner?.phone}`, "_self")
    }

    const handleCloseModal = () => {
        setActiveToggle(defaultToggle)
        setShowComplaintModal(false)
    }

    const handleEdit = () => {
        navigate("/post/new", { state: { postDetails } })
    }

    const EditIcon: React.FC<{ absolute?: boolean }> = ({ absolute = false }) => {
        return (
            <>
                {isOwner &&
                    (absolute ? (
                        <EditAbsolute onClick={handleEdit}>
                            <i />
                        </EditAbsolute>
                    ) : (
                        <Edit onClick={handleEdit}>
                            <i />
                        </Edit>
                    ))}
            </>
        )
    }

    return (
        <Page>
            <MobileButtons>
                <Button btnColor="grayC4" onClick={handleSendMessage} disabled={isOwner}>
                    Написать
                </Button>
                <Button onClick={handleCallPhone} disabled={isOwner}>
                    Позвонить
                </Button>
            </MobileButtons>

            <MobilePostImages images={postDetails?.imgs} />

            <Container>
                <Breadcrumbs routes={routes} hide={true} />

                <Title>{postDetails?.post.title}</Title>

                <StyledPostPage>
                    <section>
                        <PostImages
                            mainImage={postDetails?.post.image}
                            images={postDetails?.imgs}
                            editIcon={<EditIcon absolute />}
                        />

                        <MobileContent>
                            <Row justify="space-between">
                                <MobileHeader>{postDetails?.post.title}</MobileHeader>
                                <Like postID={postDetails?.post.id} isLiked={isPostLiked} />
                            </Row>

                            <CityTitle>{postDetails?.city.name}</CityTitle>
                        </MobileContent>

                        <Header>
                            Об объявлении
                            {isOwner && <EditIcon />}
                        </Header>

                        <PostDescription>{postDetails?.post.description}</PostDescription>

                        <MobileLine />

                        <MobileContent>
                            <Row justify="space-between">
                                <MobileHeader>
                                    {postDetails?.post.price === 0 ? (
                                        "Цена договорная"
                                    ) : (
                                        <Price>{postDetails?.post.price.toLocaleString()}</Price>
                                    )}
                                </MobileHeader>
                            </Row>

                            {!isOwner && <Rating rating={postDetails?.post.rating || 0} />}
                        </MobileContent>

                        {!isOwner && (
                            <>
                                <Toggle
                                    activeToggle={activeToggle}
                                    setActiveToggle={setActiveToggle}
                                    handleShowModal={handleShowModal}
                                />

                                <ReviewsLeaveRequestWrap>
                                    <Reviews
                                        reviews={postDetails?.comments}
                                        hide={activeToggle !== "review"}
                                    />

                                    <LeaveRequest ref={refToLeaveRequest} />
                                </ReviewsLeaveRequestWrap>
                            </>
                        )}
                    </section>
                    <section>
                        <PostUserProfile
                            postDetails={postDetails}
                            handleSendMessage={handleSendMessage}
                            isOwner={isOwner}
                        />
                    </section>
                </StyledPostPage>

                {!isOwner && <SimillaPosts />}
            </Container>

            {showComplaintModal && <ComplaintModal handleClose={handleCloseModal} />}
        </Page>
    )
}

export default PostPage
