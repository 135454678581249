import moment from "moment"
import { useContext } from "react"
import { useNavigate } from "react-router-dom"

import { Row } from "styles/Global.styled"
import { UserAppealType } from "types/common"
import Checkbox from "ui/Checkbox/Checkbox"

import {
    MessageRow,
    SenderName,
    MessageIcons,
    MessageContent,
    PostTitle,
    MessageText,
    MessageDate,
    MessageHeaderRow,
    MessagesButton,
    TrashIcon,
    StarIcon,
} from "./DesktopMessage.styled"

import { AppealsListContext } from "../../AppealsList"

const DesktopMessage = () => {
    const appealsCtx = useContext(AppealsListContext)
    const navigate = useNavigate()

    const handleMessageClick = (event: React.MouseEvent, appeal: UserAppealType) => {
        navigate("/cabinet/appeals/message", { state: { appeal, id: appeal.order.id } })
        event.stopPropagation()
    }

    const isAllSelected = appealsCtx.selectedMessagesID.length === appealsCtx.userAppealList.length
    const allIds = appealsCtx.userAppealList.map((item: UserAppealType) => item.order.id)

    const handleDeleteSelected = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const { selectedMessagesID, handleArchive, handleDelete, appealType } = appealsCtx
        if (selectedMessagesID.length === 0) return

        if (appealType === "archive") handleDelete(appealsCtx.selectedMessagesID, event)
        else handleArchive(appealsCtx.selectedMessagesID, event)
    }

    return (
        <>
            <MessageHeaderRow>
                <Checkbox
                    checked={isAllSelected}
                    handleChange={() => appealsCtx.handleSelectAll(!isAllSelected, allIds)}
                />
                <MessageIcons>Все</MessageIcons>
                <SenderName>Имя</SenderName>
                <Row justify="space-between">
                    Объявление
                    <MessagesButton onClick={handleDeleteSelected}>
                        Удалить выбранные
                    </MessagesButton>
                </Row>
            </MessageHeaderRow>

            {appealsCtx.userAppealList.map((appeal: UserAppealType, index: number) => {
                const { order, post } = appeal
                const {
                    selectedMessagesID,
                    handleSelect,
                    favouriteIDS,
                    handleArchive,
                    handleFavourite,
                } = appealsCtx

                const isSelected = selectedMessagesID.includes(order.id)
                const isFavourite = favouriteIDS.includes(order.id)

                return (
                    <MessageRow key={index}>
                        <Checkbox
                            checked={isSelected}
                            handleChange={() => handleSelect(!isSelected, order.id)}
                        />
                        <MessageIcons>
                            <StarIcon
                                liked={isFavourite}
                                onClick={(event) => handleFavourite(order.id, !isFavourite, event)}
                            />
                            <TrashIcon onClick={(event) => handleArchive([order.id], event)} />
                        </MessageIcons>

                        <SenderName>{order.user_name}</SenderName>

                        <MessageContent onClick={(event) => handleMessageClick(event, appeal)}>
                            <PostTitle>{post.title}</PostTitle>
                            <MessageText>{order.comment}</MessageText>
                            <MessageDate>
                                <div>{moment(order.date).locale("ru").format("D MMMM")}</div>
                                <div>{moment(order.date).locale("ru").format("HH:mm")}</div>
                            </MessageDate>
                        </MessageContent>
                    </MessageRow>
                )
            })}
        </>
    )
}

export default DesktopMessage
