import styled from "styled-components"

const StyledUnauthorized = styled.div`
    font-size: 2rem;
    flex-grow: 15;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    text-align: center;
`

const Unauthorized = () => {
    return <StyledUnauthorized>У вас нет доступа на просмотр данной страницы</StyledUnauthorized>
}

export default Unauthorized
