import { useState } from "react"

import { Header } from "pages/AdminPanelPage/AdminPanelPage.styled"
import { PostType } from "types/common"

import EditPostForm from "./components/EditPostForm"
import PostList from "./components/PostList"
import { PostProvider } from "./context/PostContext"

const Post = () => {
    const [currentState, setCurrentState] = useState("list")
    const [postEdit, setPostEdit] = useState<PostType>()

    return (
        <>
            <Header>Объявления</Header>

            <PostProvider>
                {currentState === "list" ? (
                    <PostList setCurrentState={setCurrentState} setPostEdit={setPostEdit} />
                ) : (
                    <EditPostForm setCurrentState={setCurrentState} postEdit={postEdit} />
                )}
            </PostProvider>
        </>
    )
}

export default Post
