import styled from "styled-components"

import camera from "assets/cabinet/camera.svg"

export const StyledProfile = styled.section`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
`

export const ProfileContent = styled.div`
    display: flex;
    flex-direction: column-reverse;
    gap: 1.875rem;

    @media (min-width: 1024px) {
        display: grid;
        grid-template-columns: 2fr 1fr;
    }

    > form {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
`

export const FormItem = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;

    > label {
        font-size: 0.75rem;
        margin-left: 0.5rem;
    }
`

export const Note = styled.span`
    font-weight: 500;
    font-size: 0.75rem;
    color: ${({ theme }) => theme.colors.darkGray};
`

export const ProfileAvatar = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;

    height: 10rem;
    width: 10rem;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.lightGray};
    color: ${({ theme }) => theme.colors.darkGray};
    border: 1px dashed ${({ theme }) => theme.colors.darkGray};

    position: relative;
    cursor: pointer;
    font-size: 0.75rem;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    > i {
        width: 28px;
        height: 24px;
        background: url(${camera}) center center/contain no-repeat;
    }

    &::after {
        position: absolute;
        content: "Вы можете прикрепить файл до 100 МБ в формате: jpeg, png, jpg - отображается в карточке объявления";
        left: 110%;
        width: 100%;
        color: ${({ theme }) => theme.colors.black};
        font-weight: 400;
        line-height: 0.875rem;

        @media (min-width: 1024px) {
            left: unset;
            bottom: -5rem;
        }
    }
`
