import { useState } from "react"
import { Helmet } from "react-helmet"
import { Link } from "react-router-dom"

import { Container } from "styles/Global.styled"
import { categoriesAboutData } from "utils/helpers/list/categoriesAboutData"

import { Center, Content, Table } from "./CategoriesAbout.styled"

import { AboutBlocks, ButtonWrapper, StyledAbout, ToggleButton } from "../AboutComponent.styled"

interface CategoriesAboutProps {
    categoryID: number
    withOutButton?: boolean
}

const CategoriesAbout: React.FC<CategoriesAboutProps> = ({ categoryID, withOutButton = false }) => {
    const category = categoriesAboutData.find((item) => item.categoryID === categoryID)
    const [showAll, setShowAll] = useState(withOutButton || false)

    const handleToggle = () => {
        setShowAll((current) => !current)
    }

    const buttonText = showAll ? "Скрыть" : "Подробнее"

    return (
        <Container>
            <Helmet>
                <title>{category?.metaTitle}</title>
                <meta property="og:title" content={category?.metaTitle} />
                <meta name="description" content={category?.metaDescription} />
                <meta property="og:description" content={category?.metaDescription} />
            </Helmet>
            <StyledAbout>
                {category && (
                    <AboutBlocks isPage={withOutButton}>
                        <Content showAll={showAll}>
                            {category.text.map((text) => (
                                <p key={text}>{text}</p>
                            ))}

                            <Center>
                                <p>{category.table.header}</p>
                                <b>{category.table.text}</b>
                            </Center>

                            <Table>
                                <tbody>
                                    {category.table.content.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item[0]}</td>
                                            <td>{item[1]}</td>
                                            <td>
                                                заходи на <Link to="/">JBF.KZ</Link>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>

                            {category.postText.map((text) => (
                                <p key={text}>{text}</p>
                            ))}
                        </Content>
                        {!withOutButton && (
                            <ButtonWrapper>
                                <ToggleButton onClick={handleToggle}>{buttonText}</ToggleButton>
                            </ButtonWrapper>
                        )}
                    </AboutBlocks>
                )}
            </StyledAbout>
        </Container>
    )
}

export default CategoriesAbout
