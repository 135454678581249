import styled from "styled-components"

interface StyledPostImageProps {
    height?: string
}

export const StyledPostImage = styled.div<StyledPostImageProps>`
    min-height: ${({ height }) => height || "200px"};
    cursor: pointer;
    position: relative;
`

export const DefaultImage = styled.div`
    position: absolute;
    top: 0;
    left: 0;

    border-radius: ${({ theme }) => theme.radius.large};

    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.black};

    &::after {
        content: "JBF";
        top: 50%;
        left: 50%;
        color: ${({ theme }) => theme.colors.main};
        font-family: Druk Wide Bold, sans-serif;
        font-size: 1.1875rem;
        line-height: 1.4375rem;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
`

export const Image = styled.div`
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    z-index: 1;

    position: absolute;
    border-radius: ${({ theme }) => theme.radius.large};
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
`
