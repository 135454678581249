import styled from "styled-components"

import exit from "assets/icons/exit.svg"
import burger from "assets/mobile/burger.svg"
import close from "assets/mobile/close.svg"
import { Row } from "styles/Global.styled"

interface SideBarController {
    show: boolean
}

export const StyledSmallNavbar = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.black};
    position: sticky;
    top: 0;
    width: 100%;
    font-size: 0.75rem;
    z-index: 1000;

    > div {
        padding: 3.5px;

        @media (min-width: 1024px) {
            padding: 0rem 1rem;
        }
    }
`

export const List = styled.div`
    display: none;

    @media (min-width: 1024px) {
        display: flex;
        gap: 1.625rem;
        padding: 0.5625rem 0;
    }

    a {
        color: ${({ theme }) => theme.colors.white};
        text-decoration: none;
        font-weight: 500;
        line-height: 0.875rem;
    }
`

export const Exit = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: ${({ theme }) => theme.colors.white};

    cursor: pointer;

    > i {
        width: 16px;
        height: 16px;
        background: url(${exit}) center / contain no-repeat;
    }
`

export const SmallNavbarRow = styled(Row)`
    gap: 1rem;
    @media (min-width: 1024px) {
        display: none;
    }
`

export const SideBarController = styled.svg<SideBarController>`
    width: 2.2rem;
    height: 2rem;
    mask: url(${({ show }) => (show ? close : burger)}) center / contain no-repeat;
    background-color: ${({ theme }) => theme.colors.white};
    transition: all 0.5s ease-out;
`
