import { useEffect, useState } from "react"

interface OptionType {
    value: string | number
    label: string | number
}

//eslint-disable-next-line
const useOptions = (data: any[], labelField = "name", valueField = "id") => {
    const [options, setOptions] = useState<OptionType[]>([])

    useEffect(() => {
        if (data && data.length > 0)
            setOptions(
                data.map((item) => {
                    return { label: item[labelField], value: item[valueField] }
                })
            )
    }, [])

    return { options, setOptions }
}

export default useOptions
