import { useState } from "react"

import { DefaultImage } from "modules/components/PostList/modules/components/PostImage/PostImage.styled"
import { ImageType } from "types/common"

import { ImageList, MainImage, SmallImage, StyledPostImages } from "./PostImages.styled"

import useImageSlider from "../../hooks/useImageSlider"
import PostImageSlider from "../PostImageSlider/PostImageSlider"

interface PostImagesProps {
    mainImage?: string
    images?: ImageType[]
    editIcon?: React.ReactElement
}

const PostImages: React.FC<PostImagesProps> = ({ mainImage, images = [], editIcon }) => {
    const [image, setImage] = useState<string>("")
    const { showImageSlider, handleShowSlider, handleCloseSlider } = useImageSlider(images)

    return (
        <StyledPostImages>
            <MainImage image={image || mainImage} onClick={handleShowSlider}>
                {images.length === 0 && <DefaultImage />}
                {editIcon}
            </MainImage>

            {images.length > 0 && (
                <ImageList>
                    {images.map((image) => (
                        <SmallImage
                            key={image.id}
                            image={image.img}
                            onClick={() => setImage(image.img)}
                        />
                    ))}
                </ImageList>
            )}

            {showImageSlider && <PostImageSlider images={images} handleClose={handleCloseSlider} />}
        </StyledPostImages>
    )
}

export default PostImages
