import axios from "axios"
import { useEffect, useState } from "react"
import { Outlet } from "react-router-dom"

import Loading from "modules/components/Loading/Loading"

import useAuth from "../hooks/useAuth"

interface AuthType {
    accessToken: string
    user: Record<string, string>
    role?: string
}

const PersistLogin = () => {
    const [isLoading, setIsLoading] = useState(true)
    const { setAuth } = useAuth()

    let authCredetials: AuthType = { accessToken: "", user: {} }

    try {
        authCredetials = JSON.parse(localStorage.getItem("auth_user_credentials") || "") || ""
    } catch (e) {
        authCredetials = { accessToken: "", user: {} }
    }

    useEffect(() => {
        const verifyToken = async () => {
            try {
                const headers = {
                    Authorization: `Bearer ${authCredetials?.accessToken}`,
                }
                await axios.get("/api/accounts/auth/", { headers })
                setAuth(authCredetials)
            } catch (err) {
                setAuth({})
            } finally {
                setIsLoading(false)
            }
        }

        authCredetials.accessToken ? verifyToken() : setIsLoading(false)
    }, [])

    return <>{isLoading ? <Loading /> : <Outlet />}</>
}

export default PersistLogin
