import styled from "styled-components"

export const Table = styled.table`
    border-collapse: collapse;
    margin-top: 1.5rem;

    thead > tr > td {
        padding: 1rem 0.5rem;
    }

    tbody > tr > td {
        padding: 0.5rem;
    }

    thead > tr > td:nth-child(1) {
        border-top-left-radius: 0.5rem;
    }
    thead > tr > td:last-child {
        border-top-right-radius: 0.5rem;
    }

    thead > tr > td:nth-child(1),
    tbody > tr > td:nth-child(1) {
        text-align: center;
        width: 50px;
        max-width: 50px;
    }

    thead > tr > td:last-child,
    tbody > tr > td:last-child {
        width: 130px;
        max-width: 130px;
        text-align: center;
    }

    thead > tr > td {
        background-color: #dddde9;
    }

    tbody > tr > td {
        background-color: #fff;
        border-bottom: 1px solid #cccccc;
    }

    thead > tr > td:nth-child(1) > input,
    tbody > tr > td:nth-child(1) > input {
        scale: 1.5;
        accent-color: ${({ theme }) => theme.colors.main};
    }
`
