import { Formik, Form, Field, ErrorMessage } from "formik"
import { useState } from "react"
import * as Yup from "yup"

import Modal, { CloseButton } from "modules/components/Modal/Modal"
import Success from "modules/components/Success/Success"
import { POST } from "modules/Core/api/REST"
import useAuth from "modules/Core/hooks/useAuth"
import useAuthorizationHeader from "modules/Core/hooks/useAuthorizationHeader"
import { CustomFormItem } from "styles/Global.styled"
import { Button } from "ui/Button/Button.styled"
import { renderError } from "utils/helpers"
import { InputComponent, TextareaComponent } from "utils/helpers/components/FormikField"

import { Header, Section, StyledFeedbackModal, CustomFormModal } from "./FeedbackModal.styled"

interface FeedbackModalProps {
    handleClose: () => void
}

interface FeedbackForm {
    name: string
    contact: string
    message: string
}

const validationSchema = Yup.object({
    name: Yup.string().required("Заполните имя"),
    contact: Yup.string().required("Заполните номер телефон/email"),
    message: Yup.string().required("Заполните Ваше пожелания"),
})

const FeedbackModal: React.FC<FeedbackModalProps> = ({ handleClose }) => {
    const { auth } = useAuth()
    const [feedbackSended, setFeedbackSended] = useState(false)

    const initialValues = {
        name: auth?.user?.first_name || "",
        contact: auth?.user?.email || "",
        message: "",
    }

    const headers = useAuthorizationHeader()

    const handleSubmit = async (values: FeedbackForm) => {
        try {
            await POST("/api/service/wishes/", { ...values }, headers)

            const timer = setTimeout(() => {
                handleClose()
            }, 3000)
            return () => clearTimeout(timer)
        } catch (error) {
            console.log(error)
        } finally {
            setFeedbackSended(true)
        }
    }

    return (
        <Modal>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
                validateOnBlur={false}
            >
                <Form>
                    <StyledFeedbackModal>
                        <Section>
                            <Header>Есть пожелания по сервису?</Header>
                            Оставьте в форме ниже и мы обязательно рассмотрим
                        </Section>
                        {!feedbackSended ? (
                            <CustomFormModal>
                                <CustomFormItem>
                                    <label htmlFor="">Ваше имя:</label>
                                    <Field name="name" component={InputComponent} />
                                    <ErrorMessage name="name" render={renderError} />
                                </CustomFormItem>
                                <CustomFormItem>
                                    <label htmlFor="">Email/телефон:</label>
                                    <Field name="contact" component={InputComponent} />
                                    <ErrorMessage name="name" render={renderError} />
                                </CustomFormItem>
                                <CustomFormItem>
                                    <label htmlFor="">Ваше сообщение:</label>
                                    <Field name="message" component={TextareaComponent} />
                                    <ErrorMessage name="message" render={renderError} />
                                </CustomFormItem>
                                <Button>Отправить</Button>
                            </CustomFormModal>
                        ) : (
                            <Success caption="Мы ознакомимся с Вашими пожеланиями в ближайшее время!" />
                        )}
                        <CloseButton onClick={handleClose} />
                    </StyledFeedbackModal>
                </Form>
            </Formik>
        </Modal>
    )
}

export default FeedbackModal
