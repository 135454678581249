import styled from "styled-components"

import { fadeIn } from "styles/Animation.styled"

export const StyledPost = styled.div`
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
    border-radius: ${({ theme }) => theme.radius.large};
    padding: 8px;
    min-height: 21.875rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;

    animation: ${fadeIn} 1s ease 0s 1 normal forwards;
`
