import { useEffect, useState } from "react"

import { CategoryType } from "types/common"

import { GET } from "../api/REST"

const useCategoryList = () => {
    const [categoryList, setCategoryList] = useState<CategoryType[]>()

    const fGetCategoryList = async () => {
        const response = await GET("/api/service/category/all")
        if (response && response.data) {
            const { data } = response.data
            const categoryList = Object.keys(data).map((category) => data[category])
            setCategoryList(categoryList)
        }
    }

    useEffect(() => {
        fGetCategoryList()
    }, [])

    return { categoryList }
}

export default useCategoryList
