import styled from "styled-components"

interface StyledBreadcrumbsProps {
    color?: string
    lastColor?: string
    hide?: boolean
}

export const StyledBreadcrumbs = styled.section<StyledBreadcrumbsProps>`
    display: ${({ hide }) => (hide ? "none" : "block")};

    @media (min-width: 1024px) {
        display: block;
    }

    ul {
        font-size: 0.75rem;
        list-style: none;
        padding: 0;
        margin-bottom: 0px;
        padding-top: 1.5rem;

        li {
            display: inline;

            a {
                text-decoration: underline;
                color: ${({ theme, color }) => theme.colors[color || "black"]};
            }
        }

        li + li:before {
            margin: 0 8px;
            content: ">";
            color: ${({ theme, color }) => theme.colors[color || "black"]};
        }

        li:last-child a {
            color: ${({ theme, lastColor }) => theme.colors[lastColor || "main"]};
            text-decoration: none;
        }
    }
`
