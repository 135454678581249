import { useState } from "react"
import Select from "react-select"
import { toast } from "react-toastify"

import { POST_FORM } from "modules/Core/api/REST"
import useAuthorizationHeader from "modules/Core/hooks/useAuthorizationHeader"
import {
    AdminForm,
    AdminFormButtons,
    AdminFormItem,
} from "pages/AdminPanelPage/modules/helpers/components/AdminForm/AdminForm.styled"
import Required from "pages/AdminPanelPage/modules/helpers/components/Required/Required"
import { SliderType } from "types/common"
import { Button } from "ui/Button/Button.styled"
import { Input } from "ui/Input/Input.styled"

import useCategoryList from "../../Category/hooks/useCategoryList"

interface CreateSliderFormProps {
    setCurrentState: React.Dispatch<React.SetStateAction<string>>
    sliderEdit?: SliderType
}

const CreateSliderForm: React.FC<CreateSliderFormProps> = ({ setCurrentState, sliderEdit }) => {
    const [sliderName, setSliderName] = useState(sliderEdit?.name || "")
    const [sliderText, setSliderText] = useState(sliderEdit?.text || "")
    const [sliderOrder, setSliderOrder] = useState(sliderEdit?.order || 0)
    const [sliderLink, setSliderLink] = useState(sliderEdit?.link || "")

    const { categoryList } = useCategoryList()
    const categoryOptions = categoryList.map((category) => {
        return { label: category.name, value: category.id }
    })

    const [selectedFile, setSelectedFile] = useState<File>()
    const headers = useAuthorizationHeader()

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target?.files

        if ((!files || files.length === 0) && !selectedFile) {
            setSelectedFile(undefined)
        } else if (files) {
            const file = files[0]
            setSelectedFile(file)
        }
    }

    const handleSubmit = async () => {
        if (!sliderName || !sliderLink) {
            toast.warning("Заполните все обязательные поля!")
            return
        }

        try {
            const formData = new FormData()

            formData.append("name", sliderName)
            formData.append("link", sliderLink)
            formData.append("text", sliderText)
            formData.append("order", String(sliderOrder))

            if (selectedFile && typeof selectedFile !== "string")
                formData.append("image", selectedFile)

            const { data } = await POST_FORM(
                "/api/admin/sliders/" + (sliderEdit?.id ? sliderEdit?.id : ""),
                formData,
                headers
            )

            if (data.ok === 1) {
                toast.success(`Слайд успешно ${sliderEdit?.id ? "обновлен" : "создан"}!`)
            } else {
                throw new Error(JSON.stringify(data?.detail))
            }
        } catch (error) {
            toast.error("Произошла ошибка" + error)
        }
    }

    return (
        <AdminForm>
            <AdminFormItem>
                <label htmlFor="">
                    Название: <Required />
                </label>
                <Input value={sliderName} onChange={(e) => setSliderName(e.currentTarget.value)} />
            </AdminFormItem>
            <AdminFormItem>
                <label htmlFor="">Текст:</label>
                <Input value={sliderText} onChange={(e) => setSliderText(e.currentTarget.value)} />
            </AdminFormItem>
            <AdminFormItem>
                <label htmlFor="">Номер по счету:</label>
                <Input
                    type="number"
                    value={sliderOrder}
                    onChange={(e) => setSliderOrder(+e.currentTarget.value)}
                />
            </AdminFormItem>
            <AdminFormItem>
                <label htmlFor="">
                    Ссылка на категорию: <Required />
                </label>
                {/* <Input value={sliderLink} onChange={(e) => setSliderLink(e.currentTarget.value)} /> */}
                <Select
                    value={
                        (categoryOptions
                            ? categoryOptions.find((option) => String(option.value) === sliderLink)
                            : //eslint-disable-next-line
                              "") as any
                    }
                    placeholder={"Выберите один из вариантов"}
                    noOptionsMessage={() => "Данных нет"}
                    options={categoryOptions}
                    onChange={(value) => setSliderLink(String(value?.value))}
                />
            </AdminFormItem>
            <AdminFormItem>
                <label htmlFor="">Картинка:</label>
                <Input type="file" onChange={handleFileChange} accept="image/*" />
            </AdminFormItem>
            {sliderEdit?.image && (
                <AdminFormItem>
                    <label htmlFor="">Текущая картинка: </label>
                    <img src={sliderEdit?.image} width="554px" />
                </AdminFormItem>
            )}

            <AdminFormButtons>
                <Button
                    type="button"
                    btnColor="darkestGray"
                    onClick={() => setCurrentState("list")}
                >
                    Вернуться к списку
                </Button>
                <Button type="submit" onClick={handleSubmit}>
                    Сохранить
                </Button>
            </AdminFormButtons>
        </AdminForm>
    )
}

export default CreateSliderForm
