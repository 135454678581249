import styled from "styled-components"

export const AdminForm = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem 1rem;
    background: rgba(255, 255, 255, 0.05);
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.05);
    border-radius: 16px;
    margin-top: 1.5rem;
    width: 65%;

    > form {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;
    }
`

export const AdminFormItem = styled.div`
    display: grid;
    grid-template-columns: 1fr 3fr;
    align-items: center;
    gap: 2rem;

    label {
        font-weight: 700;
        font-size: 1rem;
        line-height: 1.1875rem;
    }
`

export const PostTitle = styled.h1`
    font-size: 1.5rem;
    font-weight: 400;
`

export const AdminImagesGrid = styled.div`
    margin-top: 1rem;
    display: grid;
    grid-template-columns: repeat(6, minmax(auto, 1fr));
    gap: 1rem;

    div {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        min-height: 64px;
        max-height: 64px;
        min-width: 64px;
        max-width: 64px;
        border-radius: 5px;
        cursor: pointer;
    }
`

export const AdminPostMainImage = styled.div`
    min-width: 554px;
    min-height: 300px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    border: 1px solid #ccc;
    border-radius: 5px;
`

export const AdminFormButtons = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 4rem;

    button {
        width: max-content;
    }
`
