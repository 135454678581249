import styled from "styled-components"

import close from "assets/icons/close.svg"
import warning from "assets/icons/warning.svg"
import { bounceRight } from "styles/Animation.styled"

export const StyledToast = styled.div`
    display: flex;
    gap: 0.75rem;
    padding: 1rem;
    border-radius: ${({ theme }) => theme.radius.small};
    border-left: 2px solid ${({ theme }) => theme.colors.main};
    box-shadow: 0px 0px 0px 1px rgba(25, 37, 50, 0.1), 0px -6px 16px -6px rgba(25, 37, 50, 0.03),
        0px 8px 16px -8px rgba(25, 37, 50, 0.2), 0px 13px 27px -5px rgba(25, 37, 50, 0.15);

    background-color: ${({ theme }) => theme.colors.white};

    min-width: 350px;
    max-width: 400px;

    position: fixed;

    right: 3%;
    top: calc(110px + 10px);

    animation: ${bounceRight} 1s ease 0s 1 normal forwards;
    z-index: 995;
`

export const Icon = styled.i`
    background: url(${warning}) center / contain no-repeat;
    min-width: 22px;
    height: 19px;
`

export const CloseIcon = styled.i`
    background: url(${close}) center / contain no-repeat;
    min-width: 10px;
    height: 10px;
    cursor: pointer;

    &:hover {
        opacity: 0.5;
        transform: scale(1.1);
    }
`

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
`

export const Header = styled.div`
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.25rem;
    display: flex;
    justify-content: space-between;
`

export const Text = styled.div`
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
`

export const CloseText = styled(Text)`
    text-decoration: underline;
    cursor: pointer;
    width: fit-content;

    &:hover {
        opacity: 0.5;
    }
`
