import styled from "styled-components"

import icon from "assets/icons/plus.svg"

export const StyledNoRating = styled.div`
    display: flex;
    padding: 1.5rem 1rem;
    background: ${({ theme }) => theme.colors.darkGrayE};
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.05);
    border-radius: ${({ theme }) => theme.radius.huge};
    gap: 1.25rem;
`

export const Icon = styled.svg`
    width: 24px;
    height: 24px;
    mask-image: url(${icon});
    mask-size: contain;
    mask-position: center;
    background-color: ${({ theme }) => theme.colors.main};
    mask-repeat: no-repeat;
`

export const Caption = styled.div`
    display: flex;
    flex-direction: column;
    gap: 11px;

    font-size: 0.875rem;
    line-height: 1rem;
    font-weight: 400;

    a {
        font-size: 0.75rem;
        line-height: 0.875rem;
        font-weight: 400;
        color: ${({ theme }) => theme.colors.main};
    }
`
