import styled from "styled-components"

interface StyledMobileMenuProps {
    hide: boolean
}
interface MobileMenuItemProps {
    icon: string
}

export const StyledMobileMenu = styled.div<StyledMobileMenuProps>`
    display: ${({ hide }) => (hide ? "none" : "flex")};
    justify-content: space-between;
    align-items: center;

    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 994;

    padding: 1rem 1.5rem;
    background-color: ${({ theme }) => theme.colors.black};

    @media (min-width: 480px) and (max-width: 1023px) {
        justify-content: space-around;
    }

    @media (min-width: 1024px) {
        display: none;
    }
`

export const MobileMenuItem = styled.div<MobileMenuItemProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;

    font-size: 1rem;
    color: ${({ theme }) => theme.colors.white};
    font-weight: 400;

    > svg {
        width: 1.5rem;
        height: 1.5rem;
        mask: url(${({ icon }) => icon}) center / contain no-repeat;
        background-color: ${({ theme }) => theme.colors.white};
    }
`
