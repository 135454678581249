/*eslint-disable */
import { FieldInputProps, FormikState, FieldProps } from "formik"
import { Input } from "ui/Input/Input.styled"
import { Textarea } from "ui/Textarea/Textarea.styled"
import Select from "react-select"
import { selectStyle } from "../variables"
import { OptionType } from "types/common"

interface ComponentProps {
    field: FieldInputProps<any>
    form: FormikState<any>
}

export const InputComponent: React.FC<ComponentProps> = ({ field, form, ...props }) => (
    <Input {...field} {...props} />
)
export const TextareaComponent: React.FC<ComponentProps> = ({ field, form, ...props }) => (
    <Textarea {...field} {...props} rows={5} />
)

export const SelectСomponent = ({
    field,
    form: { setFieldValue },
    ...props
}: FieldProps & { placeholder?: string; options: OptionType[] }) => {
    const { options, placeholder } = props

    return (
        <Select
            {...field}
            {...props}
            options={options}
            value={(options ? options.find((option) => option.value === field.value) : "") as any}
            onChange={(option) => setFieldValue(field.name, option?.value || "")}
            placeholder={placeholder || "Выберите один из вариантов"}
            noOptionsMessage={() => "Данных нет"}
            styles={selectStyle}
            isClearable={true}
            isSearchable={false}
            components={{
                IndicatorSeparator: () => null,
            }}
        />
    )
}
