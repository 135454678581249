import { StyledTrashButton } from "./TrashButton.styled"

interface TrashButtonProps {
    onClick?: React.MouseEventHandler<HTMLDivElement>
}

const TrashButton: React.FC<TrashButtonProps> = ({ onClick }) => {
    return (
        <StyledTrashButton onClick={onClick}>
            <i />
        </StyledTrashButton>
    )
}

export default TrashButton
