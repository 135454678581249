import { useEffect, useState } from "react"

import { GET } from "modules/Core/api/REST"
import useAuthorizationHeader from "modules/Core/hooks/useAuthorizationHeader"
import { PostType } from "types/common"

const useUserPosts = (postType: string) => {
    const [isLoading, setIsLoading] = useState(true)
    const [userPostList, setUserPostList] = useState<PostType[]>([])

    const headers = useAuthorizationHeader()

    const fGetFavourites = async () => {
        try {
            const response = await GET("/api/service/favourites", {}, { headers })

            const { favourites } = response.data.data

            setUserPostList(favourites)
        } catch (err) {
            setUserPostList([])
        } finally {
            setIsLoading(false)
        }
    }

    const fGetPostList = async () => {
        try {
            const response = await GET(
                `/api/service/user/?is_active=${postType === "active"}`,
                {},
                { headers }
            )

            const { posts } = response.data.data

            setUserPostList(posts)
        } catch (err) {
            setUserPostList([])
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        postType === "favourite" ? fGetFavourites() : fGetPostList()
    }, [postType])

    return { userPostList, setUserPostList, isLoading }
}

export default useUserPosts
