import styled from "styled-components"

import FacebookIcon from "assets/socials/facebook.svg"
import InstagramIcon from "assets/socials/instagram.svg"
import TelegramIcon from "assets/socials/telegram.svg"
import VKIcon from "assets/socials/vk.svg"
import WhatsAppIcon from "assets/socials/whatsapp.svg"

export const StyledSocials = styled.div`
    display: flex;
    flex-wrap: nowrap;
    gap: 0.5rem;
    align-items: center;
    margin-top: 0.5rem;
`

export const Icon = styled.div`
    width: 32px;
    height: 32px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
`

export const WhatsApp = styled(Icon)`
    background-image: url(${WhatsAppIcon});
`
export const VK = styled(Icon)`
    background-image: url(${VKIcon});
`
export const Facebook = styled(Icon)`
    background-image: url(${FacebookIcon});
`
export const Instagram = styled(Icon)`
    background-image: url(${InstagramIcon});
`
export const Telegram = styled(Icon)`
    background-image: url(${TelegramIcon});
`
