import useAuth from "modules/Core/hooks/useAuth"
import { PostDetailsType } from "types/common"
import { PRIVATE_OPTIONS } from "utils/helpers/list"

const useInitialValues = (postDetails: PostDetailsType) => {
    const { auth } = useAuth()

    const isPrivate = postDetails?.post.is_private ? 1 : 2

    const price =
        postDetails?.post.price === 0 || !postDetails?.post.price ? "" : postDetails?.post.price

    const initialValues = {
        title: postDetails?.post.title || "",
        description: postDetails?.post.description || "",
        category: postDetails?.category.id,
        city: postDetails?.city.id,
        isPrivate: PRIVATE_OPTIONS[isPrivate]?.value || 1,
        price: price,
        firstName: postDetails?.partner.first_name || auth?.user?.first_name || "",
        phone: (postDetails?.partner.phone || auth?.user?.phone || "").replaceAll(" ", ""),
    }

    return { initialValues }
}

export default useInitialValues
