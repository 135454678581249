import styled from "styled-components"

import { scaleForward } from "styles/Animation.styled"
import { CustomForm, GHeader } from "styles/Global.styled"

export const StyledFeedbackModal = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    background-color: ${({ theme }) => theme.colors.white};
    padding: 1.5rem 3rem;
    border-radius: ${({ theme }) => theme.radius.large};
    position: relative;

    animation: ${scaleForward} 1s ease 0s 1 normal forwards;
`

export const Section = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const Header = styled(GHeader)`
    font-weight: 700;
    font-size: 1.25rem;
`

export const CustomFormModal = styled(CustomForm)`
    min-width: 21.875rem;
`
