import styled from "styled-components"

import ellipse from "assets/mainMenuBanner/carousel/ellipse.svg"
import { GHeader } from "styles/Global.styled"

interface CarouselPictureProps {
    url: string
    index: number
    caption?: string
    backgroundColor?: string
}

interface CarouselControllerProps {
    color?: string
}

export const Carousel = styled.div`
    height: 18.75rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    @media (min-width: 1400px) {
        height: 100%;
    }
`

export const CarouselPicture = styled.div<CarouselPictureProps>`
    /* background: url("${({ url }) => url}") right bottom no-repeat; */
    /* background-size: ${({ backgroundColor }) => (backgroundColor ? "contain" : "cover")}; */
    background: url("${({ url }) => url}") center/cover no-repeat;
    width: 100%;
    height: 100%;
    border-radius: ${({ theme }) => theme.radius.large};
    transition: background 1s ease-in-out;
    position: relative;
    background-color: ${({ backgroundColor }) => backgroundColor};

    /* &::before {
        content: "${({ caption }) => caption}";
        position: absolute;
        top: 0%;
        left: 50%;
        transform: translate(-50%);

        font-family: "Montserrat";
        font-size: 1rem;
        line-height: 1.125rem;
        color: ${({ theme }) => theme.colors.white};
        font-weight: 700;

        padding: 2.9375rem 2.75rem;
        border-radius: ${({ theme }) => theme.radius.small};
        background-color: ${({ theme, index }) => theme.colors[index !== 1 ? "main" : "black"]};

        display: flex;
        justify-content: center;
        text-align: center;

        @media (min-width: 1024px) {
            top: 50%;
            transform: translate(-50%, -50%);
        }
    } */
`

export const CarouselContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: 0.8rem;

    position: absolute;
    top: 0%;
    left: 50%;
    transform: translate(-50%);

    font-family: "Montserrat";
    font-size: 1rem;
    line-height: 1.125rem;
    color: ${({ theme }) => theme.colors.black};
    font-weight: 700;

    padding: 1.9375rem 1.75rem;
    width: 80%;
    border-radius: ${({ theme }) => theme.radius.small};
    background-color: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(5px);

    @media (min-width: 1024px) {
        top: 50%;
        transform: translate(-50%, -50%);
        padding: 2.9375rem 2.75rem;
        width: unset;
    }
`

export const CarouselContentAlt = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: absolute;
    top: 3.5rem;
    left: 1.25rem;
    width: 60%;

    @media (min-width: 1024px) {
        left: 2.25rem;
        gap: 2rem;
        width: 46%;
    }

    header {
        font-weight: 700;
        font-size: 1.3rem;
        line-height: 1.4rem;

        @media (min-width: 1024px) {
            font-size: 1.5rem;
            line-height: 1.8125rem;
        }
    }

    div {
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.25rem;
        width: 80%;

        @media (min-width: 1024px) {
            width: 60%;
        }
    }

    button {
        /* background-color: ${({ theme }) => theme.colors.blueButton}; */
        width: max-content;
        padding-left: 3rem;
        padding-right: 3rem;

        @media (min-width: 1024px) {
            margin-top: 1.25rem;
        }
    }
`

export const BannerBottom = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    position: absolute;
    bottom: 0.5rem;
    left: 50%;
    transform: translateX(-50%);
    padding: 0 1.25rem;

    button {
        font-size: 1rem;
        width: fit-content;
        padding: 0.95rem !important;

        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);

        @media (min-width: 1024px) {
            padding: 0.95rem 3rem !important;
        }
    }
`

export const CarouselControllers = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
    /* position: absolute;
    bottom: 0.5rem;
    left: 50%;
    transform: translateX(-50%); */

    background-color: rgba(255, 255, 255, 0.6);
    padding: 0.5rem;
    border-radius: ${({ theme }) => theme.radius.huge};

    @media (min-width: 1024px) {
        gap: 1rem;
    }
`

export const CarouselController = styled.svg<CarouselControllerProps>`
    width: 0.75rem;
    height: 0.75rem;
    mask: url(${ellipse}) center / contain no-repeat;
    background-color: ${({ theme, color }) => theme.colors[color || "white"]};
    cursor: pointer;

    @media (min-width: 1024px) {
        width: 0.95rem;
        height: 0.95rem;
    }
`

export const Header = styled(GHeader)`
    font-size: 1.2rem;
    line-height: 1.5rem;
    font-weight: 800;
`
