import { createContext, useState } from "react"

//eslint-disable-next-line
const AuthContext = createContext<any>({})

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [auth, setAuth] = useState({})

    return <AuthContext.Provider value={{ auth, setAuth }}>{children}</AuthContext.Provider>
}

export default AuthContext
