import styled from "styled-components"

export const Textarea = styled.textarea`
    background-color: ${({ theme }) => theme.colors.lightGray};
    border: none;
    border-radius: ${({ theme }) => theme.radius.large};
    padding: 0.875rem 0.75rem;
    width: 100%;
    resize: none;
    font-family: "Montserrat", "Roboto";
    transition: all 0.3s linear;

    &:focus {
        border: 1px solid ${({ theme }) => theme.colors.main};
        outline: none;
    }

    &:disabled {
        background-color: ${({ theme }) => theme.colors.gray};
        cursor: not-allowed;
    }
`
