import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import { POST } from "modules/Core/api/REST"
import { FilterParamsType, PostType } from "types/common"

const useFilteredPosts = (params: FilterParamsType) => {
    const [filterPostList, setFilterPostList] = useState<PostType[]>([])
    const [showLoadMoreBtn, setShowLoadMoreBtn] = useState(true)
    const [isLoading, setIsLoading] = useState(true)

    const navigate = useNavigate()

    const fGetPostList = async () => {
        try {
            const { categoryID, cityID, priceStart, priceEnd, page, postPerPage, loadMore } = params
            const request = {
                category_id: categoryID,
                city_id: cityID,
                price_start: priceStart,
                price_end: priceEnd,
                page: page,
                post_per_page: postPerPage,
            }
            const response = await POST("/api/service/filter/all", { ...request })

            if (response.data) {
                const { data: dataPosts } = response.data
                if (loadMore) setFilterPostList((current) => [...current, ...dataPosts])
                else setFilterPostList(dataPosts)

                setShowLoadMoreBtn(dataPosts?.length === params?.postPerPage)
            }
        } catch (err) {
            navigate("/")
            console.log(err)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        setIsLoading(true)
    }, [JSON.stringify(params)])

    useEffect(() => {
        if (isLoading) fGetPostList()
    }, [isLoading])

    return { filterPostList, isLoading, showLoadMoreBtn }
}

export default useFilteredPosts
