import styled from "styled-components"

import edit from "assets/icons/edit.svg"
import { GHeader } from "styles/Global.styled"

import { City } from "./modules/components/PostUserProfile/PostUserProfile.styled"

export const StyledPostPage = styled.section`
    display: flex;
    flex-direction: column;

    @media (min-width: 1024px) and (max-width: 1339px) {
        display: grid;
        grid-template-columns: 2fr 1fr;
        margin-top: 1.6875rem;
    }

    @media (min-width: 1340px) {
        display: grid;
        grid-template-columns: 3fr 1fr;
        margin-top: 1.6875rem;
    }

    gap: 1.75rem;
`

export const Title = styled(GHeader)`
    display: none;

    @media (min-width: 1024px) {
        margin-top: 2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`
export const Header = styled(GHeader)`
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const PostDescription = styled.div`
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.2rem;
    margin-top: 1rem;
`

export const Edit = styled.div`
    border-radius: ${({ theme }) => theme.radius.small};
    background-color: ${({ theme }) => theme.colors.lightGray};
    padding: 0.625rem 0.625rem 0.625rem 0.6875rem;
    display: flex;
    cursor: pointer;

    > i {
        width: 1.4375rem;
        height: 1.5625rem;
        background: url(${edit}) center/contain no-repeat;
    }
`

export const EditAbsolute = styled(Edit)`
    position: absolute;
    top: 0;
    right: 0;
`

export const ReviewsLeaveRequestWrap = styled.div`
    display: flex;
    flex-direction: column-reverse;

    @media (min-width: 1024px) {
        flex-direction: column;
    }
`

export const MobileHeader = styled(Header)`
    margin-top: unset;
`

export const MobileLine = styled.div`
    border: 0.5px solid ${({ theme }) => theme.colors.grayHr};
    margin-top: 2rem;

    @media (min-width: 1024px) {
        display: none;
    }
`

export const MobileContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-size: 1rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid ${({ theme }) => theme.colors.grayHr};
    margin-top: 2rem;

    @media (min-width: 1024px) {
        display: none;
    }
`

export const CityTitle = styled(City)`
    font-size: 1rem;
`

export const MobileButtons = styled.div`
    display: flex;
    gap: 1rem;
    padding: 0 1rem;

    width: 100%;
    position: fixed;
    bottom: 2%;
    z-index: 994;

    @media (min-width: 1024px) {
        display: none;
    }
`
