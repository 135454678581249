import styled from "styled-components"

import { fadeIn } from "styles/Animation.styled"

interface StyledAuthPageProps {
    image: string
}

interface TextContainerProps {
    align?: string
    color?: string
}

export const StyledAuthPage = styled.div<StyledAuthPageProps>`
    position: relative;
    /* top: 32px; */

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;

    background: url("${({ image }) => image}") center center/cover no-repeat;
    transition: 0.5s ease-in-out;

    font-size: 0.75rem;

    a {
        font-size: 0.75rem;
    }
`

export const AuthContainer = styled.section`
    width: 100vw;
    min-height: 70vh;

    @media (min-width: 1024px) {
        max-height: calc(100vh - 32px);
        min-height: calc(100vh - 32px);
        min-width: 32rem;
        width: 32rem;
        border-radius: ${({ theme }) => theme.radius.large};
        padding: 0 6rem;
    }

    overflow-y: auto;
    background-color: ${({ theme }) => theme.colors.white};

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const AuthToggle = styled.div`
    margin: 2.5rem 0 1.625rem;
`

export const AuthContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    min-width: 22rem;
    margin-top: 1rem;

    animation: ${fadeIn} 1s ease-in 0s 1 normal;
`

export const FormItem = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;

    > label {
        font-size: 0.75rem;
        line-height: 0.875rem;
        margin-left: 0.5rem;
    }
`

export const TextContainer = styled.div<TextContainerProps>`
    font-size: 0.75rem;
    width: 100%;
    margin-top: 1rem;
    text-align: ${({ align }) => align};
    color: ${({ theme, color }) => theme.colors[color || "black"]};

    a {
        color: ${({ theme, color }) => theme.colors[color || "black"]};
        text-underline-offset: 0.25rem;
    }
`
