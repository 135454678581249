import moment from "moment"
import { useContext } from "react"
import { FaRegComment, FaEye } from "react-icons/fa"
import { Link, useLocation } from "react-router-dom"

import Like from "modules/components/Like/Like"
import {
    PostHeader,
    PostDescription,
    PostPrice,
    PostPriceText,
    PostDate,
} from "modules/components/PostList/PostList.styled"
import useMainContext from "modules/Core/hooks/useMainContext"
import useMediaQuery from "modules/Core/hooks/useMediaQuery"
import { UserPostsContext } from "pages/CabinetPage/modules/components/UserPosts/UserPosts"
import { PostType } from "types/common"
import { Button } from "ui/Button/Button.styled"

import {
    MobileButtons,
    PostAppealsLink,
    PostDetails,
    PostStatistic,
    Statistic,
    StyledPostLine,
    TrashIcon,
} from "./PostLine.styled"

import PostImage from "../PostImage/PostImage"

interface PostLineProps {
    details: PostType
}

const userPostLinks = ["/cabinet/posts", "/cabinet/posts/active", "/cabinet/posts/inactive"]

const PostLine: React.FC<PostLineProps> = ({ details }) => {
    const { image, title, id, description, price, date, post_count, order_count } = details
    const { isMobileDevice } = useMediaQuery()

    const { pathname } = useLocation()
    const showEditors = userPostLinks.includes(pathname)

    const userPostCtx = useContext(UserPostsContext)
    const { favouritePosts } = useMainContext()

    const isPostLiked = favouritePosts.some((post: PostType) => (post?.post_id || post?.id) === id)

    return (
        <>
            <StyledPostLine>
                <PostImage id={id} image={image} height="12rem" />
                <PostDetails>
                    <PostHeader>
                        <Link
                            to={`/catalogue/post/${id}`}
                            target={isMobileDevice ? "_blank" : "_self"}
                        >
                            {title}
                        </Link>

                        {!showEditors && <Like postID={id} isLiked={isPostLiked} />}
                        {showEditors && (
                            <TrashIcon onClick={() => userPostCtx?.handleArchive(id)} />
                        )}
                    </PostHeader>

                    <PostDescription>{description}</PostDescription>

                    {price === 0 ? (
                        <PostPriceText>Цена договорная</PostPriceText>
                    ) : (
                        <PostPrice>{price.toLocaleString()}</PostPrice>
                    )}

                    <PostDate>{moment(date).format("DD.MM.YYYY")}</PostDate>
                </PostDetails>

                {showEditors && (
                    <>
                        <PostStatistic>
                            <Statistic>
                                <FaRegComment size={18} /> {order_count}
                            </Statistic>
                            <Statistic>
                                <FaEye size={20} /> {post_count}
                            </Statistic>
                        </PostStatistic>

                        <PostAppealsLink to="appeals">Отклики</PostAppealsLink>

                        <MobileButtons>
                            <Button btnColor="grayC4">
                                <TrashIcon color="white" />
                            </Button>

                            <Button>Отклики</Button>
                        </MobileButtons>
                    </>
                )}
            </StyledPostLine>
        </>
    )
}

export default PostLine
