import styled from "styled-components"

import defaultIcon from "assets/icons/defaultAvatar.svg"

interface UserNameProps {
    image?: string
}

export const StyledPostUserProfile = styled.div`
    display: none;
    @media (min-width: 1024px) {
        background: ${({ theme }) => theme.colors.white};
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15);
        border-radius: ${({ theme }) => theme.radius.small};
        padding: 1rem 1.5rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        position: sticky;
        position: -webkit-sticky;
        top: 10px;
    }
`

export const UserName = styled.div<UserNameProps>`
    display: flex;
    align-items: center;
    gap: 0.75rem;
    font-size: 1.125rem;
    font-weight: 500;

    > i {
        width: 44px;
        height: 44px;
        background: url("${({ image }) => image || defaultIcon}") center center / cover no-repeat;
        border-radius: ${({ theme }) => theme.radius.small};
    }
`

export const Line = styled.hr`
    width: 100%;

    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid ${({ theme }) => theme.colors.grayHr};
    padding: 0;
`

export const Text = styled.div`
    font-size: 1.5rem;
    line-height: 1.75rem;
    font-weight: 600;
`

export const City = styled(Text)`
    font-weight: 400;

    &::before {
        content: "г.";
        margin-right: 5px;
    }
`

export const Price = styled(Text)`
    &::after {
        content: "\\20B8";
        margin-left: 5px;
    }
`
