import styled from "styled-components"

import { Button } from "ui/Button/Button.styled"

interface AboutBlocksProps {
    isPage: boolean
}

export const StyledAbout = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem 0 1rem;
    text-align: justify;
`

export const AboutBlocks = styled.div<AboutBlocksProps>`
    ${({ isPage }) =>
        !isPage &&
        "font-size: 0.75rem;padding: 1.8rem 2.375rem; box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1); border-radius: ${({ theme }) => theme.radius.large};"}

    background-color: ${({ theme }) => theme.colors.white};
    display: flex;
    flex-direction: column;

    p {
        margin: 0;
        padding: 0.5rem 0;
    }

    ul,
    ol {
        padding-left: 1.3rem;
    }
`

export const ButtonWrapper = styled.div`
    margin: 1rem 0 0;
    display: flex;
    justify-content: center;
`

export const ToggleButton = styled(Button)`
    width: max-content;
    padding: 0.6rem 2rem;
    background-color: transparent;
    color: ${({ theme }) => theme.colors.main};
    text-transform: uppercase;

    &:hover {
        box-shadow: 0 6px 16px rgb(0 0 0 / 20%);
    }
`

export const Green = styled.div`
    color: ${({ theme }) => theme.colors.green};
`
