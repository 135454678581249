import { Formik, Form, Field, ErrorMessage } from "formik"
import { useContext, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import * as Yup from "yup"

import { POST } from "modules/Core/api/REST"
// import useAuth from "modules/Core/hooks/useAuth"
import useAuth from "modules/Core/hooks/useAuth"
import { AuthContent, FormItem, TextContainer } from "pages/AuthPage/AuthPage.styled"
import { Row } from "styles/Global.styled"
import { Button } from "ui/Button/Button.styled"
import Checkbox from "ui/Checkbox/Checkbox"
import { StyledLink } from "ui/Link/Link.styled"
import { renderError } from "utils/helpers"
import { InputComponent } from "utils/helpers/components/FormikField"

import { AuthFormContext } from "../../context/AuthFormContext"
import Password from "../Password/Password"

interface SignOnForm {
    phone: string
    email: string
    password: string
    confirmPassword: string
    publicOffer: boolean
}

const initialValues = {
    phone: "",
    email: "",
    password: "",
    confirmPassword: "",
    publicOffer: false,
}

const validationSchema = Yup.object({
    email: Yup.string()
        .email("Введите корректный адрес эл.почты")
        .required("Поле E-mail обязательно для заполнения!"),
    phone: Yup.string()
        .matches(/^((\+7|8)\d{10})$/g, "Введите корректный номер телефона")
        .required("Поле телефон обязательно для заполнения!"),
    password: Yup.string()
        .required("Введите пароль (минимум 8 символов)")
        .min(8, "Минимум 8 символов"),
    confirmPassword: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Введенные пароли не совпадают!"
    ),
    publicOffer: Yup.boolean().oneOf([true], "Примите согласие с условиями публичной оферты"),
})

const SignOnForm = () => {
    const authContext = useContext(AuthFormContext)
    const location = useLocation()
    const navigate = useNavigate()
    const from = location.state?.from?.pathname || "/"
    const [success] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const { setAuth } = useAuth()

    const isAdmin = location.pathname.includes("/admin")

    const handleSubmit = async (values: SignOnForm) => {
        authContext?.setErrorMessage("")
        setIsLoading(true)
        handleRequest(values)
    }

    const handleRequest = async (values: SignOnForm) => {
        try {
            const { data: response } = await POST(
                `/api/accounts/create/${isAdmin ? "admin" : "client"}`,
                {
                    ...values,
                }
            )

            if (response.ok === 1) {
                // setSuccess(true)
                // navigate(from, { replace: true })

                const { data } = response
                const { token: accessToken, user } = data

                const userData = { user, role: user?.user_type, accessToken }
                localStorage.setItem("auth_user_credentials", JSON.stringify(userData))
                setAuth(userData)
                navigate(from, { replace: true })
            } else if (
                response.detail === "The user with this email already exists in the system"
            ) {
                authContext?.setErrorMessage(
                    "Пользователь с таким адресом электронной почты уже существует в системе"
                )
            } else {
                authContext?.setErrorMessage(
                    "Непредвиденная ошибка при регистрации. Попробуйте позднее " +
                        String(response.detail)
                )
            }
        } catch (err) {
            authContext?.setErrorMessage(
                "Непредвиденная ошибка при регистрации. Попробуйте позднее"
            )
            console.log(err)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnBlur={false}
            onSubmit={handleSubmit}
        >
            {(props) => (
                <Form>
                    {success && (
                        <TextContainer align="center" color="green">
                            Поздравляем Ваша регистрация на нашем сайте прошла успешно! Мы выслали
                            вам письмо на указанный вами адрес электронной почты с инструкциями для
                            подтверждения вашей учетной записи.
                        </TextContainer>
                    )}
                    <AuthContent>
                        <FormItem>
                            <label htmlFor="">E-mail</label>
                            <Field name="email" component={InputComponent} />
                            <ErrorMessage name="email" render={renderError} />
                        </FormItem>

                        <FormItem>
                            <label htmlFor="">Телефон</label>
                            <Field name="phone" component={InputComponent} />
                            <ErrorMessage name="phone" render={renderError} />
                        </FormItem>

                        <FormItem>
                            <label htmlFor="">Пароль</label>
                            <Password>
                                <Field
                                    name="password"
                                    type={authContext?.showPassword ? "text" : "password"}
                                    component={InputComponent}
                                />
                            </Password>
                            <ErrorMessage name="password" render={renderError} />
                        </FormItem>

                        <FormItem>
                            <label htmlFor="">Повторите пароль</label>
                            <Password>
                                <Field
                                    name="confirmPassword"
                                    type={authContext?.showPassword ? "text" : "password"}
                                    component={InputComponent}
                                />
                            </Password>

                            <ErrorMessage name="confirmPassword" render={renderError} />
                        </FormItem>
                    </AuthContent>

                    <Row gap="0.5rem" style={{ marginTop: "1rem" }}>
                        <Checkbox
                            checked={props.values.publicOffer}
                            handleChange={() => {
                                props.setFieldValue("publicOffer", !props.values.publicOffer)
                            }}
                        />
                        <div>
                            Согласен с условиями{" "}
                            <StyledLink to="/terms">публичной оферты</StyledLink>
                        </div>
                    </Row>
                    <ErrorMessage name="publicOffer" render={renderError} />

                    <Button
                        type="submit"
                        style={{ marginTop: "1rem" }}
                        disabled={isLoading || success}
                    >
                        Зарегистрироваться
                    </Button>
                </Form>
            )}
        </Formik>
    )
}

export default SignOnForm
