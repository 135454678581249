import styled from "styled-components"

export const StyledAdminPanelPage = styled.div`
    display: flex;
    height: 100vh;
    padding: 0.5rem;
    background-color: ${({ theme }) => theme.colors.admin.gray};
    gap: 3.5rem;
`

export const StyledWorkspace = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    /* border: 1px solid red; */
    padding: 0.5rem;
    max-height: 100vh;
    overflow: auto;
`

export const Header = styled.header`
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 2rem;
    line-height: 2.375rem;
    margin-bottom: 1rem;
`
