import { useState } from "react"

import Breadcrumbs from "modules/components/Breadcrumbs/Breadcrumbs"
import { Container, Page, ToggleButton, ToggleButtons } from "styles/Global.styled"
import { DocumentType } from "types/common"

import { StyledDocumentPage } from "./DocumentsPage.styled"
import Documents from "./modules/components/Documents/Documents"

const routes = ["documents"]

const DOCUMENT_TYPES = [
    { name: "letters", label: "Письма" },
    { name: "acts", label: "Акты" },
    { name: "statements", label: "Заявления и приказы" },
]

//     { name: "agreements", label: "Договоры" },
const DocumentsPage = () => {
    const [documentType, setDocumentType] = useState<DocumentType>(DOCUMENT_TYPES[0])

    const handleToggle = (document: DocumentType) => {
        setDocumentType(document)
    }

    return (
        <Page>
            <Container>
                <Breadcrumbs routes={routes} />

                <StyledDocumentPage>
                    <ToggleButtons>
                        {DOCUMENT_TYPES.map((document) => (
                            <ToggleButton
                                key={document.name}
                                onClick={() => handleToggle(document)}
                                className={`${documentType.name === document.name && "active"}`}
                            >
                                {document.label}
                            </ToggleButton>
                        ))}
                    </ToggleButtons>

                    <Documents documentType={documentType} />
                </StyledDocumentPage>
            </Container>
        </Page>
    )
}

export default DocumentsPage
