/*eslint-disable @typescript-eslint/no-explicit-any*/
import { useEffect } from "react"

import { POST } from "modules/Core/api/REST"
import useAuth from "modules/Core/hooks/useAuth"
import { ImageType, PostDetailsType } from "types/common"

const usePostImages = (
    postDetails: PostDetailsType,
    setSelectedFiles: React.Dispatch<React.SetStateAction<any[]>>,
    setMainImage: React.Dispatch<React.SetStateAction<any[]>>
) => {
    const { auth } = useAuth()

    const fGetPostImages = async () => {
        const headers = {
            Authorization: `Bearer ${auth?.accessToken}`,
        }

        try {
            const response = await POST(
                "/api/service/imgs/all/",
                { post_id: postDetails.post.id },
                { headers }
            )
            const { imgs } = response.data.data

            if (imgs.length > 0)
                setMainImage(imgs.find((image: ImageType) => image.img === postDetails.post.image))

            setSelectedFiles(imgs)
        } catch (err) {
            setSelectedFiles([])
        }
    }

    useEffect(() => {
        if (postDetails) fGetPostImages()
    }, [postDetails])
}

export default usePostImages
