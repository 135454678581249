import { createContext } from "react"

import { NavigatonItemType } from "../helpers"

//eslint-disable-next-line
interface AdminContextProps {
    activeNavigation: NavigatonItemType
    setActiveNavigation: (value: NavigatonItemType) => void
}

const AdminContext = createContext<AdminContextProps | null>(null)

export default AdminContext
