import styled from "styled-components"

import trash from "assets/icons/trash.svg"

interface ImageProps {
    image?: string
}

export const StyledCreatePostImages = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`

export const MainImage = styled.div<ImageProps>`
    width: 100%;
    min-height: 287px;
    border-radius: ${({ theme }) => theme.radius.small};
    background: url("${({ image }) => image}") center center / cover no-repeat;
    transition: all 0.3s linear;
    position: relative;
`

export const ImageList = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1.875rem;
    overflow: auto;
`

export const SmallImage = styled.div<ImageProps>`
    width: 64px;
    height: 64px;
    min-width: 64px;
    min-height: 64px;
    background: url("${({ image }) => image}") center center / cover no-repeat;
    border-radius: ${({ theme }) => theme.radius.small};
    cursor: pointer;
    position: relative;

    &.active {
        border: 1px solid ${({ theme }) => theme.colors.main};
    }

    &:hover {
        border: 1px solid ${({ theme }) => theme.colors.main};
    }

    &:hover > div {
        display: flex;
    }
`

export const TrashIcon = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: ${({ theme }) => theme.colors.lightGray};
    border-radius: ${({ theme }) => theme.radius.small};
    padding: 2px;
    display: none;
    align-items: center;

    > svg {
        max-width: 22px;
        height: 22px;
        mask: url(${trash}) center / contain no-repeat;
        background-color: ${({ theme }) => theme.colors.main};
    }
`
