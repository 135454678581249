import users from "assets/admin/1.svg"
import post from "assets/admin/2.svg"
// import tariff from "assets/admin/3.svg"
import slider from "assets/admin/4.svg"
import category from "assets/admin/5.svg"

export interface NavigatonItemType {
    name: string
    title: string
    image: string
}

export const NAVIGATION_ITEMS: NavigatonItemType[] = [
    {
        name: "post",
        title: "Объявления",
        image: post,
    },
    {
        name: "user",
        title: "Пользователи",
        image: users,
    },
    {
        name: "slider",
        title: "Слайдеры",
        image: slider,
    },
    {
        name: "category",
        title: "Категории",
        image: category,
    },
    // {
    //     name: "complaints",
    //     title: "Жалобы",
    //     image: tariff,
    // },
]
