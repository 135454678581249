import { Icon, SuccessMessage } from "./Success.styled"

interface SuccessProps {
    caption: string
}

const Success: React.FC<SuccessProps> = ({ caption }) => {
    return (
        <SuccessMessage>
            <Icon />
            {caption}
        </SuccessMessage>
    )
}

export default Success
