import styled from "styled-components"

interface MobileMainImageProps {
    image?: string
}

export const StyledMobilePostImages = styled.section`
    width: 100%;
    @media (min-width: 1024px) {
        display: none;
    }
`

export const MobileMainImage = styled.div<MobileMainImageProps>`
    width: 100%;
    min-height: 20.25rem;
    background: url("${({ image }) => image}") center center / contain no-repeat;
    transition: all 0.3s linear;
    position: relative;

    @media all and (min-width: 480px) and (max-width: 1024px) {
        min-height: 31.25rem;
    }
`
