import styled from "styled-components"

import download from "assets/icons/download.svg"
import eye from "assets/icons/eye.svg"
import { fadeIn } from "styles/Animation.styled"
import { Button } from "ui/Button/Button.styled"

export const StyledDocuments = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;

    header {
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 1.8125rem;
        margin-top: 2rem;
        display: flex;
        justify-content: space-between;

        span {
            font-weight: 600;
            font-size: 1rem;
            line-height: 1.25rem;

            b {
                color: ${({ theme }) => theme.colors.main};
            }
        }
    }
`

export const DocumentList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.125rem;

    div:nth-child(1) {
        border-radius: 12px 12px 0px 0px;
    }

    div:last-of-type {
        border-radius: 0 0 12px 12px;
    }

    iframe {
        width: 100%;
        height: 31.25rem;
        border: none;

        animation: ${fadeIn} 0.3s linear 0s 1 normal forwards;
    }
`

export const DocumentLine = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.1875rem;
    background: #f6f6f6;
    gap: 3.125rem;
    animation: ${fadeIn} 0.8s linear 0s 1 normal forwards;
`

export const Buttons = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.75rem;
`

export const DocButton = styled(Button)`
    border-radius: ${({ theme }) => theme.radius.small};
    width: max-content;
    cursor: pointer;

    i {
        width: 1.1875rem;
        height: 1.1875rem;
        background-color: ${({ theme }) => theme.colors.white};
    }

    div {
        display: none;
    }

    @media (min-width: 1024px) {
        padding: 0.5625rem 1.5rem;
        border-radius: ${({ theme }) => theme.radius.large};

        i {
            display: none;
        }

        div {
            display: block;
        }
    }
`

export const ViewButton = styled(DocButton)`
    background-color: ${({ theme }) => theme.colors.black};
    i {
        width: 1.5rem;
        height: 1.125rem;
        mask: url(${eye}) center/contain no-repeat;
    }

    @media (max-width: 1023px) {
        padding: 0.5rem 0.35rem 0.5rem;
    }
`

export const DowloadButton = styled(DocButton)`
    i {
        mask: url(${download}) center/contain no-repeat;
    }

    @media (max-width: 1023px) {
        padding: 0.4rem 0.45rem 0.5rem;
    }
`
