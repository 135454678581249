import { useEffect, useState } from "react"

import { PostType } from "types/common"

import { GET } from "../api/REST"

const usePostList = (page = 1, post_per_page = 16) => {
    const [postList, setPostList] = useState<PostType[]>([])
    const [showLoadMoreBtn, setShowLoadMoreBtn] = useState(false)

    const fGetPostList = async () => {
        const { data } = await GET(`/api/service/all?page=${page}&post_per_page=${post_per_page}`)
        if (data && data.ok === 1) {
            const { posts } = data.data

            if (page > 1) setPostList((current) => [...current, ...posts])
            else setPostList(posts)

            setShowLoadMoreBtn(posts.length === post_per_page)
        } else {
            throw new Error()
        }
    }

    useEffect(() => {
        fGetPostList()
    }, [page])

    return { postList, showLoadMoreBtn }
}

export default usePostList
