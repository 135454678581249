import styled from "styled-components"

export const StyledToggle = styled.div`
    display: none;

    @media (min-width: 1024px) {
        margin: 3.8rem 0 2rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 1.5rem;
        border-bottom: 1px solid ${({ theme }) => theme.colors.grayHrE};
    }
`

export const Item = styled.div`
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: ${({ theme }) => theme.colors.inactiveGray};
    cursor: pointer;

    &.active {
        font-weight: 700;
        color: ${({ theme }) => theme.colors.main};
    }
`
