import Breadcrumbs from "modules/components/Breadcrumbs/Breadcrumbs"
import { Container, Page, TextPageContent, TextPageHeader } from "styles/Global.styled"

const routes = ["info", "placement"]

const PlacementRulesPage = () => {
    return (
        <Page>
            <Container>
                <Breadcrumbs routes={routes} />

                <TextPageContent>
                    <h1>Правила размещения на сервисах JBF</h1>

                    <TextPageHeader style={{ marginTop: "2rem" }}>
                        ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ
                    </TextPageHeader>

                    <TextPageHeader>ЗАПРЕЩЕННЫЕ ТОВАРЫ И УСЛУГИ</TextPageHeader>

                    <p>
                        Перед публикацией объявления убедитесь, что ваши товары или услуги
                        соответствуют нормативно-правовым актам Республики Казахстан. Публикуя
                        объявления вы подтверждаете, что полностью понимаете свои права и
                        обязанности.
                    </p>

                    <p>
                        В соответствии с действующим законодательством Республики Казахстан на
                        JBF.kz строго запрещено размещение объявлений, относящихся к следующим
                        категориям:
                    </p>

                    <TextPageHeader>Медицина и здоровье</TextPageHeader>

                    <p>
                        продажа любых лекарственных средств, в том числе ветеринарного назначения. К
                        таким средствам относятся любые формы лекарственных препаратов, а также
                        фармацевтические субстанции (за исключением лекарственных трав); медицинские
                        услуги, в том числе психотерапия; средства, методы и услуги народной
                        медицины; медицинское оборудование или приборы, в которых используются
                        радиоактивные вещества (рентгенологическое оборудование, КТ, МРТ, приборы
                        для радиоизотопной диагностики и терапии и т.д.); официальные бланки, формы
                        строгой отчетности и помощь в их получении: органы и ткани человека, кровь,
                        услуги суррогатных матерей, грудное молоко. Наркотические вещества
                    </p>

                    <TextPageHeader>Алкогольная продукция</TextPageHeader>

                    <p>
                        этиловый и метиловый спирт, а так же продукты на спиртовой основе (включая
                        дезинфицирующие средства и жидкости технического назначения); продукты и
                        вещества, содержащие никотин; любые наркотические, сильнодействующие и
                        ядовитые средства, психотропные вещества, прекурсоры, средства для
                        употребления и изготовления наркотиков, поиск работы и сотрудников в этой
                        сфере, а так же любая пропаганда и реклама. Оружие
                    </p>

                    <TextPageHeader>
                        Взрывчатые вещества и средства (в том числе пиротехника)
                    </TextPageHeader>

                    <p>
                        любое огнестрельное, газовое, пневматическое, электрошоковое, спортивное,
                        сигнальное, коллекционное, охолощённое, деактивированное, сувенирное, макеты
                        оружия и т.п.; холодное оружие: ножи-бабочки, автоматические,
                        полуавтоматические, пружинные, гравитационные, метательные, инерционные и
                        любые другие ножи; клинки и лезвия с автоматическим извлечением из рукоятки;
                        холодное оружие имеющее кровосток и т.п.; запчасти и комплектующие: любые
                        виды патронов, затворы, рукоятки, глушители, приклады и т.д.;
                    </p>

                    <p>
                        Услуги обучение обращению с оружием; изготовление оружия; помощь в получении
                        разрешений и лицензий; услуги по починке и обслуживанию оружия и т.д;
                        средства активной самообороны: дубинки, электрошокеры, кастеты, аэрозольные
                        и светозвуковые устройства, газовые и перцовые баллончики и т.п. Животные и
                        растения
                    </p>

                    <p>
                        животные, растения и прочие живые организмы, занесенные в Красную книгу РК,
                        Красные книги субъектов РК или охраняемые международными договорами; любые
                        товары из них (предметы декора и интерьера, амулеты, украшения, продукты
                        питания, одежда и т.п.), их части и костные производные (чучела, шкуры, мех
                        и т.п), а так же услуги, связанные с такими животными; продукты и услуги,
                        связанные с браконьерством и незаконным отловом диких животных:
                        электрические удочки, капканы, средства для глушения рыбы, услуги по
                        притравке животных и т.п.; любые продукты или услуги, направленные на
                        жестокое обращение с животными и птицами (зоопарки, дельфинарии, охота и
                        т.п).
                    </p>

                    <TextPageHeader>Финансы и материальные ценности</TextPageHeader>

                    <p>
                        поддельные денежные знаки, а так же услуги и средства по их копированию и
                        изготовлению; иностранная валюта и иные валютные ценности (за исключением
                        купли-продажи для нумизматических целей), монеты и банкноты РК, находящиеся
                        в обращении; акции и иные ценные бумаги; криптовалюты; пиратская продукция
                        (копии книг, фильмов, аудиоматериалов, онлайн-курсов, программ и т.п. не
                        имеющих лицензии); драгоценные металлы, природные драгоценные камни и жемчуг
                        (за исключением ювелирных изделий из них); государственные награды, боевые
                        награды РК и СССР, а также памятные медали и знаки, вручаемые ветеранам,
                        принимавшим участие в боевых действиях; услуги коллекторов.
                    </p>

                    <TextPageHeader>Нематериальные товары</TextPageHeader>

                    <p>
                        базы данных, содержащие персональные данные; товары и услуги, которые могут
                        способствовать использованию данных третьих лиц без из согласия; подготовка
                        и написание дипломов, курсовых работ, диссертаций и других аналогичных видов
                        работ; материалы, передаваемые исключительно виртуально и не записанные на
                        какой-либо материальный носитель (идеи, методы, принципы и т.п.); предметы и
                        материалы, продажа которых нарушает авторские и/или смежные права третьих
                        лиц, права на товарные знаки или патенты; материалы, нарушающие тайну
                        частной жизни, посягающие на честь, достоинство и деловую репутацию граждан
                        и юридических лиц; материалы, содержащие государственную, банковскую или
                        коммерческую тайну; услуги магического характера: обряды, религиозных
                        обряды, молитвы и ритуалы, колдовство, магия, астрология, нумерология,
                        гадания, нетрадиционная медицина, деятельность сект и т.д.
                    </p>

                    <TextPageHeader>Прочие товары</TextPageHeader>

                    <p>
                        Радиоэлектронные и специальные технические средства в соответствии с
                        Перечнем, утвержденным Постановлением Правительства РК
                    </p>

                    <p>
                        шифровальные средства, а также защищенные технические средства хранения,
                        обработки и передачи информации; услуги интимного характера (например,
                        эскорт услуги, эротический массаж, стриптиз, фотосессии ню), поиск и
                        предложение работы в этой сфере; действующие или имеющие силу
                        государственные удостоверения личности, знаки, пропуска, разрешения,
                        сертификаты и лицензии; предложения, связанные с проведением лотерей,
                        основанные на риске и пари, а так же устройства для их проведения;
                    </p>
                </TextPageContent>
            </Container>
        </Page>
    )
}

export default PlacementRulesPage
