import Breadcrumbs from "modules/components/Breadcrumbs/Breadcrumbs"
import { Container, Page, TextPageContent, TextPageHeader } from "styles/Global.styled"

const routes = ["info", "rating"]

const RatingSystemPage = () => {
    return (
        <Page>
            <Container>
                <Breadcrumbs routes={routes} />
                <TextPageContent>
                    <h1>Рейтинговая система на сервисе JBF</h1>

                    <TextPageHeader style={{ marginTop: "2rem" }}>
                        Что следует оценивать?
                    </TextPageHeader>
                    <p>
                        Оценки и отзывы — это возможность для пользователей оценить опыт
                        взаимодействия с продавцами/исполнителями, даже если сделка не состоялась.
                    </p>

                    <TextPageHeader>Совершили покупку/Выполнили заказ?</TextPageHeader>
                    <p>
                        Оцените личную встречу, состояние вещи и ее соответствие описанию. Ничего не
                        купили? Дайте оценку отзывчивости продавца и его готовности помочь.
                    </p>

                    <p>
                        Помните, что хороший отзыв всегда должен быть полезным, справедливым и
                        основанным на фактах. Ваш отзыв поможет пользователям, которые будут
                        обращаться к продавцу/исполнителю в будущем. Также конструктивные отзывы
                        могут помочь продавцам/исполнителям улучшить свои показатели.
                    </p>

                    <TextPageHeader>
                        В каких случаях я не смогу оставить оценку или отзыв?
                    </TextPageHeader>
                    <p>
                        Как пользователь JBF вы не сможете оценивать себя. Также не разрешается
                        оценивать тех, с кем вы никак не взаимодействовали, или использовать
                        технические инструменты для увеличения количества отзывов.
                    </p>

                    <p>
                        Обратите внимание, что сотрудники, родственники пользователя, конкуренты или
                        их сотрудники не могут оставлять оценки. Оценки и отзывы должны
                        соответствовать правилам JBF. Мы оставляем за собой право удалять рейтинги
                        или отзывы в любое время.
                    </p>

                    <TextPageHeader>Где я могу оценить пользователей JBF? </TextPageHeader>
                    <p>
                        После входа в свой профиль JBF вы найдете кнопку для оценки и отзывов на
                        странице каждого объявления или профиля пользователя. Также мы можем
                        предлагать вам оставить отзыв пользователю после контакта с ним.
                    </p>

                    <TextPageHeader>
                        Сколько времени у меня есть для оценки пользователя?{" "}
                    </TextPageHeader>
                    <p>
                        После отправки сообщения или звонка пользователю вы сразу же увидите кнопку
                        Оценить наверху страницы его профиля. С этого момента у вас будет 30 дней,
                        чтобы оценить или оставить отзыв о пользователе.
                    </p>

                    <TextPageHeader>
                        Что происходит с оценками и отзывами, которые я оставляю для пользователей
                        JBF?{" "}
                    </TextPageHeader>
                    <p>
                        Ваша оценка влияет на общую оценку пользователя, с которым вы общались. Вы
                        можете оставлять отзывы, чтобы подробнее рассказать о причине той или иной
                        оценки. Особенно важны конструктивные отзывы, если вы ставите низкую оценку.
                        Обратите внимание на то, что пока ваши отзывы будут проходить проверку и
                        оставаться скрытыми. Однако оставляя их, вы уже помогаете нам и сообществу
                        JBF: благодаря отзывам мы узнаем, как разные пользователи ведут себя на
                        платформе, и сможем разработать соответствующий план действий.
                    </p>

                    <TextPageHeader>Как мне увидеть мою оценку? </TextPageHeader>
                    <p>
                        На страницах ваших объявлений и в профиле будут отображаться оценки, которые
                        вы получили от других пользователей JBF. Обратите внимание: если вас никто
                        не оценивал, то рейтинг показан не будет.
                    </p>

                    <TextPageHeader>Как определяется моя оценка?</TextPageHeader>
                    <p>
                        Ваша оценка — это среднее арифметическое всех оценок, которые вы получили от
                        пользователей JBF. Если большинство пользователей довольны сотрудничеством с
                        вами, вы получите хорошую или даже отличную оценку. Если пользователи будут
                        неудовлетворены, соответственно и оценка будет низкой.
                    </p>
                </TextPageContent>
            </Container>
        </Page>
    )
}

export default RatingSystemPage
