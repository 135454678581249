import act1 from "assets/docs/acts/1-akt-priemki-peredachi-obuekta.pdf"
import act10 from "assets/docs/acts/10-obrazec-protokola-vneshnego-planovo-otchetnogo-soveshaniya.pdf"
import act11 from "assets/docs/acts/11-obrazec-protokola-vnutrennego-planovo-otchetnogo-soveshaniya.pdf"
import act2 from "assets/docs/acts/2-akt-posadki-zdaniya.pdf"
import act3 from "assets/docs/acts/3-akt-vynosa-geodezicheskih-reperov.pdf"
import act4 from "assets/docs/acts/4-akt-ob-otvode-granic-uchastka-v-nature.pdf"
import act5 from "assets/docs/acts/5-akt-osmotra-otkrytyh-rvov-i-kotlovanov-pod-fundamenty-forma-№8.pdf"
import act6 from "assets/docs/acts/6-akt-osvidetelьstvovaniya-skrytyh-rabot.pdf"
import act7 from "assets/docs/acts/7-akt-promezhutochnoj-priemki-otvetstvennyh-konstrukcij.doc.pdf"
import act8 from "assets/docs/acts/8-akt-prostoya-proizvodstva-stroitelьno-montazhnyh-rabot.pdf"
import act9 from "assets/docs/acts/9-akt-priema-peredachi-v-montazhxlsx-list.pdf"
import agreement0 from "assets/docs/agreements/0-obrazec-trudovogo-dogovora-s-prilozheniyami-tipovoj.doc.pdf"
import agreement1 from "assets/docs/agreements/1-obrazec-dogovora-na-vypolnenie-proektnyh-izyskatelьskih-rabot-razrabotka-proektno-smetnoj-dokumentacii.docx.pdf"
import agreement10 from "assets/docs/agreements/10-obrazec-dogovora-arendy-oborudovaniya.docx.pdf"
import agreement11 from "assets/docs/agreements/11-obrazec-dogovora-na-stroitelьno-montazhnye-raboty.docx.pdf"
import agreement12 from "assets/docs/agreements/12-obrazec-dogovora-na-okazanie-vozmezdnyh-uslug-stroitelьnoj-brigadoj.docx.pdf"
import agreement13 from "assets/docs/agreements/13-obrazec-dogovora-arendy-instrumentov-prokat.doc.pdf"
import agreement14 from "assets/docs/agreements/14-obrazec-dogovora-na-perevozku-gruza-avtotransportom.doc.pdf"
import agreement15 from "assets/docs/agreements/15-obrazec-dogovora-otvetstvennogo-hraneniya.docx.pdf"
import agreement2 from "assets/docs/agreements/2-obrazec-dogovora-podryada-na-remontnye-raboty.doc.pdf"
import agreement3 from "assets/docs/agreements/3-obrazec-dogovora-podryada-na-remont-zhilogo-pomesheniya.doc.pdf"
import agreement4 from "assets/docs/agreements/4-obrazec-dogovora-podryada-na-remont-ofisa.doc.pdf"
import agreement5 from "assets/docs/agreements/5-obrazec-dogovora-podryada-na-remont-krovli.doc.pdf"
import agreement6 from "assets/docs/agreements/6-obrazec-dogovora-na-stroitelьstvo-inzhenernyh-setej-material-ot-zakazchika.docx.pdf"
import agreement7 from "assets/docs/agreements/7-obrazec-dogovora-arendy-spectehniki.doc.pdf"
import agreement8 from "assets/docs/agreements/8-obrazec-dogovora-arendy-kompressora.doc.pdf"
import agreement9 from "assets/docs/agreements/9-obrazec-dogovora-postavki-materiala-oborudovaniya.doc.pdf"
import letter1 from "assets/docs/letters/1/1-pisьmo-o-nachale-rabot.doc.pdf"
import letter2 from "assets/docs/letters/1/2-pisьmo-o-neobhodimosti-peredachi-obuekta-aktom.doc.pdf"
import letter3 from "assets/docs/letters/1/3-pisьmo-o-nevozmozhnosti-nachala-rabot.doc.pdf"
import letter4 from "assets/docs/letters/1/4-pisьmo-o-nesootvetstvii-obuemov-smet-rp.doc.pdf"
import letter5 from "assets/docs/letters/1/5-pisьmo-o-narusheniyah-zakazchika-nachala-rabot.doc.pdf"
import letter6 from "assets/docs/letters/1/6-pisьmo-o-soglasovanii-smet.doc.pdf"
import letter7 from "assets/docs/letters/1/7-soprovoditelьnoe-pisьmo-k-novym-smetam.doc.pdf"
import letter8 from "assets/docs/letters/1/8-garantijnoe-pisьmo-zakazchiku.doc.pdf"
import letter2_1 from "assets/docs/letters/2/1-pisьmo-prosьba-o-zamene-materiala.doc.pdf"
import letter2_2 from "assets/docs/letters/2/2-pisьmo-o-pomehah-v-rabote.doc.pdf"
import letter2_3 from "assets/docs/letters/2/3-pisьmo-o-priostanovke-rabot.doc.pdf"
import letter2_4 from "assets/docs/letters/2/4-pisьmo-o-fiksacii-ustnyh-ukazanij-zakazchika.doc.pdf"
import letter2_5 from "assets/docs/letters/2/5-pisьmo-s-predlogaemym-grafikom-oplaty-po-dogovoru.doc.pdf"
import letter2_6 from "assets/docs/letters/2/6-pisьmo-uvedomlenie-o-neobhodimosti-izmeneniya-srokov.doc.pdf"
import letter2_7 from "assets/docs/letters/2/7-pisьmo-povtornoe-uvedomlenie-o-neobhodimosti-izmeneniya-srokov.doc.pdf"
import letter3_1 from "assets/docs/letters/3/1-pisьmo-uvedomlenie-ob-obnaruzhenii-doprabot.doc.pdf"
import letter3_2 from "assets/docs/letters/3/2-pisьmo-povtornaya-prosьba-podtverditь-dopraboty-pisьmenno.doc.pdf"
import letter3_3 from "assets/docs/letters/3/3-pisьmo-o-priostanovke-iz-za-doprabot.doc.pdf"
import letter3_4 from "assets/docs/letters/3/4-pisьmo-prosьba-podpisatь-dopsoglashenie-ili-inoe-dlya-fiksacii-doprabot.doc.pdf"
import letter4_1 from "assets/docs/letters/4/1-pisьmo-prosьba-podpisaniya-akta-ks-2.doc.pdf"
import letter4_2 from "assets/docs/letters/4/2-soprovoditelьnoe-pisьmo-k-ks-2.doc.pdf"
import letter4_3 from "assets/docs/letters/4/3-soprovoditelьnoe-pisьmo-k-ks-2-podpisannym-v-odnostoronnem-poryadke.doc.pdf"
import letter4_4 from "assets/docs/letters/4/4-pisьmo-uvedomlenie-o-nemotivirovannom-otkaze-ot-priemki-vyp-rabot.doc.pdf"
import letter4_5 from "assets/docs/letters/4/5-pisьmo-otkaz-ot-predostavleniya-izlishnej-tehdokumentacii.doc.pdf"
import letter4_6 from "assets/docs/letters/4/6-pisьmo-razuyasnenie-po-zamechaniyam-pri-priemke-vyp-rabot.doc.pdf"
import letter4_7 from "assets/docs/letters/4/7-pisьmo-razuyasnenie-po-nedostatkam-vyyavlennym-pri-priemke-vyp-rabot.doc.pdf"
import letter4_8 from "assets/docs/letters/4/8-uvedomlenie-o-zavershenii-rabot.doc.pdf"
import letter5_1 from "assets/docs/letters/5/1-pisьmo-trebovanie-oplatitь-za-vypolnennye-raboty.doc.pdf"
import letter5_2 from "assets/docs/letters/5/2-pisьmo-trebovanie-oplatitь-za-vypraboty-v-tom-chisle-za-dopraboty.doc.pdf"
import letter5_3 from "assets/docs/letters/5/3-pisьmo-razuyasnenie-po-shtrafam-za-prosrochku-oplaty-zakazchikom.doc.pdf"
import letter5_4 from "assets/docs/letters/5/4-pisьmo-po-shtrafam-za-narushenie-srokov-vyprabot-uslug-podryadchikom.doc.pdf"
import statement1 from "assets/docs/statements/1-obrazec-zayavleniyaopriemenarabotu.pdf"
import statement10 from "assets/docs/statements/10-obrazec-prikaza-ob-uvolьnenii.pdf"
import statement2 from "assets/docs/statements/2-obrazec-zayavleniyanaperevod.pdf"
import statement3 from "assets/docs/statements/3-obrazec-zayavleniyana4hchasrabden.pdf"
import statement4 from "assets/docs/statements/4-obrazec-zayavleniyanatrudovoiotpusk.pdf"
import statement5 from "assets/docs/statements/5-obrazec-zayavleniyanaotpuskbs.pdf"
import statement6 from "assets/docs/statements/6-obrazec-zayavleniyanauvolьnenie.pdf"
import statement7 from "assets/docs/statements/7-obrazec-prikaza-o-prieme-na-rabotu.pdf"
import statement8 from "assets/docs/statements/8-obrazec-prikaza-o-predostavlenii-otpuska-bez-soderzhaniya-zarabotnoi-platy.pdf"
import statement9 from "assets/docs/statements/9-obrazec-prikaza-o-predostavlenii-ezhegodnogo-trudovogo-otpuska.pdf"

export const ALL_DOCUMENTS = {
    statements: [
        {
            title: "Заявление о приеме на работу",
            previewDocument: statement1,
            wordFile: "/filesdir/statements/1. Образец заявления_о_приеме_на_работу.doc",
        },
        {
            title: "Заявление на перевод",
            wordFile: "/filesdir/statements/2. Образец заявления_на_перевод.doc",
            previewDocument: statement2,
        },
        {
            title: "Заявление на 4-х часовой рабочий день",
            wordFile: "/filesdir/statements/3. Образец заявления_на_4х_час.раб.день.doc",
            previewDocument: statement3,
        },
        {
            title: "Заявление на трудовой отпуск",
            wordFile: "/filesdir/statements/4. Образец заявления_на_трудовой_отпуск.doc",
            previewDocument: statement4,
        },
        {
            title: "Заявление на отпуск без содержания заработной платы",
            wordFile: "/filesdir/statements/5. Образец заявления_на_отпуск_б.с..doc",
            previewDocument: statement5,
        },
        {
            title: "Заявление на увольнение",
            wordFile: "/filesdir/statements/6. Образец заявления_на_увольнение.doc",
            previewDocument: statement6,
        },
        {
            title: "Приказ о приеме на работу",
            wordFile: "/filesdir/statements/7. Образец приказа о приеме на работу.docx",
            previewDocument: statement7,
        },
        {
            title: "Приказ о предоставлении отпуска без содержания заработной платы",
            wordFile:
                "/filesdir/statements/8. Образец приказа о предоставлении отпуска без содержания заработной платы.docx",
            previewDocument: statement8,
        },
        {
            title: "Приказ о предоставлении ежегодного трудового отпуска",
            wordFile:
                "/filesdir/statements/9. Образец приказа о предоставлении ежегодного трудового отпуска.doc",
            previewDocument: statement9,
        },
        {
            title: "Приказ об увольнении",
            wordFile: "/filesdir/statements/10. Образец приказа об увольнении.docx",
            previewDocument: statement10,
        },
    ],
    acts: [
        {
            title: "Акт приемки-передачи объекта",
            wordFile: "/filesdir/acts/1. Акт приемки-передачи объекта.doc",
            previewDocument: act1,
        },
        {
            title: "Акт посадки здания.",
            wordFile: "/filesdir/acts/2. Акт посадки здания.docx",
            previewDocument: act2,
        },
        {
            title: "Акт выноса геодезических реперов.",
            wordFile: "/filesdir/acts/3. Акт выноса геодезических реперов.docx",
            previewDocument: act3,
        },
        {
            title: "Акт об отводе границ участка в натуре",
            wordFile: "/filesdir/acts/4. Акт об отводе границ участка в натуре.doc",
            previewDocument: act4,
        },
        {
            title: "Акт осмотра открытых рвов и котлованов под фундаменты (Форма №8)",
            wordFile:
                "/filesdir/acts/5. Акт осмотра открытых рвов и котлованов под фундаменты (Форма №8).doc",
            previewDocument: act5,
        },
        {
            title: "Акт освидетельствования скрытых работ",
            wordFile: "/filesdir/acts/6. Акт освидетельствования скрытых работ.doc",
            previewDocument: act6,
        },
        {
            title: "Акт промежуточной приемки ответственных конструкций",
            wordFile: "/filesdir/acts/7. Акт промежуточной приемки ответственных конструкций.doc",
            previewDocument: act7,
        },
        {
            title: "Акт простоя производства строительно-монтажных работ",
            wordFile: "/filesdir/acts/8. Акт простоя производства строительно-монтажных работ.doc",
            previewDocument: act8,
        },
        {
            title: "Акт приема-передачи в монтаж",
            wordFile: "/filesdir/acts/9. Акт приема-передачи в монтаж.xlsx",
            previewDocument: act9,
        },
        {
            title: "Образец протокола внешнего планово-отчетного совещания",
            wordFile:
                "/filesdir/acts/10. Образец протокола внешнего планово-отчетного совещания.doc",
            previewDocument: act10,
        },
        {
            title: "Образец протокола внутреннего планово-отчетного совещания",
            wordFile:
                "/filesdir/acts/11. Образец протокола внутреннего планово-отчетного совещания  .doc",
            previewDocument: act11,
        },
    ],
    letters: [
        {
            title: "Письмо о начале работ",
            wordFile: "/filesdir/letters/1/1. Письмо о начале работ.doc",
            previewDocument: letter1,
        },

        {
            title: "Письмо о необходимости передачи объекта актом",
            wordFile: "/filesdir/letters/1/2. Письмо о необходимости передачи объекта актом.doc",
            previewDocument: letter2,
        },

        {
            title: "Письмо о невозможности начала работ",
            wordFile: "/filesdir/letters/1/3. Письмо о невозможности начала работ.doc",
            previewDocument: letter3,
        },

        {
            title: "Письмо о несоответствии объемов смет и РП",
            wordFile: "/filesdir/letters/1/4. Письмо о несоответствии объемов смет и РП.doc",
            previewDocument: letter4,
        },
        {
            title: "Письмо о нарушениях Заказчика до начала работ",
            wordFile: "/filesdir/letters/1/5. Письмо о нарушениях Заказчика до начала работ.doc",
            previewDocument: letter5,
        },

        {
            title: "Письмо о согласовании смет",
            wordFile: "/filesdir/letters/1/6. Письмо о согласовании смет.doc",
            previewDocument: letter6,
        },

        {
            title: "Сопроводительное письмо к новым сметам",
            wordFile: "/filesdir/letters/1/7. Сопроводительное письмо к новым сметам.doc",
            previewDocument: letter7,
        },
        {
            title: "Гарантийное письмо заказчику",
            wordFile: "/filesdir/letters/1/8. Гарантийное письмо заказчику.doc",
            previewDocument: letter8,
        },

        {
            title: "Письмо (просьба) о замене материала",
            wordFile: "/filesdir/letters/2/1. Письмо (просьба) о замене материала.doc",
            previewDocument: letter2_1,
        },

        {
            title: "Письмо о помехах в работе",
            wordFile: "/filesdir/letters/2/2. Письмо о помехах в работе.doc",
            previewDocument: letter2_2,
        },

        {
            title: "Письмо о приостановке работ",
            wordFile: "/filesdir/letters/2/3. Письмо о приостановке работ.doc",
            previewDocument: letter2_3,
        },
        {
            title: "Письмо о фиксации устных указаний Заказчика",
            wordFile: "/filesdir/letters/2/4. Письмо о фиксации устных указаний Заказчика.doc",
            previewDocument: letter2_4,
        },
        {
            title: "Письмо с предлогаемым графиком оплаты по Договору",
            wordFile:
                "/filesdir/letters/2/5. Письмо с предлогаемым графиком оплаты по Договору.doc",
            previewDocument: letter2_5,
        },
        {
            title: "Письмо - уведомление о необходимости изменения сроков",
            wordFile:
                "/filesdir/letters/2/6. Письмо - уведомление о необходимости изменения сроков.doc",
            previewDocument: letter2_6,
        },
        {
            title: "Письмо - повторное уведомление о необходимости изменения сроков",
            wordFile:
                "/filesdir/letters/2/7 Письмо - повторное уведомление о необходимости изменения сроков.doc",
            previewDocument: letter2_7,
        },
        {
            title: "Письмо - уведомление об обнаружении допработ",
            wordFile: "/filesdir/letters/3/1. Письмо - уведомление об обнаружении допработ.doc",
            previewDocument: letter3_1,
        },
        {
            title: "Письмо - повторная просьба подтвердить допработы письменно",
            wordFile:
                "/filesdir/letters/3/2. Письмо - повторная просьба подтвердить допработы письменно.doc",
            previewDocument: letter3_2,
        },
        {
            title: "Письмо о приостановке из-за допработ",
            wordFile: "/filesdir/letters/3/3. Письмо о приостановке из-за допработ.doc",
            previewDocument: letter3_3,
        },
        {
            title: "Письмо - просьба подписать Доп.соглашение или иное для фиксации допработ",
            wordFile:
                "/filesdir/letters/3/4. Письмо - просьба подписать Доп.соглашение или иное для фиксации допработ.doc",
            previewDocument: letter3_4,
        },
        {
            title: "Письмо - просьба подписания акта КС-2",
            wordFile: "/filesdir/letters/4/1. Письмо - просьба подписания акта КС-2.doc",
            previewDocument: letter4_1,
        },

        {
            title: "Сопроводительное письмо к КС-2",
            wordFile: "/filesdir/letters/4/2. Сопроводительное письмо к КС-2.doc",
            previewDocument: letter4_2,
        },

        {
            title: "Сопроводительное письмо к КС-2, подписанным в одностороннем порядке",
            wordFile:
                "/filesdir/letters/4/3. Сопроводительное письмо к КС-2, подписанным в одностороннем порядке.doc",
            previewDocument: letter4_3,
        },
        {
            title: "Письмо - уведомление о немотивированном отказе от приемки вып. работ",
            wordFile:
                "/filesdir/letters/4/4. Письмо - уведомление о немотивированном отказе от приемки вып. работ.doc",
            previewDocument: letter4_4,
        },
        {
            title: "Письмо отказ от предоставления излишней тех.документации",
            wordFile:
                "/filesdir/letters/4/5. Письмо отказ от предоставления излишней тех.документации.doc",
            previewDocument: letter4_5,
        },
        {
            title: "Письмо разъяснение по замечаниям при приемке вып. работ",
            wordFile:
                "/filesdir/letters/4/6. Письмо разъяснение по замечаниям при приемке вып. работ.doc",
            previewDocument: letter4_6,
        },
        {
            title: "Письмо разъяснение по недостаткам, выявленным при приемке вып. работ",
            wordFile:
                "/filesdir/letters/4/7. Письмо разъяснение по недостаткам, выявленным при приемке вып. работ.doc",
            previewDocument: letter4_7,
        },

        {
            title: "Уведомление о завершении работ",
            wordFile: "/filesdir/letters/4/8. Уведомление о завершении работ.doc",
            previewDocument: letter4_8,
        },

        {
            title: "Письмо - требование оплатить за выполненные работы",
            wordFile:
                "/filesdir/letters/5/1. Письмо - требование оплатить за выполненные работы.doc",
            previewDocument: letter5_1,
        },
        {
            title: "Письмо требование оплатить за вып.работы, в том числе за допработы",
            wordFile:
                "/filesdir/letters/5/2. Письмо требование оплатить за вып.работы, в том числе за допработы.doc",
            previewDocument: letter5_2,
        },
        {
            title: "Письмо разъяснение по штрафам за просрочку оплаты Заказчиком",
            wordFile:
                "/filesdir/letters/5/3. Письмо разъяснение по штрафам за просрочку оплаты Заказчиком.doc",
            previewDocument: letter5_3,
        },
        {
            title: "Письмо по штрафам за нарушение сроков вып.работ (услуг) Подрядчиком",
            wordFile:
                "/filesdir/letters/5/4. Письмо по штрафам за нарушение сроков вып.работ (услуг) Подрядчиком.doc",
            previewDocument: letter5_4,
        },
    ],
    agreements: [
        {
            title: "Образец трудового договора с приложениями (типовой)",
            wordFile:
                "/filesdir/agreements/0. Образец трудового договора с приложениями (типовой).doc",
            previewDocument: agreement0,
        },
        {
            title: "Образец договора на выполнение проектных и изыскательских работ (разработка проектно-сметной документации)",
            wordFile:
                "/filesdir/agreements/1. Образец договора на выполнение проектных и изыскательских работ (разработка проектно-сметной документации).docx",
            previewDocument: agreement1,
        },
        {
            title: "Образец договора подряда на ремонтные работы",
            wordFile:
                "/filesdir/agreements/1. Образец договора на строительно-монтажные работы.docx",
            previewDocument: agreement2,
        },
        {
            title: "Образец договора подряда на ремонт жилого помещения",
            wordFile: "/filesdir/agreements/2. Образец договора подряда на ремонтные работы.doc",
            previewDocument: agreement3,
        },
        {
            title: "Образец договора подряда на ремонт офиса",
            wordFile:
                "/filesdir/agreements/3. Образец договора подряда на ремонт жилого помещения.doc",
            previewDocument: agreement4,
        },
        {
            title: "Образец договора подряда на ремонт кровли",
            wordFile: "/filesdir/agreements/4. Образец договора подряда на ремонт офиса.doc",
            previewDocument: agreement5,
        },
        {
            title: "Образец договора на строительство инженерных сетей (материал от заказчика)",
            wordFile: "/filesdir/agreements/5. Образец договора подряда на ремонт кровли.doc",
            previewDocument: agreement6,
        },
        {
            title: "Образец договора аренды спецтехники",
            wordFile:
                "/filesdir/agreements/6. Образец договора на строительство инженерных сетей (материал от заказчика).docx",
            previewDocument: agreement7,
        },
        {
            title: "Образец договора аренды компрессора",
            wordFile: "/filesdir/agreements/7. Образец договора аренды спецтехники.doc",
            previewDocument: agreement8,
        },
        {
            title: "Образец договора поставки материала и оборудования",
            wordFile: "/filesdir/agreements/8. Образец договора аренды компрессора.doc",
            previewDocument: agreement9,
        },
        {
            title: "Образец договора аренды оборудования",
            wordFile:
                "/filesdir/agreements/9. Образец договора поставки материала и оборудования.doc",
            previewDocument: agreement10,
        },
        {
            title: "Образец договора на строительно-монтажные работы",
            wordFile: "/filesdir/agreements/10. Образец договора аренды оборудования.docx",
            previewDocument: agreement11,
        },
        {
            title: "Образец договора на оказание возмездных услуг со строительной бригадой",
            wordFile:
                "/filesdir/agreements/12. Образец договора на оказание возмездных услуг со строительной бригадой.docx",
            previewDocument: agreement12,
        },
        {
            title: "Образец договора аренды инструментов (прокат)",
            wordFile: "/filesdir/agreements/13. Образец договора аренды инструментов (прокат).doc",
            previewDocument: agreement13,
        },
        {
            title: "Образец договора на перевозку груза автотранспортом",
            wordFile:
                "/filesdir/agreements/14. Образец договора на перевозку груза автотранспортом.doc",
            previewDocument: agreement14,
        },
        {
            title: "Образец договора ответственного хранения",
            wordFile: "/filesdir/agreements/15. Образец договора ответственного хранения.docx",
            previewDocument: agreement15,
        },
    ],
}
