import { useState } from "react"
import { useLocation } from "react-router-dom"

import adminBackground from "assets/authPage/adminAuth.png"
import signOnBackground from "assets/authPage/signOn.jpg"
import userBackground from "assets/authPage/userAuth.png"
import Logo from "modules/components/Logo/Logo"
import { ErrorMessage, ToggleButton, ToggleButtons } from "styles/Global.styled"

import { StyledAuthPage, AuthContainer, AuthToggle } from "./AuthPage.styled"
import ForgetPassword from "./modules/components/ForgetPassword/ForgetPassword"
import ResetPassword from "./modules/components/ResetPassword/ResetPassword"
import SignInForm from "./modules/components/SignInForm/SignInForm"
import SignOnForm from "./modules/components/SignOnForm/SignOnForm"
import { AuthFormContext } from "./modules/context/AuthFormContext"

interface BackgroundImagesType {
    admin: string
    signIn: string
    signOn: string
}

const backgroundImages: BackgroundImagesType = {
    admin: adminBackground,
    signIn: userBackground,
    signOn: signOnBackground,
}

interface AuthPageProps {
    reset?: boolean
}

const AuthPage: React.FC<AuthPageProps> = ({ reset }) => {
    const location = useLocation()
    const isAdmin = location.pathname.includes("/admin")
    const [authType, setAuthType] = useState(location.state?.authType || "signIn")
    const [forgetPassword, setForgetPassword] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")

    const [backgroundImage, setBackgroundImage] = useState(
        isAdmin ? adminBackground : userBackground
    )

    const handleAuthType = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const type = e.currentTarget.name
        const backgroundImage = isAdmin
            ? adminBackground
            : backgroundImages[type as keyof BackgroundImagesType]

        setAuthType(type)
        setForgetPassword(false)
        setBackgroundImage(backgroundImage)
        setErrorMessage("")
    }

    return (
        <StyledAuthPage image={backgroundImage}>
            <AuthContainer>
                <Logo fontSize="29px" color="black" />

                {!reset && (
                    <AuthToggle>
                        <ToggleButtons>
                            <ToggleButton
                                name="signIn"
                                onClick={handleAuthType}
                                className={`${authType === "signIn" && "active"}`}
                            >
                                Авторизация
                            </ToggleButton>
                            <ToggleButton
                                name="signOn"
                                onClick={handleAuthType}
                                className={`${authType === "signOn" && "active"}`}
                            >
                                Регистрация
                            </ToggleButton>
                        </ToggleButtons>
                    </AuthToggle>
                )}

                {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

                <AuthFormContext.Provider
                    value={{
                        setForgetPassword,
                        isAdmin,
                        showPassword,
                        setShowPassword,
                        setErrorMessage,
                    }}
                >
                    {!reset ? (
                        <>
                            {forgetPassword && <ForgetPassword />}
                            {!forgetPassword && authType === "signIn" && <SignInForm />}
                            {!forgetPassword && authType === "signOn" && <SignOnForm />}
                        </>
                    ) : (
                        <ResetPassword />
                    )}
                </AuthFormContext.Provider>
            </AuthContainer>
        </StyledAuthPage>
    )
}

export default AuthPage
