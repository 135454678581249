import { Link } from "react-router-dom"
import styled from "styled-components"

interface NavigationIconProps {
    url: string
}

interface BannerProps extends NavigationIconProps {
    caption: string
}

export const StyledMainMenu = styled.section`
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    margin-bottom: 3rem;

    @media (max-width: 1399px) {
        gap: 2.5rem;
    }

    @media (min-width: 1400px) {
        display: grid;
        grid-template-columns: 1fr 2fr 1fr;
        gap: 1.875rem;
    }
`

export const MainMenuItem = styled.section`
    display: flex;
    flex-direction: column;

    &:last-child {
        @media (max-width: 1399px) {
            display: none;
        }
    }
`

export const Header = styled.div`
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.5rem;
`

export const Navigation = styled.nav`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    max-width: 95vw;
    overflow-x: auto;
    overflow-y: hidden;

    align-items: flex-start;
    gap: 2rem;
    /* margin-top: 1.5625rem; */

    @media (min-width: 1400px) {
        width: unset;
        flex-direction: column;
        padding-left: unset;
        gap: 0.8rem;
    }
`

export const NavigationItem = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.3rem;
    align-items: center;

    width: min-content;

    @media (min-width: 1400px) {
        gap: 1.25rem;
        flex-direction: row;
        min-width: unset;
    }
`

export const NavigationIcon = styled.i<NavigationIconProps>`
    max-width: 4rem;
    min-width: 4rem;
    max-height: 4rem;
    min-height: 4rem;
    display: flex;
    background: url("${({ url }) => url}") center center / contain no-repeat;

    /* background-color: ${({ theme }) => theme.colors.white}; */

    @media (min-width: 1400px) {
        max-width: 2.25rem;
        min-width: 2.25rem;
        max-height: 2.25rem;
        min-height: 2.25rem;
    }
`

export const NavigationIconWrapper = styled.div`
    padding-bottom: 1rem;
    /* border-radius: 50%; */

    @media (min-width: 1400px) {
        background: none;
        padding: 0;
        border-radius: unset;
    }
`

export const NavigationCaption = styled(Link)`
    text-align: center;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1rem;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.black};

    &:hover {
        color: ${({ theme }) => theme.colors.main};
        text-decoration: underline;
    }

    @media (min-width: 1400px) {
        text-align: unset;
        font-weight: 600;
        white-space: nowrap;
    }
`

export const Banners = styled.div`
    display: grid;
    grid-template-rows: 1fr 1fr;
    gap: 2rem;
    width: 100%;
    height: 100%;
`

export const Banner = styled.div<BannerProps>`
    background: url("${({ url }) => url}") center center / cover no-repeat;
    border-radius: ${({ theme }) => theme.radius.large};
    min-height: 50%;
    position: relative;

    &::after {
        content: "${({ caption }) => caption}";
        position: absolute;
        right: 12px;
        top: 12px;
        color: ${({ theme }) => theme.colors.white};
        font-family: "Montserrat";
        font-size: 1rem;
        font-weight: 700;
    }
`

export const MobileAppBanner = styled.div`
    background-color: ${({ theme }) => theme.colors.black};
    border-radius: ${({ theme }) => theme.radius.large};
    color: ${({ theme }) => theme.colors.white};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.2rem 2rem;
    min-height: 100%;
    gap: 1rem;
`

export const BannerText = styled.div`
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.2rem;
    text-align: center;
`
