import * as Yup from "yup"

export const validationSchema = Yup.object({
    title: Yup.string().required("Заполните название"),
    category: Yup.number().required("Выберите категорию"),
    city: Yup.number().required("Выберите город"),
    isPrivate: Yup.number().oneOf([2, 1]).required("Выберите один из вариантов"),
    // price: Yup.number().typeError("Только цифры").required("Заполните цену"),
    description: Yup.string().required("Заполните описание"),
    firstName: Yup.string().required("Заполните Ваше имя"),
    phone: Yup.string()
        .matches(/^((\+7|8)\d{10})$/g, "Введите корректный номер телефона")
        .required("Заполните Ваш номер телефона"),
})
