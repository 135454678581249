import { useEffect, useState } from "react"

import useMainContext from "modules/Core/hooks/useMainContext"
import { CategoryType } from "types/common"

const useSelectedCategory = (id: number | undefined) => {
    const { categoryList } = useMainContext()
    const [selectedCategory, setSelectedCategory] = useState()

    useEffect(() => {
        setSelectedCategory(
            categoryList &&
                categoryList.find((category: CategoryType) => {
                    return category.id === id
                })
        )
    }, [categoryList, id])

    return { selectedCategory }
}

export default useSelectedCategory
