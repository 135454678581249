import { Carousel } from "react-responsive-carousel"

import { ImageType } from "types/common"

import { MobileMainImage, StyledMobilePostImages } from "./MobilePostImages.styled"

import useImageSlider from "../../hooks/useImageSlider"
import MobileImageDummy from "../MobileImageDummy/MobileImageDummy"
import PostImageSlider from "../PostImageSlider/PostImageSlider"

interface MobilePostImagesProps {
    images?: ImageType[]
}

const MobilePostImages: React.FC<MobilePostImagesProps> = ({ images }) => {
    const settings = {
        swipeable: true,
        emulateTouch: true,
        showThumbs: false,
        showArrows: false,
        showStatus: false,
    }

    const { showImageSlider, handleShowSlider, handleCloseSlider } = useImageSlider(images)

    return (
        <StyledMobilePostImages>
            {images && images.length > 0 ? (
                <Carousel {...settings}>
                    {images.map((image) => (
                        <MobileMainImage
                            image={image.img}
                            key={image.id}
                            onClick={handleShowSlider}
                        />
                    ))}
                </Carousel>
            ) : (
                <MobileImageDummy />
            )}

            {showImageSlider && <PostImageSlider images={images} handleClose={handleCloseSlider} />}
        </StyledMobilePostImages>
    )
}

export default MobilePostImages
