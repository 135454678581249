import styled from "styled-components"

import coin1 from "assets/icons/Coin1.svg"
import coin2 from "assets/icons/Coin2.svg"
import coin3 from "assets/icons/Coin3.svg"

export const StyledPayBanner = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 1.625rem 1rem;
    gap: 1rem;
    position: relative;
    background: linear-gradient(
        286.65deg,
        rgba(255, 125, 2, 0.66) 19.33%,
        rgba(255, 125, 2, 0.82) 62.61%,
        #ff7d02 102.33%
    );

    border-radius: ${({ theme }) => theme.radius.large};
    color: ${({ theme }) => theme.colors.white};

    @media (min-width: 1024px) {
        flex-direction: row;

        background: linear-gradient(
            270deg,
            #d0e9ff -2.79%,
            rgba(255, 125, 2, 0.45) 33.04%,
            rgba(255, 125, 2, 0.65) 65.06%,
            #ff7d02 103.29%
        );
    }
`

export const LeftContent = styled.section`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.375rem;

    padding-left: 6.25rem;

    div:nth-child(1) {
        font-size: 1.375rem;
        line-height: 1.6875rem;
    }

    @media (min-width: 1024px) {
        padding-left: 11.875rem;
    }
`

export const RightContent = styled.section`
    display: grid;
    grid-template-columns: 1fr 2fr;
    width: 100%;
    gap: 0.75rem;

    button {
        padding: 1rem 3rem;
        font-weight: 500;
    }

    @media (min-width: 1024px) {
        display: flex;
        gap: 1.25rem;
        align-items: center;
        width: unset;
    }
`

export const Cost = styled.div`
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.black};
    font-weight: 700;
    font-size: 1.375rem;
    line-height: 1.6875rem;
    border-radius: ${({ theme }) => theme.radius.large};
    white-space: nowrap;
    padding: 0.625rem 1rem;
    text-align: center;

    &::after {
        content: "\\20B8";
        margin-left: 5px;
    }
`

export const Coin1 = styled.div`
    position: absolute;
    width: 50px;
    height: 40px;
    background: url(${coin1}) center / contain no-repeat;
    top: -15px;
    left: 0;

    @media (min-width: 1024px) {
        width: 80px;
        height: 50px;
        top: -20px;
        left: 50px;
    }
`
export const Coin2 = styled.div`
    position: absolute;
    width: 70px;
    height: 40px;
    background: url(${coin2}) center / contain no-repeat;
    background-color: linear-gradient(229.23deg, #fedb5d 3.64%, #ffa413 96.65%);
    top: 15px;
    left: 25px;

    @media (min-width: 1024px) {
        width: 90px;
        height: 60px;
        top: 20px;
        left: 60px;
    }
`
export const Coin3 = styled.div`
    position: absolute;
    width: 90px;
    height: 50px;
    bottom: 60px;
    left: -32px;
    background: url(${coin3}) center / contain no-repeat;
    background-color: linear-gradient(229.23deg, #fedb5d 3.64%, #ffa413 96.65%);

    @media (min-width: 1024px) {
        width: 120px;
        height: 80px;
        bottom: -30px;
        left: 0;
    }
`
