import { ErrorMessage, Field, Form, Formik } from "formik"
import { forwardRef, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import * as Yup from "yup"

import Success from "modules/components/Success/Success"
import { POST_FORM } from "modules/Core/api/REST"
import useAuth from "modules/Core/hooks/useAuth"
import useAuthorizationHeader from "modules/Core/hooks/useAuthorizationHeader"
import { Header } from "pages/PostPage/PostPage.styled"
import { CustomForm, CustomFormItem } from "styles/Global.styled"
import { SelectedFileType } from "types/common"
import { Button } from "ui/Button/Button.styled"
import { Input } from "ui/Input/Input.styled"
import { renderError } from "utils/helpers"
import { InputComponent, TextareaComponent } from "utils/helpers/components/FormikField"

import {
    FileAttachment,
    FileAttachmentText,
    Note,
    StyledLeaveRequest,
    Text,
    LeaveRequestItem,
} from "./LeaveRequest.styled"

interface LeaveRequestForm {
    name: string
    phone: string
    comment: string
}

const validationSchema = Yup.object({
    name: Yup.string().required("Заполните Ваше имя"),
    phone: Yup.string().required("Заполните Ваш номер телефона"),
    comment: Yup.string().required("Комментарий обязательно для заполнения"),
})

//eslint-disable-next-line
const LeaveRequest = forwardRef<HTMLDivElement, any>((props, ref) => {
    const { auth } = useAuth()
    const { id } = useParams()

    const inputFileRef = useRef() as React.MutableRefObject<HTMLInputElement>
    const navigate = useNavigate()
    const headers = useAuthorizationHeader()

    const [selectedFile, setSelectedFile] = useState<SelectedFileType>({})
    const [messageSended, setMessageSended] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")

    const initialValues = {
        name: auth.user?.first_name || "",
        phone: auth.user?.phone || "",
        comment: "",
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target?.files

        if ((!files || files.length === 0) && !selectedFile) {
            setSelectedFile({})
        } else if (files) {
            setSelectedFile({ file: files[0], fileName: files[0].name })
        }
    }

    const handleIconClick = () => {
        inputFileRef.current.click()
    }

    const handleMessageSend = async (values: LeaveRequestForm) => {
        if (!auth?.accessToken) {
            navigate("/auth", { state: { from: location } })
            return
        }

        const formData = new FormData()

        formData.append("post_id", id || "")
        formData.append("user_name", values.name)
        formData.append("phone", values.phone)
        formData.append("comment", values.comment)
        if (selectedFile.file) formData.append("file", selectedFile.file)

        try {
            await POST_FORM("/api/service/orders", formData, headers)
            setMessageSended(true)
        } catch (err) {
            setErrorMessage(
                "Произошла непредвиденная ошибка при отправки сообщения! Попробуйте позже"
            )
        }
    }

    return (
        <StyledLeaveRequest ref={ref}>
            <Header>Оставить предложение</Header>
            {errorMessage && renderError(errorMessage)}
            {!messageSended && (
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleMessageSend}
                    validateOnBlur={false}
                    validationSchema={validationSchema}
                >
                    <Form>
                        <CustomForm>
                            <LeaveRequestItem>
                                <CustomFormItem>
                                    <label htmlFor="">Имя/Название компании</label>
                                    <Field name="name" component={InputComponent} />
                                    <ErrorMessage name="name" render={renderError} />
                                </CustomFormItem>
                                <CustomFormItem>
                                    <label htmlFor="">Телефон</label>
                                    <Field name="phone" component={InputComponent} />
                                    <ErrorMessage name="phone" render={renderError} />
                                </CustomFormItem>
                            </LeaveRequestItem>
                            <LeaveRequestItem>
                                <CustomFormItem>
                                    <label htmlFor="">Комментарий</label>
                                    <Field name="comment" component={TextareaComponent} />
                                    <ErrorMessage name="comment" render={renderError} />
                                </CustomFormItem>
                            </LeaveRequestItem>
                            <LeaveRequestItem justify="space-between">
                                <FileAttachment>
                                    <i onClick={handleIconClick} />
                                    <FileAttachmentText>
                                        <Text>Прикрепить файл</Text>
                                        <Note>
                                            {selectedFile.fileName ||
                                                "Вы можете прикрепить файл до 100 МБ в формате: jpeg,png, jpg"}
                                        </Note>
                                    </FileAttachmentText>
                                    <Input
                                        type="file"
                                        ref={inputFileRef}
                                        onChange={handleFileChange}
                                        hidden={true}
                                    />
                                </FileAttachment>
                                <Button type="submit">Написать продавцу</Button>
                            </LeaveRequestItem>
                        </CustomForm>
                    </Form>
                </Formik>
            )}

            {messageSended && <Success caption="Сообщение успешно отправлено" />}
        </StyledLeaveRequest>
    )
})

export default LeaveRequest
