import HomeAbout from "modules/components/AboutComponent/HomeAbout/HomeAbout"
import { Page } from "styles/Global.styled"

const AboutPostPage = () => {
    return (
        <Page>
            <HomeAbout withOutButton={true} />
        </Page>
    )
}

export default AboutPostPage
