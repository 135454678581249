// import { Facebook, Instagram, StyledSocials, Telegram, VK, WhatsApp } from "./Socials.styled"
import { Facebook, Instagram, StyledSocials, WhatsApp } from "./Socials.styled"

const socialNetworks = {
    whatsapp: "wa.me/77471568377",
    vk: "vk.com",
    facebook: "www.facebook.com/jbfkz",
    instagram: "www.instagram.com/jbforcekz/",
    telegram: "telegram.org",
}

const Socials = () => {
    const handleLink = (event: React.MouseEvent<HTMLDivElement>) => {
        const name = event.currentTarget.id
        console.log(name)
        window.open(`https://${socialNetworks[name as keyof typeof socialNetworks]}`)
    }

    return (
        <StyledSocials>
            <WhatsApp onClick={handleLink} id="whatsapp" />
            {/* <VK onClick={handleLink} id="vk" /> */}
            <Facebook onClick={handleLink} id="facebook" />
            <Instagram onClick={handleLink} id="instagram" />
            {/* <Telegram onClick={handleLink} id="telegram" /> */}
        </StyledSocials>
    )
}

export default Socials
