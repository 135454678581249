import styled from "styled-components"

export const ToggleButtons = styled.div`
    display: flex;
    border-radius: ${({ theme }) => theme.radius.large};
    background-color: ${({ theme }) => theme.colors.lightBlackC};
    width: fit-content;
`

export const ToggleButton = styled.button`
    padding: 0.625rem 1rem;
    font-size: 0.875rem;
    line-height: 1rem;

    border: none;
    outline: none;

    border-radius: ${({ theme }) => theme.radius.small};
    background-color: ${({ theme }) => theme.colors.lightBlackC};
    color: ${({ theme }) => theme.colors.white};

    cursor: pointer;

    &.active {
        background-color: ${({ theme }) => theme.colors.white};
        color: ${({ theme }) => theme.colors.lightBlackC};
        font-weight: 700;
    }
`
