import styled from "styled-components"

import { Button } from "ui/Button/Button.styled"

interface LoadMoreButtonProps {
    handleLoadMore: () => void
}

const StyledLoadMoreButton = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 2rem;

    > button {
        width: fit-content;
    }
`

const LoadMoreButton: React.FC<LoadMoreButtonProps> = ({ handleLoadMore }) => {
    return (
        <StyledLoadMoreButton>
            <Button onClick={handleLoadMore}>Загрузить еще</Button>
        </StyledLoadMoreButton>
    )
}

export default LoadMoreButton
