import { useEffect, useState } from "react"

import { POST } from "modules/Core/api/REST"
import { PostType } from "types/common"

const useSearchResults = (value: string) => {
    const [postList, setPostList] = useState<PostType[]>([])
    const [isLoading, setIsLoading] = useState(true)

    const handleSearch = async () => {
        try {
            const response = await POST("/api/search/by/text", { q: value })
            if (response.data && response.data.data) {
                const { posts } = response.data.data
                setPostList(posts)
            }
        } catch (err) {
            setPostList([])
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (value.length < 3) {
            setPostList([])
            setIsLoading(false)
            return
        } else {
            handleSearch()
        }
    }, [value])

    return { postList, isLoading }
}

export default useSearchResults
