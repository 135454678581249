import { useLocation, useNavigate } from "react-router-dom"

import { MOBILE_MAIN_MENU } from "utils/helpers/list"

import { MobileMenuItem, StyledMobileMenu } from "./MobileMenu.styled"

const MobileMenu = () => {
    const navigate = useNavigate()
    const { pathname } = useLocation()

    return (
        <StyledMobileMenu hide={pathname.includes("/catalogue/post/")}>
            {MOBILE_MAIN_MENU.map((menu) => (
                <MobileMenuItem
                    icon={menu.icon}
                    key={menu.title}
                    onClick={() => navigate(menu.link)}
                >
                    <svg />
                    {menu.title}
                </MobileMenuItem>
            ))}
        </StyledMobileMenu>
    )
}

export default MobileMenu
