import styled from "styled-components"

import usePostList from "modules/Core/hooks/usePostList"

import PostList from "../PostList/PostList"

const StyledSimillaPosts = styled.div`
    margin-top: 3rem;
`

const SimillarPosts = () => {
    const { postList } = usePostList()
    return (
        <StyledSimillaPosts>
            <PostList header="Похожие объявления" postList={postList.slice(0, 4)} />
        </StyledSimillaPosts>
    )
}

export default SimillarPosts
