import { useEffect, useState } from "react"

// import { POST } from "modules/Core/api/REST"
import { GET } from "modules/Core/api/REST"
import useAuthorizationHeader from "modules/Core/hooks/useAuthorizationHeader"
import { UserAppealType } from "types/common"

const useUserAppealList = (type: string) => {
    const [userAppealList, setUserAppealList] = useState<UserAppealType[]>([])
    const [favouriteIDS, setFavouriteIDS] = useState<number[]>([])
    const [isLoading, setIsLoading] = useState(true)

    const headers = useAuthorizationHeader()

    const fGetUserAppeals = async () => {
        const request: Record<string, unknown> = {}

        request.is_self = type !== "send"

        if (type === "archive") request.is_archive = true

        try {
            const response = await GET("/api/service/orders", request, { headers })
            setUserAppealList(response?.data?.data)
            setFavouriteIDS(
                response?.data?.data
                    .filter((item: UserAppealType) => item.order.is_favourite)
                    .map((item: UserAppealType) => item.order.id)
            )
        } catch (err) {
            setUserAppealList([])
            setFavouriteIDS([])
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fGetUserAppeals()
    }, [type])

    return { isLoading, userAppealList, setUserAppealList, favouriteIDS, setFavouriteIDS }
}

export default useUserAppealList
