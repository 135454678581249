import { Fragment, useState } from "react"

import { DocumentType } from "types/common"

import {
    DocumentLine,
    DocumentList,
    DowloadButton,
    Buttons,
    StyledDocuments,
    ViewButton,
} from "./Documents.styled"

import { ALL_DOCUMENTS } from "../../utils"
import PayBanner from "../PayBanner/PayBanner"

interface DocumentsProps {
    documentType: DocumentType
}

const Documents: React.FC<DocumentsProps> = ({ documentType }) => {
    const [showDocumentName, setShowDocumentName] = useState("")

    const handlePreview = (title: string) => {
        setShowDocumentName((current) => (current === title ? "" : title))
    }

    const handleDownload = (url: string) => {
        console.log(url)
        window.open(url, "_blank")
    }

    return (
        <StyledDocuments>
            {documentType.name === "agreements" && <PayBanner />}
            <header>
                {documentType.label}
                {documentType.name === "agreements" ? (
                    <span>
                        Или скачайте 1 документ <b>за 500 ₸*</b>
                    </span>
                ) : (
                    <span>Бесплатно</span>
                )}
            </header>

            <DocumentList>
                {ALL_DOCUMENTS[documentType.name as keyof typeof ALL_DOCUMENTS].map((document) => (
                    <Fragment key={document.title}>
                        <DocumentLine>
                            <div>{document.title}</div>
                            <Buttons>
                                {documentType.name !== "agreements" && (
                                    <ViewButton
                                        title="Просмотр"
                                        onClick={() => handlePreview(document.title)}
                                    >
                                        <div>Просмотр</div>
                                        <i></i>
                                    </ViewButton>
                                )}
                                <DowloadButton
                                    title="Скачать"
                                    onClick={() => handleDownload(document.wordFile)}
                                >
                                    <div>Скачать</div>
                                    <i></i>
                                </DowloadButton>
                            </Buttons>
                        </DocumentLine>
                        {showDocumentName === document.title && (
                            <iframe src={document.previewDocument} />
                        )}
                    </Fragment>
                ))}
            </DocumentList>
        </StyledDocuments>
    )
}

export default Documents
