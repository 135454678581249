import { useState } from "react"
import Select from "react-select"

import useMainContext from "modules/Core/hooks/useMainContext"
import useOptions from "modules/Core/hooks/useOptions"
import { Button } from "ui/Button/Button.styled"
import { selectStyle } from "utils/helpers/variables"

import PriceInputForm from "./components/PriceInputForm/PriceInputForm"
import {
    FilterItem,
    Filters,
    Header,
    PriceFilters,
    StyledFilter,
    FilterMobileButton,
} from "./Filter.styled"

import SideBanner from "../SideBanner/SideBanner"

interface FilterProps {
    handleFilter: (values: Record<string, unknown>) => void
}

const Filter: React.FC<FilterProps> = ({ handleFilter }) => {
    const { cityList, categoryList } = useMainContext()

    const { options: categoryOptions } = useOptions(categoryList)
    const { options: cityOptions } = useOptions(cityList)

    const [values, setValues] = useState<Record<string, unknown>>({})
    const [hideFilters, showFilters] = useState(false)

    const handleFilterShow = () => {
        showFilters((current) => !current)
    }

    const handleSearch = () => {
        if (Object.keys(values).length > 0) handleFilter(values)
    }

    return (
        <StyledFilter>
            <Header>Фильтр</Header>
            <FilterMobileButton onClick={handleFilterShow}>
                Фильтр
                <i />
            </FilterMobileButton>

            <Filters hide={hideFilters}>
                <FilterItem>
                    <label htmlFor="">Город</label>
                    <Select
                        options={cityOptions}
                        placeholder="Выберите город"
                        noOptionsMessage={() => "Данных нет"}
                        styles={selectStyle}
                        isClearable={true}
                        isSearchable={false}
                        onChange={(item) =>
                            setValues((current) => ({ ...current, cityID: item?.value }))
                        }
                        components={{
                            IndicatorSeparator: () => null,
                        }}
                    />
                </FilterItem>

                <FilterItem>
                    <label htmlFor="">Категория</label>
                    <Select
                        options={categoryOptions}
                        placeholder="Выберите категорию"
                        noOptionsMessage={() => "Данных нет"}
                        styles={selectStyle}
                        isClearable={true}
                        isSearchable={false}
                        onChange={(item) =>
                            setValues((current) => ({ ...current, categoryID: item?.value }))
                        }
                        components={{
                            IndicatorSeparator: () => null,
                        }}
                    />
                </FilterItem>

                <FilterItem>
                    <label htmlFor="">Цена, ₸</label>
                    <PriceFilters>
                        <PriceInputForm
                            label="от"
                            handleChange={(item) =>
                                setValues((current) => ({ ...current, priceStart: item }))
                            }
                        />
                        <PriceInputForm
                            label="до"
                            handleChange={(item) =>
                                setValues((current) => ({ ...current, priceEnd: item }))
                            }
                        />
                    </PriceFilters>
                </FilterItem>

                <Button type="button" onClick={handleSearch}>
                    Поиск
                </Button>
            </Filters>

            <SideBanner />
            <SideBanner />
        </StyledFilter>
    )
}

export default Filter
