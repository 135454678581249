import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import CategoriesAbout from "modules/components/AboutComponent/CategoriesAbout/CategoriesAbout"
import Loading from "modules/components/Loading/Loading"
import PostList from "modules/components/PostList/PostList"
import { Container, Page } from "styles/Global.styled"
import { FilterParamsType } from "types/common"

import { StyledCataloguePage } from "./CataloguePage.styled"
import Banner from "./modules/components/Banner/Banner"
import Filter from "./modules/components/Filter/Filter"
import { categoryPagePostPerPage } from "./modules/helpers"
import useFilteredPosts from "./modules/hooks/useFilteredPosts"
import useSelectedCategory from "./modules/hooks/useSelectedCategory"

const CataloguePage = () => {
    const { category: paramCategory } = useParams()
    const { selectedCategory } = useSelectedCategory(Number(paramCategory))
    const [currentPage, setCurrentPage] = useState(1)

    const [filterRequestParams, setFilterRequestParams] = useState<FilterParamsType>({
        categoryID: Number(paramCategory),
        cityID: "",
        priceStart: "",
        priceEnd: "",
        page: currentPage,
        postPerPage: categoryPagePostPerPage,
        loadMore: false,
    })

    const { filterPostList, showLoadMoreBtn, isLoading } = useFilteredPosts(filterRequestParams)

    const handleLoadMore = () => {
        setCurrentPage((currentPage) => currentPage + 1)
        setFilterRequestParams((current) => ({
            ...current,
            page: Number(currentPage + 1),
            loadMore: true,
        }))
    }

    const handleFilter = (values: Record<string, unknown>) => {
        setFilterRequestParams((current) => ({ ...current, ...values, page: 1, loadMore: false }))
        setCurrentPage(1)
    }

    useEffect(() => {
        setFilterRequestParams((current) => ({
            ...current,
            page: 1,
            loadMore: false,
            categoryID: Number(paramCategory),
        }))
        setCurrentPage(1)
    }, [paramCategory])

    return (
        <Page>
            <Banner selectedCategory={selectedCategory} />

            <Container>
                <StyledCataloguePage>
                    <Filter handleFilter={handleFilter} />
                    {isLoading && <Loading />}
                    <PostList
                        header="Все объявления"
                        postList={filterPostList}
                        gridColumns={3}
                        wDisplayBtn
                        handleLoadMore={showLoadMoreBtn ? handleLoadMore : undefined}
                    />
                </StyledCataloguePage>
            </Container>

            <CategoriesAbout categoryID={Number(paramCategory)} />
        </Page>
    )
}

export default CataloguePage
