import styled from "styled-components"

import { scaleForward } from "styles/Animation.styled"

interface MessageButtonProps {
    btnType?: string
}

export const StyledQuickMessages = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
`

export const MessageButton = styled.button<MessageButtonProps>`
    border: 1px solid ${({ theme, btnType }) => theme.colors[btnType || "main"]};
    color: ${({ theme, btnType }) => theme.colors[btnType || "main"]};
    background-color: transparent;
    padding: 0.625rem 1rem;
    font-size: 1rem;
    border-radius: ${({ theme }) => theme.radius.huge};
    width: fit-content;

    cursor: pointer;
    animation: ${scaleForward} 1s ease 0s 1 normal forwards;

    &:hover {
        color: ${({ theme }) => theme.colors.white};
        background-color: ${({ theme, btnType }) => theme.colors[btnType || "main"]};
    }
`
