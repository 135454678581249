import automobile from "assets/tariff/automobile.png"
import bicycle from "assets/tariff/bicycle.png"
import rocket from "assets/tariff/rocket.png"

export const TARIFF_LIST = [
    {
        name: "Легкий старт",
        image: bicycle,
        price: "300",
        hint: "4х больше просмотров",
        conditions: [
            { caption: "Топ-объявление на 3 дня", active: true },
            { caption: "3 поднятия в категориях", active: true },
            { caption: "Вывод на главной странице", active: false },
        ],
    },
    {
        name: "Быстрая продажа",
        image: automobile,
        price: "800",
        hint: "16х больше просмотров",
        conditions: [
            { caption: "Топ-объявление на 7 дней", active: true },
            { caption: "7 поднятий в категориях", active: true },
            { caption: "Вывод на главной странице", active: false },
        ],
    },
    {
        name: "Турбо продажа",
        image: rocket,
        price: "3000",
        hint: "30х больше просмотров",
        conditions: [
            { caption: "Топ-объявление на 3 дня", active: true },
            { caption: "30 поднятия в категориях", active: true },
            { caption: "Вывод на главной странице", active: true },
        ],
    },
]
