// import { useNavigate } from "react-router-dom"

import useMediaQuery from "modules/Core/hooks/useMediaQuery"

import { StyledPostImage, Image, DefaultImage } from "./PostImage.styled"

interface PostImageProps {
    id: number
    image?: string
    height?: string
}

const PostImage: React.FC<PostImageProps> = ({ id, image, height }) => {
    // const navigate = useNavigate()
    const { isMobileDevice } = useMediaQuery()
    const handleCLick = () => {
        // navigate(`/catalogue/post/${id}`)
        window.open(`/catalogue/post/${id}`, isMobileDevice ? "_blank" : "_self")
    }

    return (
        <StyledPostImage height={height} onClick={handleCLick}>
            {!image && <DefaultImage />}
            {image && <Image style={{ backgroundImage: `url("${image}")` }} />}
        </StyledPostImage>
    )
}

export default PostImage
