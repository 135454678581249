import styled from "styled-components"

import icon from "assets/icons/attachment.svg"
import { Row } from "styles/Global.styled"

export const StyledLeaveRequest = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    scroll-margin-top: 5rem;
`

export const FileAttachment = styled.div`
    display: flex;
    align-items: center;
    gap: 1.1875rem;
    width: 100%;

    padding: 1rem;
    background-color: ${({ theme }) => theme.colors.grayE9};
    border-radius: ${({ theme }) => theme.radius.small};

    @media (min-width: 1024px) {
        padding: unset;
        background-color: unset;
        border-radius: unset;
    }

    i {
        width: 1.5rem;
        height: 1.5rem;
        background: url(${icon}) center center / contain no-repeat;
        cursor: pointer;
    }
`

export const FileAttachmentText = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`

export const Text = styled.div`
    font-weight: 500;
`

export const Note = styled.div`
    font-size: 0.8125rem;
    font-weight: 300;
`

export const LeaveRequestItem = styled(Row)`
    flex-direction: column;
    gap: 2rem;

    @media (min-width: 1024px) {
        flex-direction: row;
        gap: 1.875rem;
    }
`
