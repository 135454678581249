import moment from "moment"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"

import Loading from "modules/components/Loading/Loading"
import { DELETE } from "modules/Core/api/REST"
import useAuthorizationHeader from "modules/Core/hooks/useAuthorizationHeader"
import { ActionButtons } from "pages/AdminPanelPage/modules/helpers/components/ActionButtons/ActionButtons.styled"
import { Status } from "pages/AdminPanelPage/modules/helpers/components/Status/Status.styled"
import EditButton from "pages/AdminPanelPage/modules/helpers/components/ui/EditButton/EditButton"
import TrashButton from "pages/AdminPanelPage/modules/helpers/components/ui/TrashButton/TrashButton"
import { Column, Row } from "styles/Global.styled"
import { UserType } from "types/common"
import { Button } from "ui/Button/Button.styled"
import Checkbox from "ui/Checkbox/Checkbox"
import { Input } from "ui/Input/Input.styled"
import { Table } from "ui/Table/Table.styled"

import useUserContext from "../hooks/useUserContext"
import useUserList from "../hooks/useUserList"
import { getUserStatus } from "../utils"

interface UserListProps {
    setCurrentState: React.Dispatch<React.SetStateAction<string>>
    setUserEdit: React.Dispatch<React.SetStateAction<UserType | undefined>>
}

const UserList: React.FC<UserListProps> = ({ setCurrentState, setUserEdit }) => {
    const [selectedUsers, setSelectedUsers] = useState<number[]>([])

    const userCtx = useUserContext()
    const { userList, setUserList, loading, setLoading } = useUserList(
        userCtx.page,
        userCtx.postPerPage,
        userCtx.inputValue
    )

    const headers = useAuthorizationHeader()

    const handleCheckbox = (id: number) => {
        if (selectedUsers.includes(id))
            setSelectedUsers((current) => current.filter((item) => item !== id))
        else setSelectedUsers((current) => [...current, id])
    }

    const handleSelectAll = () => {
        if (selectedUsers.length === userCtx.postPerPage) {
            setSelectedUsers([])
        } else {
            const users = userList.map((item) => item.id as number)
            setSelectedUsers(users)
        }
    }

    useEffect(() => {
        setSelectedUsers([])
    }, [])

    const handleDelete = async (id: number) => {
        if (
            window.confirm(
                "Вы действительно хотите удалить данного пользователя? Удаление отменить невозможно!"
            )
        ) {
            try {
                const { data } = await DELETE("/api/admin/users/" + id, {}, { headers })

                if (data.ok === 1) {
                    toast.success("Пользователь успешно удален!")
                    setUserList((current) => current.filter((user) => user.id !== id))
                } else {
                    throw new Error(JSON.stringify(data.detail))
                }
            } catch (error) {
                toast.error("Произошла ошибка " + error)
            }
        }
    }

    const handleDeleteSelected = async () => {
        if (
            window.confirm(
                "Вы действительно хотите удалить данных пользователей? Удаление отменить невозможно!"
            )
        ) {
            try {
                const { data } = await DELETE(
                    "/api/admin/users/",
                    { users_id: selectedUsers.join(",") },
                    { headers }
                )

                if (data.ok === 1) {
                    toast.success("Пользователи успешно удалены!")
                    setUserList((current) =>
                        current.filter((user) => !selectedUsers.includes(Number(user?.id)))
                    )
                    setSelectedUsers([])
                } else {
                    throw new Error(JSON.stringify(data.detail))
                }
            } catch (error) {
                toast.error("Произошла ошибка " + error)
            }
        }
    }

    const handleEdit = (user: UserType) => {
        setCurrentState("update")
        setUserEdit(user)
    }

    useEffect(() => {
        setLoading(true)
        setSelectedUsers([])
    }, [userCtx.page, userCtx.postPerPage])

    return (
        <>
            <Row justify="space-between">
                <div style={{ width: "100%" }}>
                    <Column gap="1rem">
                        <Row justify="space-between" gap="3rem">
                            <Input
                                type="number"
                                placeholder={`Количество записей на странице: ${userCtx.postPerPage}`}
                                onChange={(e) =>
                                    userCtx.handlePostPerPageChange(+e.currentTarget.value)
                                }
                            />
                            <Input
                                placeholder="Начни вводить текст"
                                value={userCtx.inputValue}
                                onChange={(e) =>
                                    userCtx.handleInputValueChange(e.currentTarget.value)
                                }
                            />
                        </Row>
                    </Column>
                </div>
                <div>
                    {selectedUsers.length > 0 && (
                        <Button btnColor="crimson" onClick={handleDeleteSelected}>
                            Удалить выбранные
                        </Button>
                    )}
                </div>
            </Row>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <Table>
                        <thead>
                            <tr>
                                <td>
                                    <Checkbox
                                        checked={selectedUsers.length === userCtx.postPerPage}
                                        handleChange={handleSelectAll}
                                    />
                                </td>
                                <td>Пользователь</td>
                                <td>Дата регистрации</td>
                                <td>Email</td>
                                <td>Статус</td>
                                <td>Действия</td>
                            </tr>
                        </thead>
                        <tbody>
                            {userList?.length > 0 &&
                                userList.map((user: UserType) => {
                                    const { value: statusType, label: statusText } =
                                        getUserStatus(user)

                                    return (
                                        <tr key={user.id}>
                                            <td>
                                                <Checkbox
                                                    checked={selectedUsers.includes(
                                                        user.id as number
                                                    )}
                                                    handleChange={() =>
                                                        handleCheckbox(user.id as number)
                                                    }
                                                />
                                            </td>
                                            <td>
                                                {user.first_name} {user.surname} {user.patronymic}
                                            </td>
                                            <td>
                                                {moment(user.created_at).format(
                                                    "DD MMMM YYYY HH:mm:SS"
                                                )}
                                            </td>
                                            <td>{user.email}</td>
                                            <td>
                                                <Status type={statusType}>{statusText}</Status>
                                            </td>
                                            <td>
                                                <ActionButtons>
                                                    <EditButton onClick={() => handleEdit(user)} />
                                                    <TrashButton
                                                        onClick={() =>
                                                            handleDelete(user.id as number)
                                                        }
                                                    />
                                                </ActionButtons>
                                            </td>
                                        </tr>
                                    )
                                })}
                        </tbody>
                    </Table>
                    <Row justify="space-between" gap="3rem" style={{ marginTop: "1rem" }}>
                        <Button onClick={userCtx.handleFirstPage} btnColor="lightBlack">
                            Первая страница
                        </Button>
                        <Button onClick={userCtx.handlePrevPage} disabled={userCtx.page === 1}>
                            Предыдущая страница
                        </Button>
                        <Button
                            onClick={() => userCtx.handleNextPage(userList)}
                            disabled={userList.length < userCtx.postPerPage}
                        >
                            Следующая страница
                        </Button>
                    </Row>
                </>
            )}
        </>
    )
}

export default UserList
