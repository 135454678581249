import styled from "styled-components"

import { fadeIn } from "styles/Animation.styled"

interface StyledReviewsProps {
    hide?: boolean
}

export const StyledReviews = styled.div<StyledReviewsProps>`
    display: ${({ hide }) => (hide ? "none" : "flex")};
    flex-direction: column;
    gap: 1rem;
    animation: ${fadeIn} 0.5s ease 0s 1 normal forwards;

    @media (max-width: 1023px) {
        display: flex;
    }
`

export const Review = styled.div`
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    background-color: ${({ theme }) => theme.colors.lightGray};
    border-radius: ${({ theme }) => theme.radius.small};
`

export const User = styled.div`
    font-weight: 500;
`

export const Text = styled.div`
    font-weight: 400;
`

export const Date = styled.div`
    font-size: 0.8125rem;
    font-weight: 400;
`
