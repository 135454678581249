import { FaEye, FaRegComment } from "react-icons/fa"
import { useNavigate } from "react-router-dom"

import {
    PostStatistic,
    Statistic,
    TrashIcon,
} from "modules/components/PostList/modules/components/PostLine/PostLine.styled"
import { POST } from "modules/Core/api/REST"
import useAuth from "modules/Core/hooks/useAuth"
import { Row } from "styles/Global.styled"
import { PostDetailsType } from "types/common"
import { Button } from "ui/Button/Button.styled"

import { Line, Price, Text, City, UserName, StyledPostUserProfile } from "./PostUserProfile.styled"

import PhoneNumber from "../PhoneNumber/PhoneNumber"
import QuickMessages from "../QuickMessages/QuickMessages"
import Rating from "../Rating/Rating"

interface PostUserProfileProps {
    postDetails?: PostDetailsType
    handleSendMessage?: () => void
    isOwner: boolean
}

const PostUserProfile: React.FC<PostUserProfileProps> = ({
    postDetails,
    handleSendMessage,
    isOwner,
}) => {
    const { auth } = useAuth()
    const navigate = useNavigate()

    const handleArchive = async () => {
        if (window.confirm("Вы действительно хотите удалить данное объявление?")) {
            try {
                const headers = {
                    Authorization: `Bearer ${auth?.accessToken}`,
                }
                await POST(`/api/service/user/delete/`, { post_id: postDetails?.post.id }, headers)

                navigate("/cabinet/posts")
            } catch (err) {
                console.log(err)
            }
        }
    }

    return (
        <StyledPostUserProfile>
            <UserName image={postDetails?.partner.image}>
                <i />
                {postDetails?.partner.first_name || postDetails?.partner.email}
            </UserName>

            <Line />

            <Row justify="space-between">
                {postDetails?.post.price === 0 ? (
                    <Text>Цена договорная</Text>
                ) : (
                    <Price>{postDetails?.post.price.toLocaleString()}</Price>
                )}
                {isOwner && <TrashIcon onClick={handleArchive} />}
            </Row>

            <PhoneNumber phoneNumber={postDetails?.partner.phone} />

            <City>{postDetails?.city.name}</City>

            {!isOwner && <Rating rating={postDetails?.post.rating || 0} />}

            <Button onClick={handleSendMessage} disabled={isOwner}>
                Написать продавцу
            </Button>

            {!isOwner ? (
                <QuickMessages />
            ) : (
                <PostStatistic>
                    <Statistic>
                        <FaRegComment size={18} /> {postDetails?.post.order_count}
                    </Statistic>
                    <Statistic>
                        <FaEye size={20} /> {postDetails?.post.post_count}
                    </Statistic>
                </PostStatistic>
            )}
        </StyledPostUserProfile>
    )
}

export default PostUserProfile
