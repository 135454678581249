import { useEffect, useState } from "react"

import { GET } from "modules/Core/api/REST"
import useAuthorizationHeader from "modules/Core/hooks/useAuthorizationHeader"
import { PostType } from "types/common"

const usePostList = (page: number, ppp: number, q?: string) => {
    const [postList, setPostList] = useState<PostType[]>([])
    const [loading, setLoading] = useState(true)
    const headers = useAuthorizationHeader()

    useEffect(() => {
        const fGetList = async () => {
            const { data } = await GET(
                `/api/admin/posts/?page=${page}&post_per_page=${ppp}&q=${q}`,
                {},
                { headers }
            )
            if (data.ok === 1) {
                setPostList(data.data.posts)
                setLoading(false)
            }
        }

        fGetList()
    }, [page, ppp, q])

    return { postList, setPostList, loading, setLoading }
}

export default usePostList
