import styled from "styled-components"

interface TariffIconProps {
    image: string
}

export const StyledTariff = styled.section`
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    gap: 1.875rem;

    @media (min-width: 1024px) {
        grid-template-rows: unset;
        grid-template-columns: repeat(3, 1fr);
    }
`

export const TariffBlock = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;

    border-radius: ${({ theme }) => theme.radius.large};
    box-shadow: 0 0 12px rgb(0 0 0 / 10%);
    padding: 1rem;
`

export const TariffIcon = styled.div<TariffIconProps>`
    background: url("${({ image }) => image}") center center / cover no-repeat;

    height: 56px;
    width: 56px;
`

export const TariffName = styled.div`
    font-weight: 300;
    font-size: 0.875rem;
`

export const TariffPrice = styled.div`
    font-size: 1.125rem;
    font-weight: 600;

    ::after {
        content: "\\20B8";
        margin-left: 5px;
    }
`

export const TariffHint = styled.div`
    font-size: 0.75rem;
    color: ${({ theme }) => theme.colors.main};
    font-weight: 500;
    font-weight: 700;
`

export const TariffConditions = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
    font-size: 0.75rem;
    margin-bottom: 0.5rem;
    width: 100%;

    [data-disabled^="true"] {
        color: ${({ theme }) => theme.colors.darkGray};
    }

    > div::before {
        content: "\\2713";
        margin-right: 0.5rem;
    }
`
