import { useEffect, useState } from "react"

import { GET } from "modules/Core/api/REST"
import useAuthorizationHeader from "modules/Core/hooks/useAuthorizationHeader"
import { CommentType } from "types/common"

const useMessageDetails = (id: number) => {
    const [details, setDetails] = useState<CommentType[]>([])
    const [isLoading, setIsLoading] = useState(true)
    const headers = useAuthorizationHeader()

    const fGetDetails = async () => {
        try {
            const response = await GET(
                `/api/service/order/comment/all/?order_id=${id}`,
                {},
                { headers }
            )
            setDetails(response?.data?.data?.comment_list)
            setIsLoading(false)
        } catch (err) {
            console.error(err)
        }
    }

    useEffect(() => {
        id ? fGetDetails() : null
    }, [id])

    return { details, setDetails, isLoading }
}

export default useMessageDetails
