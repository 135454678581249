import { useState } from "react"

import { PostType } from "types/common"

import LoadMoreButton from "./modules/components/LoadMoreButton/LoadMoreButton"
import Post from "./modules/components/Post/Post"
import PostLine from "./modules/components/PostLine/PostLine"
import {
    DisplayButtons,
    FlexButton,
    GridButton,
    Header,
    PostFlex,
    PostGrid,
    StyledPostList,
} from "./PostList.styled"

import NoDataFound from "../NoDataFound/NoDataFound"

interface PostListProps {
    header: string
    postList: PostType[]
    gridColumns?: number
    wDisplayBtn?: boolean
    displayTypeProp?: string
    handleArchive?: () => void
    handleLoadMore?: () => void
}

const PostList: React.FC<PostListProps> = ({
    header,
    postList,
    gridColumns,
    wDisplayBtn,
    displayTypeProp,
    handleLoadMore,
}) => {
    const [displayType, setDisplayType] = useState(displayTypeProp || "grid")

    return (
        <StyledPostList>
            <Header>
                {header}
                {wDisplayBtn && (
                    <DisplayButtons>
                        <GridButton
                            className={`${displayType === "grid" && "active"}`}
                            onClick={() => setDisplayType("grid")}
                        />
                        <FlexButton
                            className={`${displayType === "flex" && "active"}`}
                            onClick={() => setDisplayType("flex")}
                        />
                    </DisplayButtons>
                )}
            </Header>

            {postList && postList.length === 0 && <NoDataFound />}

            {displayType === "grid" && (
                <PostGrid gridColumns={gridColumns}>
                    {postList &&
                        postList.map((post: PostType) => <Post details={post} key={post.id} />)}
                </PostGrid>
            )}
            {displayType === "flex" && (
                <PostFlex>
                    {postList &&
                        postList.map((post: PostType) => <PostLine details={post} key={post.id} />)}
                </PostFlex>
            )}

            {handleLoadMore && postList.length > 0 && (
                <LoadMoreButton handleLoadMore={handleLoadMore} />
            )}
        </StyledPostList>
    )
}

export default PostList
