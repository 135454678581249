import { Line } from "modules/components/SearchResult/SearchResult.styled"
import useAuth from "modules/Core/hooks/useAuth"
import useMainContext from "modules/Core/hooks/useMainContext"
import { CreatePostForm } from "pages/CreatePostPage/CreatePostPage"
import PhoneNumber from "pages/PostPage/modules/components/PhoneNumber/PhoneNumber"
import {
    City,
    Price,
    StyledPostUserProfile,
    Text,
    UserName,
} from "pages/PostPage/modules/components/PostUserProfile/PostUserProfile.styled"
import { Row } from "styles/Global.styled"
import { CityType } from "types/common"
import { Button } from "ui/Button/Button.styled"

import { StickyOff } from "../PostPreview/PostPreview.styled"

interface PostPreviewUserProfile {
    formValues: CreatePostForm
}

const PostPreviewUserProfile: React.FC<PostPreviewUserProfile> = ({ formValues }) => {
    const { auth } = useAuth()
    const { cityList } = useMainContext()

    const city = cityList.find((city: CityType) => {
        return city.id === formValues.city
    })

    return (
        <StickyOff>
            <StyledPostUserProfile>
                <UserName image={auth?.user?.image}>
                    <i />
                    {formValues?.firstName}
                </UserName>

                <Line />

                <Row justify="space-between">
                    {formValues?.price === 0 || !formValues?.price ? (
                        <Text>Цена договорная</Text>
                    ) : (
                        <Price>{formValues?.price.toLocaleString()}</Price>
                    )}
                </Row>

                <PhoneNumber phoneNumber={formValues?.phone} />

                <City>{city.name}</City>
                <Button disabled={true}>Написать продавцу</Button>
            </StyledPostUserProfile>
        </StickyOff>
    )
}

export default PostPreviewUserProfile
