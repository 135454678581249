export interface RatingListType {
    customer: { value: number; text: string }[]
    partner: { value: number; text: string }[]
}

export const RATING_LIST: RatingListType = {
    partner: [
        {
            value: 1,
            text: "Оплатил только аванс. Недобросовестный заказчик.",
        },
        {
            value: 2,
            text: "Оплатил меньше договорной суммы, ссылаясь на необоснованные претензии к выполнению.",
        },
        {
            value: 3,
            text: "Оплатил заказ, но не оплатил за дополнительные. выполненные Работы",
        },
        {
            value: 4,
            text: "Оплатил заказ с небольшой просрочкой",
        },
        {
            value: 5,
            text: "Оплатил заказ во время",
        },
    ],
    customer: [
        {
            value: 1,
            text: "Не выполнил заказ! (либо выполнена только часть работ)! Отсутствие опыта, недобросовестный исполнитель! ",
        },
        {
            value: 2,
            text: "Выполнил заказ не качественно и не в установленные сроки ",
        },
        {
            value: 3,
            text: "Выполнил заказ со средним качеством и не в установленные сроки ",
        },
        {
            value: 4,
            text: "Выполнил заказ качественно, но не в установленные сроки ",
        },
        {
            value: 5,
            text: "Выполнил заказ качественно и в установленные сроки",
        },
    ],
}
