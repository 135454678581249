import { useState } from "react"

import { ImageType } from "types/common"

const useImageSlider = (images?: ImageType[]) => {
    const [showImageSlider, setShowImageSlider] = useState(false)

    const handleShowSlider = () => {
        if (images && images.length > 0) setShowImageSlider(true)
    }

    const handleCloseSlider = () => {
        setShowImageSlider(false)
    }

    return { showImageSlider, handleShowSlider, handleCloseSlider }
}

export default useImageSlider
