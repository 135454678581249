import { Link } from "react-router-dom"
import styled from "styled-components"

interface StyledLink {
    fontSize?: string
}

export const StyledLink = styled(Link)<StyledLink>`
    color: ${({ theme }) => theme.colors.main};
    font-size: ${({ fontSize }) => fontSize || "1rem"};
`
