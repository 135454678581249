import create from "assets/mobile/create.svg"
import favourites from "assets/mobile/favourites.svg"
import home from "assets/mobile/home.svg"
import messages from "assets/mobile/messages.svg"
import user from "assets/mobile/user.svg"
import { OptionType } from "types/common"

export const MENU_LIST = [
    { title: "О компании", link: "/about" },
    { title: "Политика конфиденциальности", link: "/privacy" },
    { title: "Правила размещения", link: "/placement" },
    { title: "Пользовательское соглашение", link: "/terms" },
    { title: "Создание объявлений", link: "/about-post", footer: true },
    { title: "Категории на сайте", link: "/about-category", footer: true },
]

export const PRIVATE_OPTIONS: OptionType[] = [
    { value: 2, label: "Компания" },
    { value: 1, label: "Частное лицо" },
]

export const MOBILE_MAIN_MENU = [
    { title: "Главная", link: "/", icon: home },
    { title: "Избранное", link: "/cabinet/posts/favourite", icon: favourites },
    { title: "Создать", link: "/post/new", icon: create },
    { title: "Обращения", link: "/cabinet/appeals", icon: messages },
    { title: "Профиль", link: "/cabinet", icon: user },
]
