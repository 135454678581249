import { useFormik } from "formik"
import { useState } from "react"
import * as Yup from "yup"

import Modal, { CloseButton } from "modules/components/Modal/Modal"
import Success from "modules/components/Success/Success"
import { POST } from "modules/Core/api/REST"
import useAuth from "modules/Core/hooks/useAuth"
import { CustomFormItem, Row } from "styles/Global.styled"
import { Button } from "ui/Button/Button.styled"
import Checkbox from "ui/Checkbox/Checkbox"
import { Textarea } from "ui/Textarea/Textarea.styled"
import { renderError } from "utils/helpers"

import { Checkboxes, Header, ModalCustomForm, StyledComplaintModal } from "./ComplaintModal.styled"

interface ComplaintModalProps {
    handleClose: () => void
}

interface ComplaintForm {
    message: string
    messageType: string
}

const LIST = [
    { id: 1, name: "Спам" },
    { id: 2, name: "Агентство в рубрике от частных лиц" },
    { id: 3, name: "Неверная рубрика" },
    { id: 4, name: "Запрещенный товар/услуга" },
    { id: 5, name: "Мошенничество" },
]

const validationSchema = Yup.object({
    message: Yup.string().required("Укажите причину"),
    messageType: Yup.number()
        .oneOf(LIST.map((item) => item.id))
        .required("Выберите один из вариантов"),
})

const ComplaintModal: React.FC<ComplaintModalProps> = ({ handleClose }) => {
    const { auth } = useAuth()
    const [compaintSended, setCompaintSended] = useState(false)

    const handleSubmit = async (values: ComplaintForm) => {
        const header = {
            Authorization: `Bearer ${auth?.accessToken}`,
        }

        try {
            await POST(
                "/api/admin/complaints",
                { message: values.message, type: values.messageType },
                header
            )

            const timer = setTimeout(() => {
                handleClose()
            }, 3000)
            return () => clearTimeout(timer)
        } catch (err) {
            console.log(err)
        } finally {
            setCompaintSended(true)
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            message: "",
            messageType: "",
        },
        validationSchema,
        onSubmit: handleSubmit,
    })

    const handleChange = (item: number) => {
        formik.setFieldValue("messageType", item)
    }

    return (
        <Modal>
            <form onSubmit={formik.handleSubmit}>
                <StyledComplaintModal>
                    <Header>Жалоба</Header>
                    {!compaintSended ? (
                        <>
                            <Checkboxes>
                                {LIST.map((item) => (
                                    <Row gap="0.5rem" key={item.id}>
                                        <Checkbox
                                            checked={+formik.values.messageType === item.id}
                                            handleChange={() => handleChange(item.id)}
                                        />
                                        {item.name}
                                    </Row>
                                ))}
                            </Checkboxes>
                            {formik.errors.messageType && renderError(formik.errors.messageType)}

                            <ModalCustomForm>
                                <CustomFormItem>
                                    <label htmlFor="">Укажите причину:</label>
                                    {/* <Field name="message" component={TextareaComponent} rows={4} /> */}
                                    <Textarea
                                        name="message"
                                        rows={4}
                                        onChange={formik.handleChange}
                                        value={formik.values.message}
                                    />
                                    {formik.errors.message && renderError(formik.errors.message)}
                                </CustomFormItem>
                                <Button type="submit">Отправить</Button>
                            </ModalCustomForm>
                            <CloseButton onClick={handleClose} />
                        </>
                    ) : (
                        <Success caption="Ваша жалоба принята и будет рассмотрена в ближайшее время!" />
                    )}
                </StyledComplaintModal>
            </form>
        </Modal>
    )
}

export default ComplaintModal
