import { createContext, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"

import Loading from "modules/components/Loading/Loading"
import NoDataFound from "modules/components/NoDataFound/NoDataFound"
import PostList from "modules/components/PostList/PostList"
import { POST } from "modules/Core/api/REST"
import useAuthorizationHeader from "modules/Core/hooks/useAuthorizationHeader"
import { ToggleButton, ToggleButtons } from "styles/Global.styled"

import useUserPosts from "./modules/hooks/useUserPosts"
import { StyledUserPosts } from "./UserPosts.styled"

export const UserPostsContext = createContext<{ handleArchive: (postID: number) => void } | null>(
    null
)

const UserPosts = () => {
    const navigate = useNavigate()
    const params = useParams()
    const [postType, setPostType] = useState(params["*"]?.replace("posts/", "") || "active")

    const { userPostList, setUserPostList, isLoading } = useUserPosts(postType)

    const fGetHeader = () => {
        switch (postType) {
            case "active":
                return "Активные объявления"
            case "inactive":
                return "Неактивные объявления"
            default:
                return "Избранные объявления"
        }
    }

    const headers = useAuthorizationHeader()

    const handleArchive = async (postID: number) => {
        if (window.confirm("Вы действительно хотите удалить данное объявление?")) {
            try {
                const request = postType === "active" ? { post_id: postID } : { posts_id: postID }

                const response = await POST(
                    `/api/service/user/${postType === "active" ? "archive" : "delete"}/`,
                    request,
                    headers
                )

                if (response.status === 200) {
                    setUserPostList((current) => current.filter((item) => item.id !== postID))
                } else {
                    throw new Error(JSON.stringify(response.data.detail))
                }
                //eslint-disable-next-line
            } catch (err: any) {
                toast.error(err)
                console.log(err)
            }
        }
    }

    const handleToggle = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const typeName = event.currentTarget.name
        setPostType(typeName)
        navigate(`posts/${typeName}`)
    }

    return (
        <StyledUserPosts>
            <ToggleButtons>
                <ToggleButton
                    name="active"
                    onClick={handleToggle}
                    className={`${postType === "active" && "active"}`}
                >
                    Активные
                </ToggleButton>
                <ToggleButton
                    name="inactive"
                    onClick={handleToggle}
                    className={`${postType === "inactive" && "active"}`}
                >
                    Неактивные
                </ToggleButton>
                <ToggleButton
                    name="favourite"
                    onClick={handleToggle}
                    className={`${postType === "favourite" && "active"}`}
                >
                    Избранные
                </ToggleButton>
            </ToggleButtons>

            {isLoading && <Loading />}
            {!isLoading && userPostList.length === 0 && <NoDataFound />}
            {!isLoading && userPostList.length > 0 && (
                <UserPostsContext.Provider value={{ handleArchive }}>
                    <PostList
                        header={fGetHeader()}
                        postList={userPostList}
                        displayTypeProp="flex"
                    />
                </UserPostsContext.Provider>
            )}
        </StyledUserPosts>
    )
}

export default UserPosts
