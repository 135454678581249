/*eslint-disable*/
import { Container, Page } from "styles/Global.styled"
import Banner from "pages/CataloguePage/modules/components/Banner/Banner"
import Loading from "modules/components/Loading/Loading"
import { useLocation } from "react-router-dom"
import PostList from "modules/components/PostList/PostList"
import useSearchResults from "./hooks/useSearchResults"
import { StyledSearchResultsPage } from "./StyledSearchResultsPage"

const SearchResultsPage = () => {
    const { state } = useLocation()
    const { postList, isLoading } = useSearchResults(state?.text)

    return (
        <Page>
            <Banner selectedCategory={undefined} />

            <Container>
                <StyledSearchResultsPage>
                    {isLoading && <Loading />}

                    <PostList
                        header={`Результаты поиска: ${state?.text} ${
                            postList.length > 0 ? "(" + postList.length + ")" : ""
                        }`}
                        postList={postList}
                        gridColumns={4}
                        wDisplayBtn
                    />
                </StyledSearchResultsPage>
            </Container>
        </Page>
    )
}

export default SearchResultsPage
