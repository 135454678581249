import ReactStars from "react-stars"

import { Row } from "styles/Global.styled"
import { Input } from "ui/Input/Input.styled"

import { StyledLeaveRating, Header, Item, List } from "./LeaveRating.styled"

import { RatingListType, RATING_LIST } from "../../helpers"

interface LeaveRatingProps {
    handleRatingResult: (value: number) => void
    userType: string
}

const LeaveRating: React.FC<LeaveRatingProps> = ({ handleRatingResult, userType }) => {
    return (
        <StyledLeaveRating>
            <Header>Оставьте свой отзыв - это позволит получить рейтинг на платформе</Header>
            <List>
                {RATING_LIST[userType as keyof RatingListType].map((item) => (
                    <Item key={item.value}>
                        <Input
                            type="radio"
                            name="rating"
                            value={item.value}
                            onChange={(e) => {
                                handleRatingResult(Number(e.target.value))
                            }}
                        />
                        <Row gap="1rem">
                            <ReactStars
                                value={item.value}
                                count={item.value}
                                size={20}
                                edit={false}
                            />
                            <label className="caption">{item.text}</label>
                        </Row>
                    </Item>
                ))}
            </List>
        </StyledLeaveRating>
    )
}

export default LeaveRating
