import { useEffect, useState } from "react"

import { PostType } from "types/common"

import useAuth from "./useAuth"
import useAuthorizationHeader from "./useAuthorizationHeader"

import { GET } from "../api/REST"

const useFavouritePostList = () => {
    const [favouritePosts, setFavouritePosts] = useState<PostType[]>([])

    const { auth } = useAuth()
    const headers = useAuthorizationHeader()

    const fGetPostList = async () => {
        const response = await GET(`/api/service/favourites`, {}, { headers })

        if (response && response.data) {
            const { favourites } = response.data.data
            setFavouritePosts(favourites)
        }
    }

    useEffect(() => {
        if (auth?.accessToken) fGetPostList()
    }, [auth])

    return { favouritePosts, setFavouritePosts }
}

export default useFavouritePostList
