import styled from "styled-components"

import comment from "assets/icons/comment.svg"
import listIcon from "assets/icons/list.svg"
import searchIcon from "assets/icons/search.svg"
import { pulse } from "styles/Animation.styled"

interface NavbarItemProps {
    gap?: string
}

interface NavbarButtonProps {
    icon: string
    backgroundColor?: string
}

export const StyledLargeNavbar = styled.div`
    background-color: ${({ theme }) => theme.colors.lightBlack};
    color: ${({ theme }) => theme.colors.white};
    position: sticky;
    top: 32px;
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 0.75rem;

    @media (min-width: 1024px) {
        padding: 1rem 0;
    }

    z-index: 999;
`

export const Navbar = styled.nav`
    display: flex;
    width: 100%;

    @media (min-width: 1024px) {
        display: grid;
        grid-template-columns: 1fr 2fr 1fr;
        gap: 1.875rem;
    }
`

export const NavbarItem = styled.div<NavbarItemProps>`
    display: flex;
    align-items: center;
    gap: ${({ gap }) => gap || 0};

    position: relative;
    width: 100%;

    &:first-child,
    &:last-child {
        @media (max-width: 1023px) {
            display: none;
        }
    }
`

export const Categories = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${({ theme }) => theme.radius.large};
    background-color: ${({ theme }) => theme.colors.brightBlack};
    width: 100%;
    gap: 5px;
    padding: 0.75rem 0;

    cursor: pointer;

    position: relative;

    i {
        width: 24px;
        height: 16px;
        background: url(${listIcon}) center center / contain no-repeat;
    }

    &:hover {
        background-color: ${({ theme }) => theme.colors.main};
    }
`

export const NavbarButton = styled.button<NavbarButtonProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${({ theme }) => theme.radius.large};
    background-color: ${({ theme, backgroundColor }) =>
        theme.colors[backgroundColor || "brightBlack"]};
    color: ${({ theme }) => theme.colors.white};
    border: none;
    outline: none;
    gap: 5px;
    min-height: 100%;
    width: 100%;
    font-size: 0.75rem;
    cursor: pointer;

    i {
        width: 18px;
        height: 18px;
        background: url("${({ icon }) => icon}") center center / contain no-repeat;
    }
`

export const SearchInput = styled.input`
    width: 100%;
    padding: 0 1rem;
    height: 2.5rem;
    border-radius: 12px 0 0 12px;
    border: none;
    outline: none;
    line-height: 0.875rem;
`

export const SearchInputButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    gap: 5px;
    min-height: 100%;
    border-radius: 0 12px 12px 0;
    padding: 0;
    background-color: ${({ theme }) => theme.colors.main};
    min-width: 4rem;

    i {
        width: 1.125rem;
        height: 1.125rem;
        background: url(${searchIcon}) center center / contain no-repeat;
    }
`

export const CategoriesList = styled.div`
    position: absolute;
    top: 3.5rem;
    right: 0;
    background-color: ${({ theme }) => theme.colors.white};
    z-index: 998;

    border-radius: ${({ theme }) => theme.radius.small};
    min-width: 215px;
    padding: 1.25rem 1rem;
`

export const List = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    list-style: none;
    padding: 0;
    margin: 0;

    a {
        color: ${({ theme }) => theme.colors.black};
        text-decoration: none;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1rem;

        &:hover {
            text-decoration: underline;
            color: ${({ theme }) => theme.colors.main};
            font-weight: 600;
        }

        &::after {
            content: "\\276F";
            float: right;
        }
    }
`

export const BackgroundBlur = styled.div`
    position: absolute;
    top: 0;
    width: 100%;

    &::after {
        background: rgba(0, 0, 0, 0.5);
        content: "";
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        z-index: 997;
    }
`

export const ShowFeedbackButton = styled.div`
    padding: 0.75rem;
    background-color: ${({ theme }) => theme.colors.black};
    border-radius: ${({ theme }) => theme.radius.small};

    position: fixed;
    bottom: 10.5%;
    right: 3%;

    z-index: 995;

    cursor: pointer;

    animation: ${pulse} 2s ease 0s infinite normal forwards;
`

export const ShowFeedbackIcon = styled.div`
    width: 2rem;
    height: 2rem;
    background: url(${comment}) center center / contain no-repeat;
`
