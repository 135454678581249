import styled from "styled-components"

export const StyledRating = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.875rem;
    padding: 0.875rem 0.6875rem;

    background-color: ${({ theme }) => theme.colors.darkGrayE};
    border-radius: ${({ theme }) => theme.radius.small};

    section {
        display: flex;
        flex-direction: column;
        gap: 6px;
    }
`

export const Text = styled.div`
    font-weight: 700;
    font-size: 1.875rem;
`

export const Note = styled.div`
    font-weight: 400;
    font-size: 0.625rem;
    line-height: 0.75rem;
`
