import { useEffect, useState } from "react"

import { ToastType } from "types/common"

import {
    CloseIcon,
    CloseText,
    Content,
    Header,
    Icon,
    StyledToast,
    Text,
} from "./CustomToast.styled"

export interface CustomToastProps {
    toastProps: ToastType
}

const CustomToast: React.FC<CustomToastProps> = ({ toastProps }) => {
    const [isShowing, setIsShowing] = useState(toastProps.display)

    const handleHide = () => {
        setIsShowing(false)
    }

    useEffect(() => {
        setIsShowing(toastProps.display)

        if (toastProps.display === true) {
            const interval = setInterval(() => {
                handleHide()
            }, 10000)
            return () => clearInterval(interval)
        }
    }, [toastProps])

    return (
        <>
            {isShowing && (
                <StyledToast>
                    <Icon />

                    <Content>
                        <Header>
                            Ошибка!
                            <CloseIcon onClick={handleHide} />
                        </Header>
                        <Text>{toastProps.message}</Text>
                        <CloseText onClick={handleHide}>Закрыть</CloseText>
                    </Content>
                </StyledToast>
            )}
        </>
    )
}

export default CustomToast
