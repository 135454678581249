import styled from "styled-components"

import filter from "assets/icons/filter.svg"
import { GHeader } from "styles/Global.styled"
import { Button } from "ui/Button/Button.styled"

interface FiltersProps {
    hide?: boolean
}

export const StyledFilter = styled.div`
    display: flex;
    flex-direction: column;
`

export const Header = styled(GHeader)`
    display: none;

    @media (min-width: 1024px) {
        margin-bottom: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
`

export const Filters = styled.div<FiltersProps>`
    display: flex;
    flex-direction: column;
    gap: 1.125rem;

    @media (max-width: 1023px) {
        display: ${({ hide }) => (hide ? "flex" : "none")};
    }
`

export const FilterItem = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;

    > label {
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 0.875rem;
        margin-left: 4px;

        @media (max-width: 1023px) {
            display: none;
        }
    }
`

export const PriceFilters = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`

export const FilterMobileButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    > i {
        background: url(${filter}) center / contain no-repeat;
        width: 22px;
        height: 22px;
    }

    @media (min-width: 1024px) {
        display: none;
    }
`
