import { useState } from "react"

import { Header } from "pages/AdminPanelPage/AdminPanelPage.styled"
import { CategoryType } from "types/common"

import CategoryList from "./components/CategoryList"
import CreateCategoryForm from "./components/CreateCategoryForm"

const Category = () => {
    const [currentState, setCurrentState] = useState("list")
    const [categoryEdit, setCategoryEdit] = useState<CategoryType>()

    return (
        <>
            <Header>Категории</Header>

            {currentState === "list" ? (
                <CategoryList setCurrentState={setCurrentState} setCategoryEdit={setCategoryEdit} />
            ) : (
                <CreateCategoryForm setCurrentState={setCurrentState} categoryEdit={categoryEdit} />
            )}
        </>
    )
}

export default Category
