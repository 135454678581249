import styled from "styled-components"

import { fadeIn } from "styles/Animation.styled"

const StyledLoading = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;

    animation: ${fadeIn} 0.5s linear 0s 1 normal forwards;

    &::after {
        background: rgba(0, 0, 0, 0.5);
        content: "";
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        z-index: 9998;
    }
`

const Loader = styled.span`
    font-size: 3rem;
    font-family: "Druk Wide Bold";
    color: ${({ theme }) => theme.colors.main};
    letter-spacing: 5px;
    position: relative;
    z-index: 9999;

    &::after {
        content: "JBF";
        position: absolute;
        left: 0;
        top: 0;
        color: ${({ theme }) => theme.colors.white};
        width: 100%;
        height: 100%;
        overflow: hidden;
        animation: animloader 5s linear infinite;
        z-index: 1002;
    }

    @keyframes animloader {
        0% {
            height: 100%;
        }
        100% {
            height: 0%;
        }
    }
`

const Loading = () => {
    return (
        <StyledLoading>
            <Loader>JBF</Loader>
        </StyledLoading>
    )
}

export default Loading
