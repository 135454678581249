import ReactStars from "react-stars"

import { Header } from "pages/PostPage/PostPage.styled"
import { ReviewType } from "types/common"

import { Date, Review, StyledReviews, Text, User } from "./Reviews.styled"

interface ReviewsProps {
    reviews?: ReviewType[]
    hide?: boolean
}

const Reviews: React.FC<ReviewsProps> = ({ reviews, hide }) => {
    return (
        <StyledReviews hide={hide}>
            <Header>Отзывы ({reviews?.length})</Header>

            {reviews &&
                reviews.length > 0 &&
                reviews.map((review, index) => (
                    <Review key={index}>
                        <User>{review.title}</User>
                        <Text>{review.comment}</Text>
                        <ReactStars
                            value={(review.rating * 100) / 5}
                            count={5}
                            size={24}
                            half
                            edit={false}
                        />
                        <Date>{review.date}</Date>
                    </Review>
                ))}
        </StyledReviews>
    )
}

export default Reviews
