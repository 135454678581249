import { createContext } from "react"

import useFavouritePostList from "../hooks/useFavouritePostList"
import useServiceList from "../hooks/useServiceInfo"

//eslint-disable-next-line
export const MainContext = createContext<any>({})

export const MainProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { categoryList, cityList, sliderList } = useServiceList()
    const { favouritePosts, setFavouritePosts } = useFavouritePostList()

    return (
        <MainContext.Provider
            value={{ categoryList, cityList, sliderList, favouritePosts, setFavouritePosts }}
        >
            {children}
        </MainContext.Provider>
    )
}

export default MainContext
