import { useState } from "react"
import { useParams } from "react-router-dom"

import { POST_FORM } from "modules/Core/api/REST"
import useAuth from "modules/Core/hooks/useAuth"
import useAuthorizationHeader from "modules/Core/hooks/useAuthorizationHeader"

import { MessageButton, StyledQuickMessages } from "./QuickMessages.styled"

const MESSAGE_LIST = ["Договор заключаете?", "Материалы нужно закупать?", "Какой стаж работы?"]

const QuickMessages = () => {
    const { auth } = useAuth()
    const { id } = useParams()
    const [messageSend, setMessageSend] = useState(false)
    const headers = useAuthorizationHeader()

    const handleQuickMessage = async (message: string) => {
        const formData = new FormData()

        formData.append("post_id", id || "")
        formData.append("user_name", auth?.user?.first_name)
        formData.append("phone", auth?.user?.phone)
        formData.append("comment", message)

        try {
            await POST_FORM("/api/service/orders", formData, headers)
            setMessageSend(true)
        } catch (err) {
            setMessageSend(false)
        }
    }

    return (
        <StyledQuickMessages>
            {!messageSend &&
                MESSAGE_LIST.map((message) => (
                    <MessageButton key={message} onClick={() => handleQuickMessage(message)}>
                        {message}
                    </MessageButton>
                ))}

            {messageSend && <MessageButton btnType="green">Сообщение отправлено</MessageButton>}
        </StyledQuickMessages>
    )
}

export default QuickMessages
