import styled from "styled-components"

interface StatusProps {
    type: string
}

export const Status = styled.div<StatusProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;

    background: ${({ theme, type }) => theme.colors.admin.status[type]};
    border-radius: 12px;
    color: #fff;
    width: max-content;
`
