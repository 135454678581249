import styled from "styled-components"

import { Input } from "ui/Input/Input.styled"

export const StyledPriceInputForm = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;

    > span {
        display: flex;
        width: 100%;

        &:focus-within {
            border-radius: ${({ theme }) => theme.radius.small};
            border: 1px solid ${({ theme }) => theme.colors.main};
        }
    }
`

export const AddonPrepend = styled.div`
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    > span {
        border-radius: 8px 0 0 8px;
        background-color: ${({ theme }) => theme.colors.lightGrayInput};
        display: flex;
        align-items: center;
        text-align: center;
        border: none;
        padding: 12px;
    }
`

export const PriceInput = styled(Input)`
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    border: none;
    border-radius: 0 8px 8px 0;
    background-color: ${({ theme }) => theme.colors.lightGrayInput};

    &:focus {
        border: none;
        outline: none;
    }
`
