import styled from "styled-components"

import xmark from "assets/icons/xmark.svg"
import { fadeIn } from "styles/Animation.styled"

export const ModalWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 1001;
    background: rgba(0, 0, 0, 0.5);

    display: flex;
    justify-content: center;
    align-items: center;

    animation: ${fadeIn} 1s ease 0s 1 normal forwards;
`

export const CloseButton = styled.div`
    background: url(${xmark}) center no-repeat;
    background-color: ${({ theme }) => theme.colors.main};
    z-index: 1;
    border-radius: ${({ theme }) => theme.radius.small};
    cursor: pointer;
    height: 2rem;
    width: 2rem;
    position: absolute;
    right: 0;
    top: 0;
`

interface ModalProps {
    children: React.ReactNode
}

const Modal: React.FC<ModalProps> = ({ children }) => {
    return <ModalWrapper>{children}</ModalWrapper>
}

export default Modal
