import React from "react"

import CategoriesAbout from "modules/components/AboutComponent/CategoriesAbout/CategoriesAbout"
import useCategoryList from "modules/Core/hooks/useCategoryList"
import { Page } from "styles/Global.styled"

const AboutCategoryPage = () => {
    const { categoryList } = useCategoryList()
    return (
        <Page>
            {categoryList &&
                categoryList?.map((category) => (
                    <CategoriesAbout
                        key={category.id}
                        categoryID={category.id}
                        withOutButton={true}
                    />
                ))}
        </Page>
    )
}

export default AboutCategoryPage
