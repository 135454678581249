import { useNavigate } from "react-router-dom"

import Breadcrumbs from "modules/components/Breadcrumbs/Breadcrumbs"
import useMainContext from "modules/Core/hooks/useMainContext"
import { Container, Row } from "styles/Global.styled"
import { CategoryType } from "types/common"
import { DOWNLOAD_JBF_ID } from "utils/helpers/variables"

import { StyledBanner } from "./Banner.styled"
import { ToggleButtons, ToggleButton } from "./ToggleButtons/ToggleButtons.styled"

const routes = [""]

interface BannerProps {
    selectedCategory: CategoryType | undefined
}

const Banner: React.FC<BannerProps> = ({ selectedCategory }) => {
    const { categoryList } = useMainContext()

    const navigate = useNavigate()

    return (
        <StyledBanner>
            <Container>
                <Breadcrumbs
                    routes={routes}
                    color="white"
                    lastColor="white"
                    additionalRoute={selectedCategory}
                />

                <Row justify="center">
                    <ToggleButtons>
                        {categoryList &&
                            categoryList
                                .filter((category: CategoryType) => category.id !== DOWNLOAD_JBF_ID)
                                .map((category: CategoryType) => (
                                    <ToggleButton
                                        className={
                                            selectedCategory === category ? "active" : undefined
                                        }
                                        key={category.id}
                                        onClick={() => navigate(`/catalogue/${category.id}`)}
                                    >
                                        {category.name}
                                    </ToggleButton>
                                ))}
                    </ToggleButtons>
                </Row>
            </Container>
        </StyledBanner>
    )
}

export default Banner
