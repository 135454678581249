import styled from "styled-components"

export const StyledCheckbox = styled.div`
    border: 0.0625rem solid #cccccc;
    background-color: $whiteColor;
    height: 1.5rem;
    width: 1.5rem;
    min-height: 1.5rem;
    min-width: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${({ theme }) => theme.radius.small};
    border-color: ${({ theme }) => theme.colors.grayC4};
    cursor: pointer;
`

export const CheckIcon = styled.i`
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs, 1));
    width: 1.375rem;
    height: 1.375rem;
    border: 0.125rem solid transparent;
    border-radius: 100px;

    &::after {
        content: "";
        display: block;
        box-sizing: border-box;
        position: absolute;
        left: 0.1875rem;
        top: -0.0625rem;
        width: 0.375rem;
        height: 0.625rem;
        border-width: 0 0.125rem 0.125rem 0;
        border-style: solid;
        transform-origin: bottom left;
        transform: rotate(45deg);
        color: ${({ theme }) => theme.colors.main};
    }
`
