/*eslint-disable */
import { useEffect, useRef, useState } from "react"

import { GHeader } from "styles/Global.styled"
import { Button } from "ui/Button/Button.styled"
import { Input } from "ui/Input/Input.styled"

import {
    Caption,
    Drag,
    PictureContainer,
    PictureGallery,
    PictureSection,
    SubHeader,
} from "./Pictures.styled"

import CreatePostImages from "../CreatePostImages/CreatePostImages"
import { useLocation } from "react-router-dom"
import usePostImages from "../../hooks/usePostImages"
import { POST } from "modules/Core/api/REST"
import useAuthorizationHeader from "modules/Core/hooks/useAuthorizationHeader"

interface PicturesProps {
    //eslint-disable-next-line
    setFormPostImages: React.Dispatch<any>
}

const Pictures: React.FC<PicturesProps> = ({ setFormPostImages }) => {
    const { state } = useLocation()
    const headers = useAuthorizationHeader()

    const inputFileRef = useRef() as React.MutableRefObject<HTMLInputElement>

    const [selectedFiles, setSelectedFiles] = useState<any[]>([])
    const [mainImage, setMainImage] = useState<any>()
    const [dragActive, setDragActive] = useState(false)

    usePostImages(state?.postDetails, setSelectedFiles, setMainImage)

    const handleAddClick = () => {
        inputFileRef.current.click()
    }

    const handleDrag = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault()
        event.stopPropagation()

        if (event.type === "dragenter" || event.type === "dragover") {
            setDragActive(true)
        } else if (event.type === "dragleave") {
            setDragActive(false)
        }
    }

    const handleDrop = (event: React.DragEvent) => {
        event.preventDefault()
        event.stopPropagation()
        setDragActive(false)

        if (event.dataTransfer.files && event.dataTransfer.files[0])
            handleImageUpload(event.dataTransfer.files)
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        handleImageUpload(event.target?.files)
    }

    const handleImageUpload = (files: FileList | null) => {
        if (files) {
            if (!mainImage) setMainImage(files[0])
            const fileList = Array.from(files).map((file) => file)
            setSelectedFiles((current) => [...current, ...fileList])
        }
    }

    const handleImageDelete = (event: React.MouseEvent<HTMLDivElement>, image: any) => {
        event.stopPropagation()

        if (image.id) {
            try {
                POST("/api/service/inner/imgs/delete/", { img_ids: image.id }, headers)

                const images = selectedFiles.filter((file) => file?.id !== image?.id)

                if (image.id === mainImage?.id) setMainImage(images[0])
                if (images.length === 0) setMainImage(undefined)
                setSelectedFiles(images)
            } catch (err) {
                console.log(err)
            }
        } else {
            const images = selectedFiles.filter((file) => file !== image)
            if (image === mainImage) setMainImage(images[0])
            if (images.length === 0) setMainImage(undefined)
            setSelectedFiles(images)
        }
    }

    useEffect(() => {
        setFormPostImages({ main: mainImage, imageList: selectedFiles })
    }, [selectedFiles, mainImage])

    return (
        <PictureSection>
            {dragActive && (
                <Drag
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                />
            )}
            <PictureContainer onDragEnter={handleDrag}>
                <Caption>
                    <i />
                    Перетащите фотографии с вашего устройства
                </Caption>

                <Button type="button" onClick={handleAddClick}>
                    Добавить фото
                </Button>
                <Input
                    type="file"
                    hidden
                    ref={inputFileRef}
                    onChange={handleFileChange}
                    accept="image/*"
                    multiple
                />
            </PictureContainer>

            {selectedFiles.length > 0 && (
                <PictureGallery>
                    <GHeader>Отображение галереи</GHeader>
                    <SubHeader>Чтобы выбрать основное фото, кликните на фотографию</SubHeader>

                    <CreatePostImages
                        images={selectedFiles}
                        main={mainImage}
                        setMainImage={setMainImage}
                        handleImageDelete={handleImageDelete}
                    />
                </PictureGallery>
            )}
        </PictureSection>
    )
}

export default Pictures
