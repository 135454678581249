import styled from "styled-components"

interface MarketIconProps {
    url: string
}

export const StyledMarketIcons = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`

export const MarketIcon = styled.div<MarketIconProps>`
    min-width: 8.5rem;
    max-width: 8.5rem;
    min-height: 2.625rem;
    max-height: 2.625rem;
    background: url(${({ url }) => url}) center center / contain no-repeat;
    cursor: pointer;
`
