import { Link } from "react-router-dom"

import { StyledBreadcrumbs } from "./Breadcrumbs.styled"

const ROUTE_LIST = {
    main: { ru: "Главная", link: "/" },
    info: { ru: "Информация", link: "/" },
    placement: { ru: "Правила", link: "/placement/" },
    privacy: { ru: "Политика", link: "/privacy/" },
    terms: { ru: "Политика", link: "/terms/" },
    about: { ru: "О сервисе", link: "/about/" },
    rating: { ru: "Рейтинг", link: "/rating/" },
    catalogue: { ru: "Каталог", link: "/catalogue/" },
    post: { ru: "Объявление", link: "/catalogue/construction/new" },
    new: { ru: "Объявление", link: "/catalogue/construction/new" },
    cabinet: { ru: "Личный кабинет", link: "/cabinet" },
    messages: { ru: "Обращения", link: "/cabinet/appeals" },
    messagePost: { ru: "Объявление", link: "" },
    documents: { ru: "Документы", link: "" },
}

interface BreadcrumbsProps {
    routes: string[]
    additionalRoute?: { name: string }
    color?: string
    lastColor?: string
    hide?: boolean
}

const fGetRouteTitle = (route: string) => {
    try {
        return ROUTE_LIST[route as keyof typeof ROUTE_LIST].ru
    } catch (e) {
        return null
    }
}

const fGetRouteLink = (route: string) => {
    try {
        return ROUTE_LIST[route as keyof typeof ROUTE_LIST].link
    } catch (e) {
        return ""
    }
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
    routes,
    additionalRoute,
    color,
    lastColor,
    hide,
}) => {
    const cabinet = routes.includes("cabinet")

    return (
        <StyledBreadcrumbs color={color} lastColor={lastColor} hide={hide}>
            <ul>
                {!cabinet && (
                    <li>
                        <Link to="/">{fGetRouteTitle("main")}</Link>
                    </li>
                )}

                {routes.map(
                    (route, ind) =>
                        fGetRouteTitle(route) && (
                            <li key={ind}>
                                <Link to={fGetRouteLink(route)}>{fGetRouteTitle(route)}</Link>
                            </li>
                        )
                )}

                {additionalRoute && (
                    <li>
                        {/* eslint-disable-next-line */}
                        <a href="#">{additionalRoute.name}</a>
                    </li>
                )}
            </ul>
        </StyledBreadcrumbs>
    )
}

export default Breadcrumbs
