import styled from "styled-components"

export const StyledPhoneNumber = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`
export const Phone = styled.div`
    font-size: 1.5rem;
    font-weight: 600;
`

export const ShowButton = styled.div`
    color: ${({ theme }) => theme.colors.main};
    text-decoration: underline;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
`
