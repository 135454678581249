/* eslint-disable */
import { useEffect, useState } from "react"

import { CategoryType, SliderType } from "types/common"

import { GET } from "../api/REST"

const useServiceList = () => {
    const [categoryList, setCategoryList] = useState<CategoryType[]>()
    const [cityList, setCityList] = useState<CategoryType[]>()
    const [sliderList, setSliderList] = useState<SliderType[]>()

    const fGetServiceInfo = async () => {
        const { data: response } = await GET("/api/service/info")

        if (response.ok === 1) {
            const { data } = response
            const categories = Object.keys(data.categories).map(
                (category) => data.categories[category]
            )
            const cities = Object.keys(data.cities).map((city) => data.cities[city])
            setCategoryList(categories)
            setCityList(cities)
            setSliderList(data.sliders.sort((a: SliderType, b: SliderType) => a.order - b.order))
        }
    }

    useEffect(() => {
        fGetServiceInfo()
    }, [])

    return { categoryList, cityList, sliderList }
}

export default useServiceList
