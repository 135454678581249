import { useLocation, Navigate, Outlet } from "react-router-dom"

import useAuth from "../hooks/useAuth"

interface RequireAuthProps {
    allowedRoles: string[]
}

const RequireAuth: React.FC<RequireAuthProps> = ({ allowedRoles }) => {
    const { auth } = useAuth()
    const location = useLocation()

    return auth?.role && allowedRoles.includes(auth.role) ? (
        <Outlet />
    ) : auth?.user ? (
        <Navigate to="/unauthorized" state={{ from: location }} replace />
    ) : (
        <Navigate to="/auth" state={{ from: location }} replace />
    )
}

export default RequireAuth
