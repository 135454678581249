/* eslint-disable @typescript-eslint/no-explicit-any*/
import {
    ImageList,
    MainImage,
    SmallImage,
    StyledCreatePostImages,
    TrashIcon,
} from "./CreatePostImages.styled"

interface CreatePostImagesProps {
    images?: any[]
    main?: any
    setMainImage: React.Dispatch<React.SetStateAction<any[]>>
    handleImageDelete: (event: React.MouseEvent<HTMLDivElement>, image: any) => void
}

const CreatePostImages: React.FC<CreatePostImagesProps> = ({
    images = [],
    main,
    setMainImage,
    handleImageDelete,
}) => {
    const mainImage = main ? main?.img || URL.createObjectURL(main) || "" : ""

    return (
        <StyledCreatePostImages>
            <MainImage image={mainImage} />

            <ImageList>
                {images &&
                    images.map((image: any, index: number) => (
                        <SmallImage
                            key={index}
                            className={`${main === image ? "active" : ""}`}
                            image={image.img || URL.createObjectURL(image)}
                            onClick={() => setMainImage(image)}
                        >
                            <TrashIcon onClick={(event) => handleImageDelete(event, image)}>
                                <svg />
                            </TrashIcon>
                        </SmallImage>
                    ))}
            </ImageList>
        </StyledCreatePostImages>
    )
}

export default CreatePostImages
