import { useState } from "react"

import { StyledAdminPanelPage, StyledWorkspace } from "./AdminPanelPage.styled"
import AdminPanelNavigation from "./modules/components/AdminPanelNavigation/AdminPanelNavigation"
import Category from "./modules/components/Category/Category"
import Complaint from "./modules/components/Complaint/Complaint"
import Post from "./modules/components/Post/Post"
import Slider from "./modules/components/Slider/Slider"
import Users from "./modules/components/User/Users"
import AdminContext from "./modules/context/AdminContext"
import { NAVIGATION_ITEMS, NavigatonItemType } from "./modules/helpers"

const AdminPanelPage = () => {
    const [activeNavigation, setActiveNavigation] = useState<NavigatonItemType>(NAVIGATION_ITEMS[0])

    return (
        <AdminContext.Provider value={{ activeNavigation, setActiveNavigation }}>
            <StyledAdminPanelPage>
                <AdminPanelNavigation />

                <StyledWorkspace>
                    {activeNavigation.name === "complaints" && <Complaint />}
                    {activeNavigation.name === "user" && <Users />}
                    {activeNavigation.name === "category" && <Category />}
                    {activeNavigation.name === "slider" && <Slider />}
                    {activeNavigation.name === "post" && <Post />}
                </StyledWorkspace>
            </StyledAdminPanelPage>
        </AdminContext.Provider>
    )
}

export default AdminPanelPage
