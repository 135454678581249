import styled from "styled-components"

import offIcon from "assets/icons/off.svg"
import onIcon from "assets/icons/on.svg"

interface PriceToggleProps {
    active: boolean
}
interface StyledCreatePostPageProps {
    hide: boolean
}

export const StyledCreatePostPage = styled.section<StyledCreatePostPageProps>`
    display: ${({ hide }) => (hide ? "none" : "flex")};
    flex-direction: column;
    margin-top: 2rem;
    gap: 1.5rem;
`

export const CreatePost = styled.div`
    display: grid;
    grid-template-rows: 1fr auto;

    @media (min-width: 1024px) {
        grid-template-columns: 50% 50%;
    }

    gap: 2rem;
`

export const Note = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;

    font-weight: 500;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: ${({ theme }) => theme.colors.darkGray};
`

export const PriceToggle = styled.i<PriceToggleProps>`
    width: 52px;
    height: 32px;
    transition: all 0.3s linear;
    background: url(${({ active }) => (active ? onIcon : offIcon)}) center/contain no-repeat;
    cursor: pointer;
`
