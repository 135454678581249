import { useRef } from "react"
import { Navigate, Route, Routes } from "react-router-dom"

import Footer from "modules/components/Footer/Footer"
import PersistLogin from "modules/Core/hoc/PersistLogin"
import RequireAuth from "modules/Core/hoc/RequireAuth"
import useScrollToTop from "modules/Core/hooks/useScrollToTop"
import NotFound from "pages/404/404"
import AboutCategoryPage from "pages/AboutCategoryPage/AboutCategoryPage"
import AboutCompanyPage from "pages/AboutCompanyPage/AboutCompanyPage"
import AboutPostPage from "pages/AboutPostPage/AboutPostPage"
import LargeNavbar from "pages/ApplicationPage/modules/components/LargeNavbar/LargeNavbar"
import CabinetPage from "pages/CabinetPage/CabinetPage"
import CataloguePage from "pages/CataloguePage/CataloguePage"
import CreatePostPage from "pages/CreatePostPage/CreatePostPage"
import DocumentsPage from "pages/DocumentsPage/DocumentsPage"
import HomePage from "pages/HomePage/HomePage"
import InboxMessagePage from "pages/InboxMessagePage/InboxMessagePage"
import PlacementRulesPage from "pages/PlacementRulesPage/PlacementRulesPage"
import PostPage from "pages/PostPage/PostPage"
import PrivacyPolicyPage from "pages/PrivacyPolicyPage/PrivacyPolicyPage"
import RatingSystemPage from "pages/RatingSystemPage/RatingSystemPage"
import SearchResultsPage from "pages/SearchResultsPage/SearchResultsPage"
import TermsOfUsePage from "pages/TermsOfUsePage/TermsOfUsePage"
import Unauthorized from "pages/Unauthorized/Unauthorized"
import { AppContainer, EmptyFlexContainer, MainContainer } from "styles/Global.styled"

const ApplicationPage = () => {
    const ref = useRef() as React.MutableRefObject<HTMLDivElement | null>
    useScrollToTop(ref)

    return (
        <>
            <LargeNavbar />

            <MainContainer>
                <AppContainer>
                    <Routes>
                        <Route element={<PersistLogin />}>
                            <Route index element={<HomePage />} />
                            <Route path="about" element={<AboutCompanyPage />} />
                            <Route path="privacy" element={<PrivacyPolicyPage />} />
                            <Route path="placement" element={<PlacementRulesPage />} />
                            <Route path="terms" element={<TermsOfUsePage />} />
                            <Route path="ratingsystem" element={<RatingSystemPage />} />
                            <Route path="about-post" element={<AboutPostPage />} />
                            <Route path="about-category" element={<AboutCategoryPage />} />
                            <Route path="documents" element={<DocumentsPage />} />
                            <Route path="search-results" element={<SearchResultsPage />} />

                            <Route path="unauthorized" element={<Unauthorized />} />

                            <Route path="/catalogue" element={<Navigate to="/" replace />} />
                            <Route path="/catalogue/:category" element={<CataloguePage />} />
                            <Route path="/catalogue/post/:id" element={<PostPage />} />

                            <Route element={<RequireAuth allowedRoles={["client", "admin"]} />}>
                                <Route
                                    path="/cabinet"
                                    element={<Navigate to="/cabinet/profile" replace />}
                                />
                                <Route path="/cabinet/*" element={<CabinetPage />} />
                                <Route
                                    path="/cabinet/appeals/message"
                                    element={<InboxMessagePage />}
                                />
                                <Route path="/post/new" element={<CreatePostPage />} />
                            </Route>
                            <Route path="*" element={<NotFound />} />
                        </Route>
                    </Routes>

                    <EmptyFlexContainer />
                    <Footer />
                </AppContainer>
            </MainContainer>
        </>
    )
}

export default ApplicationPage
