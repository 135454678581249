import { FormikProps } from "formik"
import { useState } from "react"

import { CreatePostForm } from "pages/CreatePostPage/CreatePostPage"

const useContractPrice = (price: number | string) => {
    const [isContractPrice, setIsContractPrice] = useState(price === 0)

    const fTogglePrice = (formikProps: FormikProps<CreatePostForm>) => {
        formikProps.setFieldValue("price", "")
        setIsContractPrice((current) => !current)
    }
    return { isContractPrice, fTogglePrice }
}

export default useContractPrice
