import styled from "styled-components"

import camera from "assets/icons/camera.svg"

export const PictureSection = styled.section`
    display: flex;
    flex-direction: column;
    gap: 2rem;
`

export const PictureContainer = styled.div`
    width: 100%;
    max-height: 540px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;

    background-color: ${({ theme }) => theme.colors.lightGray};
    padding: 4.5rem 6rem;
    border-radius: ${({ theme }) => theme.radius.small};
    border: 1px dashed ${({ theme }) => theme.colors.main};
`

export const Caption = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;

    font-size: 0.8125rem;
    color: ${({ theme }) => theme.colors.darkGray};

    > i {
        width: 45px;
        height: 38px;
        background: url(${camera}) center / contain no-repeat;
    }
`

export const PictureGallery = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`

export const SubHeader = styled.div`
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.1875rem;
    margin-top: -0.5rem;
`

export const Drag = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 1001;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    &::after {
        content: "Перетащите изображение с вашего компьютера";
        font-size: 2.5rem;
        color: ${({ theme }) => theme.colors.white};
    }
`
