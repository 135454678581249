import { useContext } from "react"
import { FaEye, FaEyeSlash } from "react-icons/fa"
import styled from "styled-components"

import { AuthFormContext } from "../../context/AuthFormContext"

const PasswordField = styled.div`
    width: 100%;
    display: inline-block;
    position: relative;
    overflow: hidden;

    svg {
        position: absolute;
        top: 50%;
        right: 12px;
        transition: right 0.2s;
        transform: translateY(-50%);
    }
`

interface PasswordProps {
    children: React.ReactNode
}

const Password: React.FC<PasswordProps> = ({ children }) => {
    const authContext = useContext(AuthFormContext)

    const handleEyeClick = () => {
        authContext?.setShowPassword(!authContext.showPassword)
    }

    return (
        <PasswordField>
            {children}
            {authContext?.showPassword ? (
                <FaEye onClick={handleEyeClick} size="20px" />
            ) : (
                <FaEyeSlash onClick={handleEyeClick} size="20px" />
            )}
        </PasswordField>
    )
}

export default Password
