import { Button } from "ui/Button/Button.styled"

import {
    Coin1,
    Coin2,
    Coin3,
    Cost,
    LeftContent,
    RightContent,
    StyledPayBanner,
} from "./PayBanner.styled"

const PayBanner = () => {
    return (
        <StyledPayBanner>
            <LeftContent>
                <div>Этот контент платный</div>
                <div>
                    Платите 2500 тг, что-бы у Вас был доступ ко всем актам и протоколам - на 14 дней
                </div>

                <Coin1 />
                <Coin2 />
                <Coin3 />
            </LeftContent>
            <RightContent>
                <Cost>2 500</Cost>
                <Button>Купить</Button>
            </RightContent>
        </StyledPayBanner>
    )
}

export default PayBanner
