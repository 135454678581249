import styled from "styled-components"

interface ContentProps {
    showAll: boolean
}

export const Content = styled.div<ContentProps>`
    max-height: ${({ showAll }) => (showAll ? "unset" : "5rem")};
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;

    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        z-index: 1;
        display: block;
        width: 100%;
        height: 2.5rem;
        background: ${({ showAll }) =>
            showAll ? "unset" : "linear-gradient(180deg, hsla(0, 0%, 100%, 0) 0, #fff 70%, #fff);"};
    }
`

export const Center = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const Table = styled.table`
    width: 100%;
    background-color: transparent;
    border-collapse: collapse;
    margin: 1rem 0;

    thead > tr > td,
    tbody > tr > td {
        border: 1px solid ${({ theme }) => theme.colors.grayHrE};
        padding: 0.5rem 1rem;
    }
`
