import { useState } from "react"

const useSelectedMessages = () => {
    const [selectedMessagesID, setSelectedMessagesID] = useState<number[]>([])

    const handleSelect = (checked: boolean, id: number) => {
        if (checked) setSelectedMessagesID((current) => [...current, id])
        else setSelectedMessagesID((current) => current.filter((item) => item !== id))
    }

    const handleSelectAll = (checked: boolean, ids: number[]) => {
        if (checked) setSelectedMessagesID(ids)
        else setSelectedMessagesID([])
    }

    return { selectedMessagesID, handleSelect, handleSelectAll }
}

export default useSelectedMessages
