import { useState } from "react"

import { Phone, ShowButton, StyledPhoneNumber } from "./PhoneNumber.styled"

interface PhoneNumberProps {
    phoneNumber?: string
}

const PhoneNumber: React.FC<PhoneNumberProps> = ({ phoneNumber }) => {
    const [showPhoneNumber, setShowPhoneNumber] = useState(false)

    return (
        <StyledPhoneNumber>
            {!showPhoneNumber && (
                <>
                    <Phone>{phoneNumber?.substring(0, 5)}</Phone>
                    <ShowButton onClick={() => setShowPhoneNumber(!showPhoneNumber)}>
                        Показать номер
                    </ShowButton>
                </>
            )}
            {showPhoneNumber && <Phone>{phoneNumber}</Phone>}
        </StyledPhoneNumber>
    )
}

export default PhoneNumber
