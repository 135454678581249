import styled from "styled-components"

import banner from "assets/banner/banner.jpg"

interface BannerProps {
    type?: string
}

interface ContentProps {
    url?: string
}

export const Banner = styled.div<BannerProps>`
    background: linear-gradient(0deg, rgba(255, 124, 0, 0.6), rgba(255, 124, 0, 0.6)),
        url(${banner}) center right / cover no-repeat;

    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 1rem;
    padding: 1.75rem;

    color: ${({ theme }) => theme.colors.white};
    font-family: "Montserrat";
    font-size: 1.2rem;

    font-weight: 700;

    margin-top: 3.5rem;
    width: 100%;

    @media (min-width: 1024px) {
        border-radius: ${({ theme }) => theme.radius.large};
        font-size: 1.8125rem;

        ${({ type }) =>
            type === "large"
                ? `border-radius: 0px;
                padding: 5.3125rem;`
                : ""}
    }
`

export const Button = styled.button`
    outline: none;
    border: none;
    border-radius: ${({ theme }) => theme.radius.large};
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.black};
    font-weight: 500;
    padding: 1rem 2rem;

    @media (min-width: 1024px) {
        font-size: 1.125rem;
        padding: 1rem 4.1875rem;
    }

    cursor: pointer;
`

export const BannerText = styled.div`
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.2rem;
`

export const MarketBanner = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.main};
    color: ${({ theme }) => theme.colors.white};
    border-radius: ${({ theme }) => theme.radius.large};
    margin-top: 2.5rem;
    max-width: 100%;

    img {
        width: 60%;
    }
`

export const Content = styled.div<ContentProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    ${({ url }) => (url ? "background: url(${url}) center center/contain no-repeat" : "")}
    width: 30%;
`
