import { useEffect } from "react"
import { useLocation } from "react-router-dom"

const useScrollToTop = (
    ref: React.MutableRefObject<HTMLDivElement | null>,
    behavior: ScrollBehavior = "auto",
    manualTrigger?: string | number | boolean
) => {
    const { pathname } = useLocation()

    useEffect(() => {
        ref.current &&
            ref.current.scrollIntoView({
                behavior,
                block: "start",
            })
    }, [pathname, manualTrigger])
}

export default useScrollToTop
