export const theme = {
    colors: {
        main: "#ff7d02",
        black: "#232323",
        brightBlack: "#3f3f3f",
        lightBlack: "#4d4d4d",
        lightBlackC: "#4C4C4C",
        lightBlackB: "#5B5B5B",
        white: "#fff",
        ellipse: "#bdbdbd",
        lightGray: "#F6F6F6",
        lightGrayInput: "#f7f7f7",
        lightGrayHr: "#F5F5F5",
        grayHr: "#DFDFDF",
        grayHrE: "#E4E4E4",
        grayC4: "#C4C4C4",
        grayE9: "#E9E9E9",
        gray: "#DDDDDD",
        darkGray: "#999999",
        darkGrayE: "#efefef",
        darkestGray: "#87888F",
        crimson: "#ff2e00",
        inactiveGray: "#CDCDCD",
        green: "#29A71A",
        purple: "#4C3DAB",
        yellow: "#FFDB58",
        blue: "#5DA2E1",
        blueButton: "#374BC6",
        darkBlue: "#1b273a",
        admin: {
            blueGray: "#DDDDE9",
            gray: "#EAEAEC",
            whiteSmoke: "#f5f5f5",
            status: {
                active: "#44BA26",
                inactive: "#FF4D4D",
                archive: "#7E7E7E",
                moderation: "#FFC700",
                deleted: "#C4C4C4",
            },
        },
    },
    radius: {
        small: "8px",
        large: "12px",
        huge: "16px",
    },
}
