import moment from "moment"
import { useContext } from "react"
import { useNavigate } from "react-router-dom"

import { UserAppealType } from "types/common"
import { Button } from "ui/Button/Button.styled"
import Checkbox from "ui/Checkbox/Checkbox"

import {
    MessageContent,
    MessageDate,
    MessageIcons,
    MessageRow,
    MessageText,
    PostTitle,
    StarIcon,
    StyledMobileMessage,
} from "./MobileMessage.styled"

import { AppealsListContext } from "../../AppealsList"

const MobileMessage = () => {
    const appealsCtx = useContext(AppealsListContext)
    const navigate = useNavigate()
    const { selectedMessagesID, handleArchive, appealType, handleDelete } = appealsCtx

    const handleMessageClick = (event: React.MouseEvent, appeal: UserAppealType) => {
        navigate("/cabinet/appeals/message", { state: { appeal, id: appeal.order.id } })
        event.stopPropagation()
    }

    const handleDeleteSelected = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (selectedMessagesID.length === 0) return

        if (appealType === "archive") handleDelete(appealsCtx.selectedMessagesID, event)
        else handleArchive(appealsCtx.selectedMessagesID, event)
    }

    return (
        <StyledMobileMessage>
            {selectedMessagesID?.length > 0 && (
                <Button
                    type="button"
                    style={{ width: "fit-content", padding: "0.75rem" }}
                    onClick={handleDeleteSelected}
                >
                    Удалить выбранные
                </Button>
            )}

            {appealsCtx.userAppealList.map((appeal: UserAppealType, index: number) => {
                const { order, post } = appeal
                const { selectedMessagesID, handleSelect, favouriteIDS, handleFavourite } =
                    appealsCtx

                const isSelected = selectedMessagesID.includes(order.id)
                const isFavourite = favouriteIDS.includes(order.id)

                return (
                    <MessageRow key={index}>
                        <Checkbox
                            checked={isSelected}
                            handleChange={() => handleSelect(!isSelected, order.id)}
                        />

                        <MessageContent onClick={(event) => handleMessageClick(event, appeal)}>
                            <div>{order.user_name}</div>
                            <PostTitle>{post.title}</PostTitle>
                            <MessageText>{order.comment}</MessageText>
                        </MessageContent>

                        <MessageIcons>
                            <StarIcon
                                liked={isFavourite}
                                onClick={(event) => handleFavourite(order.id, !isFavourite, event)}
                            />
                            <MessageDate>
                                <div>{moment(order.date).locale("ru").format("D MMMM")}</div>
                                <div>{moment(order.date).locale("ru").format("HH:mm")}</div>
                            </MessageDate>
                        </MessageIcons>
                    </MessageRow>
                )
            })}
        </StyledMobileMessage>
    )
}

export default MobileMessage
