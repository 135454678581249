import moment from "moment"
import "moment/locale/ru"
import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import { BrowserRouter, Route, Routes } from "react-router-dom"

import "@fontsource/montserrat"
import "@fontsource/montserrat/300.css"
import "@fontsource/montserrat/400.css"
import "@fontsource/montserrat/500.css"
import "@fontsource/montserrat/600.css"
import "@fontsource/montserrat/700.css"
import "@fontsource/roboto"
import "@fontsource/roboto/300.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/700.css"
import { ThemeProvider } from "styled-components"

import { AuthProvider } from "modules/Core/context/AuthContext"
import RequireAuth from "modules/Core/hoc/RequireAuth"
import AdminPanelPage from "pages/AdminPanelPage/AdminPanelPage"
import { theme } from "styles/Theme"

import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader

import App from "./App"

import { MainProvider } from "modules/Core/context/MainContext"
import PersistLogin from "modules/Core/hoc/PersistLogin"

moment.locale("ru")

import { Flip, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <AuthProvider>
                    <MainProvider>
                        <Routes>
                            <Route path="/*" element={<App />} />
                            <Route element={<PersistLogin />}>
                                <Route element={<RequireAuth allowedRoles={["admin"]} />}>
                                    <Route path="/admin/panel" element={<AdminPanelPage />} />
                                </Route>
                            </Route>
                        </Routes>
                    </MainProvider>
                </AuthProvider>
            </ThemeProvider>
            <ToastContainer transition={Flip} autoClose={2000} position="top-center" />
        </BrowserRouter>
    </React.StrictMode>
)
