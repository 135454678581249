import { useState } from "react"

import { Header } from "pages/AdminPanelPage/AdminPanelPage.styled"
import { SliderType } from "types/common"

import CreateSliderForm from "./components/CreateSliderForm"
import SliderList from "./components/SliderList"

const Slider = () => {
    const [currentState, setCurrentState] = useState("list")
    const [sliderEdit, setSliderEdit] = useState<SliderType>()

    return (
        <>
            <Header>Слайдеры</Header>

            {currentState === "list" ? (
                <SliderList setCurrentState={setCurrentState} setSliderEdit={setSliderEdit} />
            ) : (
                <CreateSliderForm setCurrentState={setCurrentState} sliderEdit={sliderEdit} />
            )}
        </>
    )
}

export default Slider
