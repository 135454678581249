import { useEffect, useRef, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"

import useAuth from "modules/Core/hooks/useAuth"
import useMainContext from "modules/Core/hooks/useMainContext"
import useScrollToTop from "modules/Core/hooks/useScrollToTop"
import { Container, Row } from "styles/Global.styled"
import { MENU_LIST } from "utils/helpers/list"

import SideBar from "./components/SideBar"
import {
    Exit,
    List,
    SideBarController,
    SmallNavbarRow,
    StyledSmallNavbar,
} from "./SmallNavbar.styled"

import Logo from "../Logo/Logo"

const SmallNavbar = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const { auth, setAuth } = useAuth()
    const { setFavouritePosts } = useMainContext()

    const [isSideBarOpen, setIsSideBarOpen] = useState(false)

    const handleSideBar = () => {
        setIsSideBarOpen((current) => !current)
    }

    const handleExit = () => {
        localStorage.clear()
        sessionStorage.clear()
        setAuth({})
        setFavouritePosts([])
        navigate("/auth")
    }

    useEffect(() => {
        if (isSideBarOpen) setIsSideBarOpen(false)
    }, [location])

    const ref = useRef() as React.MutableRefObject<HTMLDivElement | null>
    useScrollToTop(ref)

    return (
        <StyledSmallNavbar ref={ref}>
            <Container>
                <Row justify="space-between">
                    <List>
                        {MENU_LIST.filter((item) => !item.footer).map((item) => (
                            <Link key={item.link} to={item.link}>
                                {item.title}
                            </Link>
                        ))}
                    </List>
                    <SmallNavbarRow>
                        <SideBarController onClick={handleSideBar} show={isSideBarOpen} />
                        <Logo fontSize="19px" />
                    </SmallNavbarRow>
                    {auth?.accessToken && (
                        <Exit onClick={handleExit}>
                            <i />
                            Выход
                        </Exit>
                    )}
                </Row>
            </Container>

            <SideBar show={isSideBarOpen} setIsSideBarOpen={setIsSideBarOpen} />
        </StyledSmallNavbar>
    )
}

export default SmallNavbar
