import { Link } from "react-router-dom"
import styled from "styled-components"

interface LogoProps {
    className?: string
    fontSize?: string
    color?: string
    onMouseOver?: () => void
}

const StyledLogo = styled.div<LogoProps>`
    a {
        color: ${({ theme, color }) => theme.colors[color || "white"]};
        text-decoration: none;

        font-size: ${({ fontSize }) => fontSize || "24px"} !important;
        font-family: "Druk Wide Bold";
    }
`

const Logo: React.FC<LogoProps> = ({ fontSize, color, onMouseOver }) => {
    return (
        <StyledLogo fontSize={fontSize} color={color} onMouseOver={onMouseOver}>
            <Link to="/">JBF</Link>
        </StyledLogo>
    )
}

export default Logo
