import { useEffect, useState } from "react"

import { GET } from "modules/Core/api/REST"
import useAuthorizationHeader from "modules/Core/hooks/useAuthorizationHeader"
import { CategoryType } from "types/common"

const useCategoryList = () => {
    const [categoryList, setCategoryList] = useState<CategoryType[]>([])
    const headers = useAuthorizationHeader()

    useEffect(() => {
        const fGetList = async () => {
            const { data: response } = await GET("/api/admin/categories/", {}, { headers })
            if (response.ok === 1) {
                const { data } = response
                const categories = Object.keys(data.categories).map(
                    (category) => data.categories[category]
                )
                setCategoryList(categories)
            }
        }

        fGetList()
    }, [])

    return { categoryList, setCategoryList }
}

export default useCategoryList
