import { useEffect, useState } from "react"

import { GET } from "modules/Core/api/REST"
import useAuthorizationHeader from "modules/Core/hooks/useAuthorizationHeader"
import { SliderType } from "types/common"

const useSliderList = () => {
    const [sliderList, setSliderList] = useState<SliderType[]>([])
    const headers = useAuthorizationHeader()

    useEffect(() => {
        const fGetList = async () => {
            const { data: response } = await GET("/api/admin/sliders/", {}, { headers })

            if (response.ok === 1) {
                const { data } = response
                setSliderList(
                    data.sliders.sort((a: SliderType, b: SliderType) => a.order - b.order)
                )
            }
        }

        fGetList()
    }, [])

    return { sliderList, setSliderList }
}

export default useSliderList
