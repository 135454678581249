import { v4 as uuidv4 } from "uuid"

import { CreatePostForm } from "pages/CreatePostPage/CreatePostPage"
import PostImages from "pages/PostPage/modules/components/PostImages/PostImages"
import { Header, PostDescription } from "pages/PostPage/PostPage.styled"
import { Button } from "ui/Button/Button.styled"

import { PreviewButtons, PreviewSideNav, StyledPostPreview } from "./PostPreview.styled"

import PostPreviewUserProfile from "../PostPreviewUserProfile/PostPreviewUserProfile"

interface PostPreviewProps {
    formValues: CreatePostForm
    //eslint-disable-next-line
    formPostImages: any
    handlePreviewToggle: () => void
    handleSubmit: (values: CreatePostForm) => void
}

const PostPreview: React.FC<PostPreviewProps> = ({
    formValues,
    formPostImages,
    handlePreviewToggle,
    handleSubmit,
}) => {
    const { main, imageList } = formPostImages

    const images =
        imageList &&
        //eslint-disable-next-line
        imageList.map((image: any) =>
            image.id ? image : { id: uuidv4(), img: URL.createObjectURL(image) }
        )
    const mainImage = main ? (main.id ? main.img : URL.createObjectURL(main)) : null

    return (
        <>
            <Header>{formValues.title}</Header>
            <StyledPostPreview>
                <section>
                    <PostImages mainImage={mainImage} images={images} />

                    <Header>Об объявлении</Header>
                    <PostDescription>{formValues.description}</PostDescription>
                </section>
                <PreviewSideNav>
                    <PostPreviewUserProfile formValues={formValues} />

                    <PreviewButtons>
                        <Button btnColor="lightBlack" onClick={handlePreviewToggle}>
                            Редактировать
                        </Button>
                        <Button onClick={() => handleSubmit(formValues)}>Опубликовать</Button>
                    </PreviewButtons>
                </PreviewSideNav>
            </StyledPostPreview>
        </>
    )
}

export default PostPreview
