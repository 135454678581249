import { Link } from "react-router-dom"
import styled from "styled-components"

interface SideBarMenuList {
    gap?: string
}
interface SideBarIconProps {
    icon: string
}

interface StyledSideBar {
    show: boolean
}

export const StyledSideBar = styled.section<StyledSideBar>`
    display: flex;
    flex-direction: column;

    height: 100%;
    position: fixed;
    z-index: 2000;

    top: 32px;
    left: 0;

    padding: 0 1rem;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.black};

    overflow-x: hidden;
    transition: all 0.5s linear;
    white-space: nowrap;

    ${({ show }) =>
        show
            ? "width:100%;visibility:visible; opacity:1"
            : "visibility:hidden; opacity:0;width:0px"};
`

export const SideBarMenuList = styled.div<SideBarMenuList>`
    display: flex;
    flex-direction: column;
    gap: ${({ gap }) => gap || "2rem"};

    padding: 1.5rem 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.grayC4};

    &:nth-child(2) {
        margin-bottom: 1rem;
    }
`

export const SideBarMenuItem = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`

export const SideBarMenuIcon = styled.div<SideBarIconProps>`
    height: 1.5rem;
    width: 1.5rem;
    background: url("${({ icon }) => icon}") center / contain no-repeat;
    /* background-color: ${({ theme }) => theme.colors.main}; */
`

export const SideBarLink = styled(Link)`
    font-size: 1rem;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.white};
`

export const SideBarMenuLink = styled(SideBarLink)`
    font-weight: 600;

    &:hover {
        color: ${({ theme }) => theme.colors.main};
    }
`
