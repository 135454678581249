import Socials from "modules/components/Socials/Socials"
import useMainContext from "modules/Core/hooks/useMainContext"
import { CategoryType } from "types/common"
import { MENU_LIST } from "utils/helpers/list"
import { DOWNLOAD_JBF_ID } from "utils/helpers/variables"

import {
    SideBarMenuIcon,
    SideBarMenuLink,
    SideBarMenuItem,
    SideBarMenuList,
    StyledSideBar,
    SideBarLink,
} from "./SideBar.styled"

interface SideBarProps {
    show: boolean
    setIsSideBarOpen: (value: boolean) => void
}

const SideBar: React.FC<SideBarProps> = ({ show }) => {
    const { categoryList } = useMainContext()

    return (
        <StyledSideBar show={show}>
            <div>
                <SideBarMenuList>
                    {categoryList &&
                        categoryList.map((category: CategoryType) => (
                            <SideBarMenuItem key={category.id}>
                                <SideBarMenuIcon icon={category.icon} />
                                <SideBarMenuLink
                                    to={
                                        category.id === DOWNLOAD_JBF_ID
                                            ? "documents"
                                            : `catalogue/${category.id}`
                                    }
                                >
                                    {category.name}
                                </SideBarMenuLink>
                            </SideBarMenuItem>
                        ))}
                </SideBarMenuList>

                <SideBarMenuList gap="1.3rem">
                    {MENU_LIST.filter((item) => !item.footer).map((item) => (
                        <SideBarLink key={item.link} to={item.link}>
                            {item.title}
                        </SideBarLink>
                    ))}
                </SideBarMenuList>

                <Socials />
            </div>
        </StyledSideBar>
    )
}

export default SideBar
