import { toast } from "react-toastify"

import { DELETE } from "modules/Core/api/REST"
import useAuthorizationHeader from "modules/Core/hooks/useAuthorizationHeader"
import { ActionButtons } from "pages/AdminPanelPage/modules/helpers/components/ActionButtons/ActionButtons.styled"
import EditButton from "pages/AdminPanelPage/modules/helpers/components/ui/EditButton/EditButton"
import TrashButton from "pages/AdminPanelPage/modules/helpers/components/ui/TrashButton/TrashButton"
import { Row } from "styles/Global.styled"
import { CategoryType } from "types/common"
import { Button } from "ui/Button/Button.styled"
import { Input } from "ui/Input/Input.styled"
import { Table } from "ui/Table/Table.styled"

import useCategoryList from "../hooks/useCategoryList"

interface CategoryListProps {
    setCurrentState: React.Dispatch<React.SetStateAction<string>>
    setCategoryEdit: React.Dispatch<React.SetStateAction<CategoryType | undefined>>
}

const CategoryList: React.FC<CategoryListProps> = ({ setCurrentState, setCategoryEdit }) => {
    const headers = useAuthorizationHeader()
    const { categoryList, setCategoryList } = useCategoryList()

    const handleDelete = async (id: number) => {
        if (window.confirm("Вы действительно хотите удалить данную категорию?")) {
            try {
                const { data } = await DELETE("/api/admin/categories/" + id, {}, { headers })

                if (data.ok === 1) {
                    toast.success("Категория успешно удалена!")
                    setCategoryList((current) => current.filter((category) => category.id !== id))
                } else {
                    throw new Error(data.detail)
                }
            } catch (error) {
                toast.error("Произошла ошибка " + error)
            }
        }
    }

    const handleEdit = (category: CategoryType) => {
        setCurrentState("update")
        setCategoryEdit(category)
    }

    const handleCreate = () => {
        setCurrentState("create")
        setCategoryEdit(undefined)
    }

    return (
        <>
            <Row justify="space-between">
                <div style={{ width: "300px" }}>
                    <Input placeholder="Начни вводить текст" />
                </div>
                <div>
                    <Button onClick={handleCreate} btnColor="black">
                        Добавить категорию
                    </Button>
                </div>
            </Row>
            <Table>
                <thead>
                    <tr>
                        <td>#</td>
                        <td>Название</td>
                        <td>Действия</td>
                    </tr>
                </thead>
                <tbody>
                    {categoryList?.length > 0 &&
                        categoryList.map((category: CategoryType, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{category.name}</td>
                                <td>
                                    <ActionButtons>
                                        <EditButton onClick={() => handleEdit(category)} />
                                        <TrashButton onClick={() => handleDelete(category.id)} />
                                    </ActionButtons>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </Table>
        </>
    )
}

export default CategoryList
