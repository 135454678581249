import { useState } from "react"

import { ToggleButton, ToggleButtons } from "styles/Global.styled"

import { StyledAppeals } from "./Appeals.styled"
import AppealsList from "./modules/components/AppealsList/AppealsList"

const Appeals = () => {
    const [appealType, setAppealType] = useState("last")

    const handleToggle = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const typeName = event.currentTarget.name
        setAppealType(typeName)
    }

    return (
        <StyledAppeals>
            <ToggleButtons>
                <ToggleButton
                    name="last"
                    onClick={handleToggle}
                    className={`${appealType === "last" && "active"}`}
                >
                    Входящие
                </ToggleButton>
                <ToggleButton
                    name="send"
                    onClick={handleToggle}
                    className={`${appealType === "send" && "active"}`}
                >
                    Отправленные
                </ToggleButton>
                <ToggleButton
                    name="archive"
                    onClick={handleToggle}
                    className={`${appealType === "archive" && "active"}`}
                >
                    Архивные
                </ToggleButton>
            </ToggleButtons>

            <AppealsList appealType={appealType} />
        </StyledAppeals>
    )
}

export default Appeals
