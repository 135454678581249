import { Item, StyledToggle } from "./Toggle.styled"

const ITEM_LIST = [
    { label: "Рекламировать", name: "promote" },
    { label: "Отзывы", name: "review" },
    { label: "Пожаловаться", name: "complaint" },
]

interface ToggleProps {
    activeToggle: string
    setActiveToggle: (name: string) => void
    handleShowModal: () => void
}

const Toggle: React.FC<ToggleProps> = ({ activeToggle, setActiveToggle, handleShowModal }) => {
    const handleActive = (name: string) => {
        setActiveToggle(name)
        if (name === "complaint") handleShowModal()
    }

    return (
        <StyledToggle>
            {ITEM_LIST.map((item) => (
                <Item
                    key={item.name}
                    onClick={() => handleActive(item.name)}
                    className={`${activeToggle === item.name && "active"}`}
                >
                    {item.label}
                </Item>
            ))}
        </StyledToggle>
    )
}

export default Toggle
