import styled from "styled-components"

import error from "assets/icons/error.svg"
import { fadeIn } from "styles/Animation.styled"

const StyledNoDataFound = styled.div`
    display: flex;
    align-items: center;
    margin-top: 2rem;
    gap: 1.1875rem;
    font-size: 1.5rem;
    font-family: "Montserrat";
    font-weight: 600;
    width: 100%;

    animation: ${fadeIn} 1s ease 0s 1 normal forwards;

    > i {
        width: 1.875rem;
        height: 1.875rem;
        background: url(${error}) center center/contain no-repeat;
    }
`

const Container = styled.div`
    display: flex;
    flex-direction: column;

    font-family: "Montserrat";
    font-size: 0.75rem;
    line-height: 0.875rem;
`

const Header = styled.div`
    font-weight: 700;
`

const Caption = styled.div`
    font-weight: 400;
`

const NoDataFound = () => {
    return (
        <StyledNoDataFound>
            <i />
            <Container>
                <Header>У Вас пока нет обращений :(</Header>
                <Caption>
                    Вы можете опубликовать новое объявление
                    <br /> и увеличить количество обращений
                </Caption>
            </Container>
        </StyledNoDataFound>
    )
}

export default NoDataFound
