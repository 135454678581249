import { Formik, Form, Field, ErrorMessage } from "formik"
import { useState, useRef } from "react"
import * as Yup from "yup"

import Success from "modules/components/Success/Success"
import { POST_FORM } from "modules/Core/api/REST"
import useAuth from "modules/Core/hooks/useAuth"
import useAuthorizationHeader from "modules/Core/hooks/useAuthorizationHeader"
import { GHeader } from "styles/Global.styled"
import { Button } from "ui/Button/Button.styled"
import { Input } from "ui/Input/Input.styled"
import { renderError } from "utils/helpers"
import { InputComponent, TextareaComponent } from "utils/helpers/components/FormikField"

import { FormItem, ProfileContent, StyledProfile, Note, ProfileAvatar } from "./Profile.styled"

interface ProfileFields {
    firstName: string
    phone: string
    email?: string
    additionalInfo?: string
}

const validationSchema = Yup.object({
    firstName: Yup.string().required("Имя обязательное поле"),
    phone: Yup.string()
        .matches(/^((\+7|8)\d{10})$/g, "Введите корректный номер телефона")
        .required("Номер телефона обязательное поле"),
})

const Profile = () => {
    const inputFileRef = useRef() as React.MutableRefObject<HTMLInputElement>

    const { auth, setAuth } = useAuth()
    const headers = useAuthorizationHeader()

    const [isLoading, setIsLoading] = useState(false)
    const [updateSuccess, setUpdateSuccess] = useState(false)
    const [selectedFile, setSelectedFile] = useState<File | undefined>(
        auth?.user?.image || undefined
    )
    const [avatarPreview, setAvatarPreview] = useState(auth?.user?.image || "")

    const initialValues: ProfileFields = {
        firstName: auth.user?.first_name || "",
        phone: auth.user?.phone || "",
        email: auth.user?.email,
        additionalInfo: auth.user?.more_info === "null" ? "" : auth.user?.more_info || "",
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target?.files

        if ((!files || files.length === 0) && !selectedFile) {
            setSelectedFile(undefined)
        } else if (files) {
            const file = files[0]
            const objectUrl = URL.createObjectURL(file)
            setAvatarPreview(objectUrl)
            setSelectedFile(file)
        }
    }

    const handleAvatarClick = () => {
        inputFileRef.current.click()
    }

    const handleSubmit = (values: ProfileFields) => {
        setIsLoading(true)
        setUpdateSuccess(false)
        fSendRequest(values)
    }

    const fSendRequest = async (values: ProfileFields) => {
        const formData = new FormData()

        formData.append("first_name", values.firstName)
        formData.append("phone", values.phone)
        formData.append("more_info", values.additionalInfo || "")

        if (selectedFile && typeof selectedFile !== "string") formData.append("image", selectedFile)

        try {
            const response = await POST_FORM("/api/accounts/user/update/", formData, headers)

            if (response?.data?.data) {
                const { user } = response.data.data
                setAuth({ ...auth, user })
                localStorage.setItem("auth_user_credentials", JSON.stringify({ ...auth, user }))
                setUpdateSuccess(true)
                setIsLoading(false)
            } else {
                throw new Error()
            }
        } catch (err) {
            setIsLoading(false)
            console.log(err)
        }
    }

    return (
        <StyledProfile>
            <GHeader>Мой профиль</GHeader>
            <ProfileContent>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    enableReinitialize
                    onSubmit={handleSubmit}
                    validateOnBlur={false}
                >
                    <Form>
                        {updateSuccess && <Success caption="Профиль успешно обновлен" />}
                        <FormItem>
                            <label htmlFor="">ФИО</label>
                            <Field name="firstName" component={InputComponent} />
                            <ErrorMessage name="firstName" render={renderError} />
                        </FormItem>

                        <FormItem>
                            <label htmlFor="">Email</label>
                            <Field name="email" component={InputComponent} readOnly disabled />
                            <Note>Вы не можете изменить адрес, указанный при регистрации</Note>
                        </FormItem>

                        <FormItem>
                            <label htmlFor="">Телефон</label>
                            <Field name="phone" component={InputComponent} />
                            <ErrorMessage name="phone" render={renderError} />
                        </FormItem>

                        <FormItem>
                            <label htmlFor="">Дополнительная информация</label>
                            <Field name="additionalInfo" component={TextareaComponent} />
                        </FormItem>

                        <Button type="submit" disabled={isLoading}>
                            Сохранить изменения
                        </Button>
                    </Form>
                </Formik>

                <ProfileAvatar
                    onClick={handleAvatarClick}
                    style={{ backgroundImage: `url('${avatarPreview}')` }}
                >
                    {!selectedFile && (
                        <>
                            <i />
                            Загрузить фото
                        </>
                    )}
                </ProfileAvatar>

                <Input
                    type="file"
                    ref={inputFileRef}
                    onChange={handleFileChange}
                    accept="image/*"
                    hidden
                />
            </ProfileContent>
        </StyledProfile>
    )
}

export default Profile
