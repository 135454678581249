import { Link } from "react-router-dom"

import useMainContext from "modules/Core/hooks/useMainContext"
import { Container } from "styles/Global.styled"
import { CategoryType } from "types/common"
import { MENU_LIST } from "utils/helpers/list"

import { FooterItem, FooterText, List, MidFooterItem, StyledFooter } from "./Footer.styled"

import Logo from "../Logo/Logo"
import Socials from "../Socials/Socials"

const Footer = () => {
    const { categoryList } = useMainContext()

    return (
        <StyledFooter>
            <Container>
                <FooterItem>
                    <Logo fontSize="29px" />
                </FooterItem>
                <MidFooterItem>
                    <FooterItem>
                        <List>
                            {MENU_LIST.map((item) => (
                                <li key={item.link}>
                                    <Link to={item.link}>{item.title}</Link>
                                </li>
                            ))}
                        </List>
                    </FooterItem>
                    <FooterItem>
                        <List>
                            {categoryList &&
                                categoryList.map((item: CategoryType) => (
                                    <li key={item.id}>
                                        <Link to={`catalogue/${item.id}`}>{item.name}</Link>
                                    </li>
                                ))}
                        </List>
                    </FooterItem>
                </MidFooterItem>
                <FooterItem>
                    <FooterText>
                        г.Алматы, Наурызбайский район, мкр. Шугыла, ул. С. Жунисова 8/3
                    </FooterText>
                    <FooterText>jbforcekz@gmail.com</FooterText>

                    <Socials />
                </FooterItem>
            </Container>
        </StyledFooter>
    )
}

export default Footer
