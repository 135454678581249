import { useState } from "react"

import { Container } from "styles/Global.styled"

import { Additional, MainHeader } from "./HomeAbout.styled"

import {
    AboutBlocks,
    ButtonWrapper,
    Green,
    StyledAbout,
    ToggleButton,
} from "../AboutComponent.styled"

interface HomeAboutProps {
    withOutButton?: boolean
}

const HomeAbout: React.FC<HomeAboutProps> = ({ withOutButton = false }) => {
    const [showAll, setShowAll] = useState(withOutButton || false)

    const handleToggle = () => {
        setShowAll((current) => !current)
    }

    const buttonText = showAll ? "Скрыть" : "Подробнее"

    return (
        <StyledAbout>
            <Container>
                <AboutBlocks isPage={withOutButton}>
                    <MainHeader>
                        <h1>Сайт-сервис объявлений по строительству по всему Казахстану</h1>
                        <p>
                            Строительный сайт JBF.KZ – спутник каждого строителя.{" "}
                            <b>Подать объявление по строительству</b> могут все желающие. Здесь
                            можно найти или предложить работу по строительной специальности или
                            смежным профессиям, продать или купить строительные материалы любого
                            типа и в любом количестве, сдать в аренду или доставить продукцию и
                            спецтехнику по всему Казахстану. Словом, здесь есть всё, что так или
                            иначе имеет отношение к слову &quot;стройка&quot;!
                        </p>
                        <p>
                            На интернет-площадке размещают свои объявления все, кто ищет строителей,
                            бригады или наоборот те, кто в поиске строительных работы и объемов, а
                            также продает стройматериалы, сдает в аренду спецтехнику и оборудование,{" "}
                            <b>предлагает прокат инструментов, электроинструментов</b>. Кроме этого,
                            на портале можно найти исполнителей, которыми осуществляется{" "}
                            <b>поставка материала по РК</b>, перевозка техники и оборудования.
                        </p>
                        <Green>
                            <p>
                                Сервис разработан и нацелен в первую очередь для улучшения
                                взаимодействия всех сторон в строительстве!
                            </p>
                        </Green>
                        <section>
                            Здесь указаны <h3>контакты работников</h3> и работодателей, информация о
                            товарах, а также прочие важные сведения для всех, кто строит или делает
                            ремонт.
                        </section>
                    </MainHeader>

                    <Additional showAll={showAll}>
                        <section>
                            <h2>КАК ЗАРЕГИСТРИРОВАТЬСЯ НА САЙТЕ JBF.KZ</h2>

                            <p>
                                Если вы пришли на этот ресурс впервые и хотите подать объявление, то
                                вам необходимо пройти процедуру регистрации. Для этого надо зайти на
                                главную страницу и в правом верхнем углу нажать кнопку «Подать
                                объявление». Откроется окно, в котором будут доступны два действия:
                                «Авторизация» и «Регистрация». Поскольку вы еще не авторизированный
                                пользователь, жмем на вторую кнопку. Для регистрации всего лишь
                                нужно заполнить появившиеся поля:
                            </p>
                            <ul>
                                <li>E-mail</li>
                                <li>Телефон</li>
                                <li>Пароль</li>
                                <li>Повторите пароль</li>
                            </ul>
                            <p>
                                После этого следует поставить галочку в пункте «Согласен с условиями
                                публичной оферты» (если вы согласны, конечно же) и ниже нажать
                                кнопку «Зарегистрироваться». Поздравляем – теперь вы стали
                                авторизированным пользователем портала!
                            </p>

                            <h2>КАК СОЗДАТЬ ОБЪЯВЛЕНИЕ</h2>

                            <p>
                                После прохождения быстрой процедуры регистрации вы можете подавать
                                объявления. Для этого также нужно заполнить все поля:
                            </p>
                            <ul>
                                <li>
                                    <b>Название</b>
                                    <p>
                                        Здесь указывается заголовок вашего объявления. Например,
                                        «Проектная организация выполняет все работы по
                                        проектированию», «Изготовление и монтаж металлических
                                        изделий», «Бригаде по бетонным работам нужен объем»,
                                        «Строительная компания предлагает свои услуги»,
                                        «Изготовление ворот, навесов, ограждений» и т.д.
                                    </p>
                                </li>
                                <li>
                                    <b>Категория</b>
                                    <p>
                                        В этом пункте предстоит выбрать подходящую категорию. Всего
                                        доступно 6 позиций:
                                    </p>
                                    <ol>
                                        <li>Ищу строителей</li>
                                        <li>Бригады и услуги</li>
                                        <li>Резюме ИТР (инженерно-технические работники)</li>
                                        <li>Поставщики</li>
                                        <li>Арендодатели</li>
                                        <li>Логистика</li>
                                    </ol>
                                </li>
                                <li>
                                    <b>Описание</b>
                                    <p>
                                        Теперь необходимо подробнее рассказать, какие именно работы
                                        вы выполняете, что за товары предлагаете и какие услуги и
                                        материалы вам требуются. К примеру, «Опытные монтажники по
                                        установке и монтажу дверей ищут работу в любых городах
                                        Казахстана», «Доставка материалов для линий связи по всему
                                        Казахстану быстро и выгодно», «Самые выгодные цены на
                                        расходные материалы в Астана, Актобе, Алматы, Шымкент,
                                        Караганда и других городах», «Доставка грузов, материалов по
                                        всем регионам, межгород, недорого» и др. Отметим, что ваше
                                        описание должно вместиться в 3000 символов с пробелами.
                                    </p>
                                </li>
                                <li>
                                    <b>Цена</b>
                                    <p>
                                        Здесь указывается стоимость работ, товаров или услуг. Если
                                        вы не хотите называть точную сумму, то можно указать, что
                                        цена «договорная». Для этого необходимо передвинуть вправо
                                        соответствующий ползунок, расположенный ниже поля «Цена».
                                    </p>
                                </li>
                                <li>
                                    <b>Частный или бизнес</b>
                                    <p>Нужно выбрать пункт «Частное лицо» или «Компания»</p>
                                </li>
                                <li>
                                    <b>Местоположение</b>
                                    <p>
                                        Выбираем нужный город из списка или вводим название своего
                                        населенного пункта
                                    </p>
                                </li>
                                <li>
                                    <b>Контактное лицо</b>
                                    <p>
                                        Указываете свое имя или псевдоним, по которому к вам будут
                                        обращаться
                                    </p>
                                </li>
                                <li>
                                    <b>Номер телефона</b>
                                    <p>
                                        Следует ввести свой контактный номер в международном
                                        формате.
                                    </p>
                                </li>
                            </ul>

                            <p>
                                Далее можно загрузить фотографии, чтобы продемонстрировать свой
                                товар или услугу. *Согласно статистике, объявления с фото
                                просматриваются чаще. Так что даже если вам нечего загрузить, то
                                скачайте какую-нибудь картинку в интернете по вашей теме. Например,
                                вы оказываете строительные <b>услуги в Караганде</b>. Можно скинуть
                                снимки готовых объектов, выполненных заказов, а если этого нет, то
                                загрузить фото с изображением города, набором материалов и
                                инструментов для строительства и все в таком же духе.
                            </p>
                            <p>
                                Когда вы заполнили все поля и загрузили (или не загрузили)
                                фотографии, то можно воспользоваться функцией предварительного
                                просмотра. Для этого нажмите соответствующую кнопку внизу страницы.
                                Такой просмотр позволит взглянуть и оценить, как будет выглядеть
                                ваше объявление на сайте. Если вас все устраивает и вы не планируете
                                вносить какие-либо правки, то смело нажимайте на кнопку «Подать
                                объявление».
                            </p>
                            <Green>
                                <p>
                                    *Дело сделано! Теперь ваше объявление доступно для всех
                                    пользователей сайта. Осталось только дождаться тех, кто
                                    откликнется на публикацию.
                                </p>
                            </Green>

                            <p>
                                Какие еще преимущества предлагает наш недорогой интернет-магазин в
                                Петропавловске, Усть-Каменогорске или Экибастузе? Наличие
                                предлагаемого товара на складе. Это очень важно, т.к. в большинстве
                                случаев совершить покупку нужно очень быстро. Необходимость найти
                                внушающий доверие интернет-магазин, доставка из которого не заставит
                                себя долго ждать, бывает разная. Чаще всего – это внезапный выход из
                                строя старой бытовой техники или электроники. В такой ситуации
                                размеренно выбирать интернет-магазины с бесплатной доставкой просто
                                нет времени. Замена мобильного телефона или ноутбука, к примеру,
                                требует максимальной оперативности. Наш интернет-магазин телефонов
                                готов предложить вам не только быстрое оформление заказа и удобную
                                доставку, но и огромный выбор качественных современных средств связи
                                и коммуникации от самых авторитетных брендов. В их числе Apple,
                                Samsung, Sony, Huawei, Oppo, Vivo, Honor, OnePlus и другие.
                            </p>
                        </section>
                        <section>
                            <h2>ПРАВИЛА РАЗМЕЩЕНИЯ ОБЪЯВЛЕНИЙ НА ПОРТАЛЕ JBF.KZ</h2>
                            <p>
                                Перед подачей своего объявления необходимо убедиться в том, что ваши
                                услуги или продукция не противоречат нормативно-правовым актам
                                Казахстана. Оставляя публикацию, пользователь подтверждает, что в
                                полной мере осознает свои права и обязанности. Согласно действующему
                                законодательству РК, на интернет-портале запрещается публиковать
                                объявления в следующих категориях (назовем все категории и некоторые
                                входящие в них позиции):
                            </p>
                            <ul>
                                <li>
                                    Медицина и здоровье. Реализация всех лекарственных средств и
                                    ветеринарных препаратов, медицинские услуги и оборудование,
                                    человеческие органы и ткани, официальные бланки, приборы с
                                    радиоактивными веществами.
                                </li>
                                <li>
                                    Алкогольная продукция. Продукты на основе метилового и этилового
                                    спирта, психотропные вещества, продукты с содержанием никотина.
                                </li>
                                <li>
                                    Взрывчатые вещества и средства. Все виды оружия, пиротехника,
                                    услуги по обучению владением любыми видами оружия.
                                </li>
                                <li>
                                    Животные и растения. Представители Красной книги Казахстана,
                                    чучела, мех, электроудочки, капканы.
                                </li>
                                <li>
                                    Финансы и материальные ценности. Настоящие и поддельные
                                    дензнаки, пиратские продукты, криптовалюты, драгметаллы,
                                    памятные медали.
                                </li>
                                <li>
                                    Нематериальные товары. Товары с нарушением авторских прав, базы
                                    данных пользователей, религиозные обряды, нетрадиционная
                                    медицина.
                                </li>
                                <li>
                                    Прочие товары. Интимные услуги, шифровальные средства,
                                    удостоверения личности, проведение лотерей т.д.
                                </li>
                            </ul>
                            <p>
                                Понятно, что портал JBF.KZ посвящен строительству и предназначен для
                                строителей и заказчиков работ. Однако правила размещения объявлений
                                должны обязательно быть на таких площадках, поскольку некоторые
                                особо хитрые граждане пользуются популярными сайтами именно для
                                продвижения своей незаконной продукции или деятельности.
                            </p>

                            <h2>ЧТО УКАЗАТЬ В РЕЗЮМЕ ДЛЯ БЫСТРОГО ПОИСКА РАБОТЫ</h2>

                            <p>
                                Резюме рабочих по всему Казахстану – это отличная возможность для
                                работодателя найти подходящего специалиста, а для соискателя
                                устроиться на работу. На строительном портале можно оставить
                                информацию о себе, чтобы сделать поиск работы еще более быстрым.
                                Найти резюме ИТР с контактами смогут все заинтересованные в этом
                                работодатели. Дадим несколько советов, как грамотно составить такой
                                документ.
                            </p>
                            <p>
                                Целью любого резюме является развернутое отображение
                                профессионального опыта соискателя и привлечение внимания
                                работодателя, который ежедневно просматривает анкеты других
                                кандидатов. Что нужно сделать, чтобы обратили внимание именно на
                                вашу кандидатуру и отправили приглашение на собеседование?
                            </p>
                            <p>
                                Во-первых, необходимо указать образование и больше информации об
                                опыте работы. Сразу отметим, что писать о себе следует без
                                орфографических ошибок – для некоторых соискателей это достаточно
                                важный пункт. Обходитесь без общих фраз наподобие «Проектирование по
                                всему Казахстану» или «Опытные монтажники по замене радиатора
                                печки». Намного эффективнее назвать свои профессиональные умения с
                                использованием соответствующих терминов. Стоит упомянуть о
                                достижениях на прошлых работах. Например, «Отвечал за организацию и
                                развитие логистического процесса», «Выполнял проектирование,
                                благодаря чему удалось сэкономить 20% бюджета проекта» и т.д.
                            </p>
                            <p>
                                Обозначить уровень образования и квалификацию недостаточно, нужно
                                отметить, какими инструментами и навыками вы владеете как
                                профессиональный специалист. Например, можно просто написать
                                «качественный монтаж системы отопления и вентиляции». Вроде бы все
                                понятно. Но будет гораздо лучше, если вы укажете, какое оборудование
                                используете при работе, какие виды монтажа можете осуществлять,
                                почему ваши системы отопления работают эффективно, как ваш опыт по
                                установке приборов продлит их срок эксплуатации и все в таком же
                                духе. То есть нужно указать, что вы умеете делать и как вы это
                                делаете для достижения максимально высокого результата.
                            </p>
                            <p>
                                Отметим, что резюме должно быть достаточно лаконичным, без лишней
                                «воды», но в тоже время содержательным и достоверным. Ни в коем
                                случае нельзя преподносить лживые сведения, указывать недостоверный
                                опыт работы, завышать свои возможности. Например, в объявлении можно
                                указать «Бригады с большим опытом по всему Казахстану», а на деле
                                окажется, что в бригаде работают молодые люди до 20 лет, причем
                                некоторые даже без профильного образования. О каком опыте работы
                                может идти речь?
                            </p>
                            <p>
                                Если вы хотите найти работу на строительном сайте JBF.KZ, то
                                указывайте только достоверные данные. Желательно также представить
                                портфолио с примерами работ, отзывами предыдущих заказчиков. Это
                                заметно увеличит ваш шанс быстро найти подходящую вакансию.
                            </p>

                            <h3>КАКИХ СПЕЦИАЛИСТОВ МОЖНО НАЙТИ НА ПОРТАЛЕ</h3>

                            <p>На сайте JBF.KZ можно найти специалистов в самых разных сферах:</p>

                            <ul>
                                <li>Сварщики и монолитчики на объект строительства;</li>
                                <li>Специалисты по отделочным, ремонтных и монтажным работам;</li>
                                <li>Бригады по СМР (строительно-монтажные работы);</li>
                                <li>
                                    Специалисты по монтажу водоснабжения и канализации, систем
                                    отопления и вентиляции;
                                </li>
                                <li>Мастера по установке дверей, монтажу окон и витражей;</li>
                                <li>
                                    Проектировщики зданий и сооружений, а также инженерных систем.
                                </li>
                            </ul>

                            <section>
                                Также на сайте можно <h3>найти контакты поставщиков различных</h3>{" "}
                                строительных товаров и оборудования:
                            </section>
                            <ul>
                                <li>Материалы по электрике и для кабельных линий в городе;</li>
                                <li>Дорожные материалы, металлопрокат, опалубка;</li>
                                <li>Облицовка, тротуарная плитка, брусчатка;</li>
                                <li>Газоблоки, кирпичи, силикатные кирпичи и сплитерные блоки;</li>
                                <li>Строительные расходные материалы и смеси;</li>
                                <li>Краска дорожная для разметки дорог;</li>
                                <li>Материалы для электрических сетей;</li>
                                <li>Дорожная техника по всему Казахстану;</li>
                                <li>
                                    Керамическая плитка, бетон, металлочерепица и многое другое.
                                </li>
                            </ul>

                            <p>Кроме этого, на портале вы можете получить следующие услуги:</p>

                            <ul>
                                <li>
                                    Арендовать грузоподъемную технику в Актобе, Алматы, Шымкент,
                                    Астана, Караганда и других городах;
                                </li>
                                <li>Заказать замену тепловых сетей, доставку по городу;</li>
                                <li>
                                    Взять в аренду грейдер, асфальтоукладчик, грузовой автомобиль,
                                    манипулятор, автокран и прочую спецтехнику;
                                </li>
                                <li>
                                    Арендовать дорожно-строительную технику, взять в прокат
                                    электроинструмент и другое оборудование.
                                </li>
                            </ul>

                            <p>
                                Это далеко не весь перечень строительных товаров и услуг, которые
                                доступны на сайте. Заходите на JBF.KZ, оставляйте свои объявления,
                                чтобы продать или купить товар, найти роботу или специалистов,
                                которые ее выполнят.
                            </p>

                            <p>
                                А если у вас есть какие-то пожелания и рекомендации относительно
                                того, как мы совместными усилиями сможем сделать сервис еще лучше,
                                удобнее и понятнее для каждого пользователя - наша редакция всегда
                                открыта для обратной связи и доброжелательного общения. Даже если в
                                ваших сообщениях будет содержательная критика, мы будем очень рады
                                получить ее от вас.
                            </p>
                        </section>

                        <section>
                            <p>
                                <b>Приятной вам работы на сервисе!</b>
                            </p>
                        </section>
                    </Additional>
                    {!withOutButton && (
                        <ButtonWrapper>
                            <ToggleButton onClick={handleToggle}>{buttonText}</ToggleButton>
                        </ButtonWrapper>
                    )}
                </AboutBlocks>
            </Container>
        </StyledAbout>
    )
}

export default HomeAbout
