import { keyframes } from "styled-components"

export const scaleForward = keyframes`
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
`

export const fadeIn = keyframes`
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
`

export const pulse = keyframes`
    0% {
		transform: scale(1);
	}

	50% {
		transform: scale(1.1);
	}

	100% {
		transform: scale(1);
	}
`

export const slideRight = keyframes`
	0% {
		opacity: 0;
		transform: translateX(250px);
	}

	100% {
		opacity: 1;
		transform: translateX(0);
	}
`

export const bounceRight = keyframes`
	0% {
		animation-timing-function: ease-in;
		opacity: 0;
		transform: translateX(250px);
	}

	38% {
		animation-timing-function: ease-out;
		opacity: 1;
		transform: translateX(0);
	}

	55% {
		animation-timing-function: ease-in;
		transform: translateX(68px);
	}

	72% {
		animation-timing-function: ease-out;
		transform: translateX(0);
	}

	81% {
		animation-timing-function: ease-in;
		transform: translateX(32px);
	}

	90% {
		animation-timing-function: ease-out;
		transform: translateX(0);
	}

	95% {
		animation-timing-function: ease-in;
		transform: translateX(8px);
	}

	100% {
		animation-timing-function: ease-out;
		transform: translateX(0);
	}

`
