import styled from "styled-components"

import image from "assets/authPage/userAuth.png"

export const StyledPage = styled.div`
    display: flex;
    flex-direction: column;

    @media (min-width: 1024px) {
        display: grid;
        grid-template-rows: unset;
        grid-template-columns: repeat(2, minmax(auto, 1fr));
    }
    gap: 1.5rem;
`

export const Image = styled.div`
    width: 100%;
    min-height: 25rem;
    max-height: 25rem;
    background: url(${image}) center center / contain no-repeat;
`

export const VideoRow = styled.div`
    margin: 1.5rem 0 2.5rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: space-between;

    @media (min-width: 1024px) {
        flex-direction: row;
    }

    @media (max-width: 1023px) {
        iframe {
            width: 100% !important;
            height: 250px;
        }
    }
`
