import styled from "styled-components"

import successIcon from "assets/icons/success.svg"
import { scaleForward } from "styles/Animation.styled"

export const SuccessMessage = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    color: ${({ theme }) => theme.colors.green};
    animation: ${scaleForward} 1s ease 0s 1 normal forwards;
`

export const Icon = styled.svg`
    width: 40px;
    height: 40px;
    mask-image: url(${successIcon});
    mask-size: contain;
    mask-position: center;
    background-color: ${({ theme }) => theme.colors.green};
`
