import Checkbox from "ui/Checkbox/Checkbox"
import { Table } from "ui/Table/Table.styled"

import useComplaintList, { ComplaintType } from "../hooks/useComplaintList"

const ComplaintList = () => {
    const { complaintList } = useComplaintList()

    return (
        <Table>
            <thead>
                <tr>
                    <td>
                        <Checkbox />
                    </td>
                    <td>Тип жалобы</td>
                    <td>Текст жалобы</td>
                    <td>Объявление</td>
                    <td>Действия</td>
                </tr>
            </thead>
            <tbody>
                {complaintList?.length > 0 &&
                    complaintList.map((complaint: ComplaintType, index) => (
                        <tr key={index}>
                            <td>
                                <Checkbox />
                            </td>
                            <td>{complaint.type.val}</td>
                            <td>{complaint.message}</td>
                            <td>ссылка на объявление</td>
                            <td>Действия</td>
                        </tr>
                    ))}
            </tbody>
        </Table>
    )
}

export default ComplaintList
