import { Link } from "react-router-dom"
import styled from "styled-components"

import trash from "assets/icons/trash.svg"
import { fadeIn } from "styles/Animation.styled"

interface TrashIconProps {
    color?: string
}

export const StyledPostLine = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem 2rem;

    @media (min-width: 1024px) {
        grid-template-columns: minmax(auto, 1fr) minmax(auto, 2fr);
        grid-template-rows: unset;
    }

    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
    border-radius: ${({ theme }) => theme.radius.large};
    padding: 1rem 0.875rem;

    animation: ${fadeIn} 1s ease 0s 1 normal forwards;
`

export const PostDetails = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

export const PostStatistic = styled.div`
    display: flex;
    gap: 0.5rem;
    align-items: flex-start;

    @media (min-width: 1024px) {
        align-items: center;
        gap: 1rem;
    }
`

export const Statistic = styled.div`
    display: flex;
    align-items: center;
    gap: 0.25rem;
`

export const PostAppealsLink = styled(Link)`
    display: none;
    @media (min-width: 1024px) {
        display: block;
        font-weight: 500;
        color: ${({ theme }) => theme.colors.main};
    }
`

export const TrashIcon = styled.svg<TrashIconProps>`
    max-width: 20px;
    height: 18px;
    mask-image: url(${trash});
    mask-position: center;
    mask-size: contain;
    background-color: ${({ theme, color }) => theme.colors[color || "black"]};
    cursor: pointer;

    &:hover {
        background-color: ${({ theme }) => theme.colors.main};
    }
`

export const MobileButtons = styled.div`
    display: flex;
    gap: 2rem;
    grid-area: 3 / 1 / 4 / 3;

    > button {
        padding: 0.5rem 0;
    }

    @media (min-width: 1024px) {
        display: none;
    }
`
