/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosInstance, AxiosResponse, GenericAbortSignal } from "axios"
import queryString from "query-string"

const api = "https://v2.api.jbf.kz"

const axiosConfig: AxiosInstance = axios.create({
    baseURL: api,
    timeout: 30000,
    headers: {
        "Content-Type": "application/x-www-form-urlencoded",
    },
})

export const GET = async (
    url: string,
    params?: Record<string, unknown>,
    headers?: Record<string, unknown>,
    signal?: GenericAbortSignal
): Promise<AxiosResponse> => {
    try {
        const fetch = axiosConfig.get(url, { params, signal, ...headers })
        const response = await fetch
        return response
    } catch (error: any) {
        return {
            ...error?.response,
        }
    }
}

export const POST = async (
    url: string,
    data?: Record<string, unknown>,
    header?: Record<string, unknown>
): Promise<AxiosResponse> => {
    const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        ...header,
    }

    try {
        const fetch = axiosConfig.post(url, queryString.stringify(data || {}), { headers })
        const response = await fetch
        return response
    } catch (error: any) {
        return {
            ...error?.response,
        }
    }
}

export const POST_FORM = async (
    url: string,
    data?: FormData,
    header?: Record<string, unknown>
): Promise<AxiosResponse> => {
    const headers = {
        "Content-Type": "multipart/form-data",
        ...header,
    }

    try {
        const fetch = axiosConfig.post(url, data, { headers })
        const response = await fetch
        return response
    } catch (error: any) {
        return {
            ...error?.response,
        }
    }
}

export const DELETE = async (
    url: string,
    data?: Record<string, unknown>,
    header?: Record<string, unknown>
): Promise<AxiosResponse> => {
    const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        ...header,
    }

    try {
        const fetch = axiosConfig.delete(url, { data, ...headers })
        const response = await fetch
        return response
    } catch (error: any) {
        return {
            ...error?.response,
        }
    }
}
