import { NavLink, useLocation } from "react-router-dom"

import useAuth from "modules/Core/hooks/useAuth"
import { Container, Page } from "styles/Global.styled"

import {
    List,
    StyledCabinetPage,
    UserIcon,
    InboxIcon,
    ListIcon,
    CartIcon,
} from "./CabinetPage.styled"
import Appeals from "./modules/components/Appeals/Appeals"
import Profile from "./modules/components/Profile/Profile"
import Tariff from "./modules/components/Tariff/Tariff"
import UserPosts from "./modules/components/UserPosts/UserPosts"

const CabinetPage = () => {
    const location = useLocation()
    const { auth } = useAuth()
    const pathname = location.pathname.replace("/cabinet/", "")

    return (
        <Page>
            <Container>
                <StyledCabinetPage>
                    <List>
                        <li>
                            <UserIcon />
                            <NavLink to="profile">Профиль</NavLink>
                        </li>
                        <li>
                            <ListIcon />
                            <NavLink to="posts/active">Объявления</NavLink>
                        </li>
                        <li>
                            <InboxIcon />
                            <NavLink to="appeals">Обращения</NavLink>
                        </li>
                        <li>
                            <CartIcon />
                            <NavLink to="tariff">Платежи и тарифы</NavLink>
                        </li>
                        {auth.role === "admin" && (
                            <li>
                                <UserIcon />
                                <NavLink to="/admin/panel">Перейти в панель администратора</NavLink>
                            </li>
                        )}
                    </List>

                    {pathname.includes("profile") && <Profile />}
                    {pathname.includes("posts") && <UserPosts />}
                    {pathname.includes("appeals") && <Appeals />}
                    {pathname.includes("tariff") && <Tariff />}
                </StyledCabinetPage>
            </Container>
        </Page>
    )
}

export default CabinetPage
