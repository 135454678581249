import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import styled from "styled-components"

import heart from "assets/icons/heart.svg"
import heartFull from "assets/icons/heartFull.svg"
import { DELETE, POST } from "modules/Core/api/REST"
import useAuth from "modules/Core/hooks/useAuth"
import useAuthorizationHeader from "modules/Core/hooks/useAuthorizationHeader"
import useMainContext from "modules/Core/hooks/useMainContext"
import { fadeIn } from "styles/Animation.styled"
import { PostType } from "types/common"

interface LikeProps {
    postID?: number
    isLiked: boolean
}

interface StyledLikeProps {
    like: boolean
}

const StyledLike = styled.svg<StyledLikeProps>`
    max-width: 18px;
    max-height: 16px;
    min-height: 16px;
    min-width: 18px;
    mask: url(${({ like }) => (like ? heartFull : heart)}) center / cover no-repeat;
    background-color: ${({ theme }) => theme.colors.main};
    cursor: pointer;
    animation: ${fadeIn} 0.5s linear 0s 1 normal forwards;
`

const Like: React.FC<LikeProps> = ({ postID, isLiked }) => {
    const { auth } = useAuth()
    const navigate = useNavigate()
    const location = useLocation()
    const { favouritePosts, setFavouritePosts } = useMainContext()

    const [isPostLiked, setIsPostLiked] = useState<boolean>(isLiked)

    useEffect(() => {
        setIsPostLiked(isLiked)
    }, [isLiked])

    const headers = useAuthorizationHeader()

    const handleClick = () => {
        if (!auth?.accessToken) {
            navigate("/auth", { state: { from: location } })
            return
        }

        const request = { post_id: postID }

        isPostLiked ? handleDislike(request) : handleLike(request)
    }

    const handleLike = async (request: Record<string, unknown>) => {
        try {
            const response = await POST("/api/service/favourites", request, headers)

            if (response.data.ok === 1) {
                const { post } = response.data.data

                if (response?.data?.detail === "Invalid token or expired token.") {
                    navigate("/auth", { state: { from: location } })
                } else if (!isPostLiked) {
                    setFavouritePosts([...favouritePosts, post])
                }
                setIsPostLiked(true)
            }
            //eslint-disable-next-line
        } catch (error: any) {
            console.log(error)
            navigate("/auth", { state: { from: location } })
        }
    }

    const handleDislike = async (request: Record<string, unknown>) => {
        try {
            const response = await DELETE("/api/service/favourites", request, { headers })

            if (response.data.ok === 1) {
                if (response?.data?.detail === "Invalid token or expired token.") {
                    navigate("/auth", { state: { from: location } })
                } else {
                    setFavouritePosts((currentPosts: PostType[]) =>
                        currentPosts.filter((post: PostType) => {
                            return (post?.post_id || post?.id) !== postID
                        })
                    )
                }
                setIsPostLiked(false)
            }
            //eslint-disable-next-line
        } catch (error: any) {
            console.log(error)
            navigate("/auth", { state: { from: location } })
        }
    }

    return <StyledLike onClick={handleClick} like={isPostLiked} />
}

export default Like
