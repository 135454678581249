import styled from "styled-components"

import { Container, Page } from "styles/Global.styled"

const StyledNotFound = styled.div`
    font-size: 2rem;
    flex-grow: 15;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    text-align: center;
`

const NotFound = () => {
    return (
        <Page>
            <StyledNotFound>
                <Container>404 | Страница не найдена</Container>
            </StyledNotFound>
        </Page>
    )
}

export default NotFound
