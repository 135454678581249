import { Link } from "react-router-dom"

import { Caption, Icon, StyledNoRating } from "./NoRating.styled"

const NoRating = () => {
    return (
        <StyledNoRating>
            <Icon />
            <Caption>
                Этот автор пока не получил ни одного отзыва
                <Link to="/ratingsystem">Узнать как работает рейтинг</Link>
            </Caption>
        </StyledNoRating>
    )
}

export default NoRating
