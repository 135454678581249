import styled from "styled-components"

import sideBanner from "assets/banner/sideBanner.jpeg"

export const StyledSideBanner = styled.div`
    display: none;
    @media (min-width: 1024px) {
        width: 100%;
        min-height: 375px;
        margin-top: 2rem;
        background: linear-gradient(rgba(255, 125, 2, 0.6) 0%, rgba(32, 32, 32, 0.6) 100%),
            url(${sideBanner}) center center / cover no-repeat;
        border-radius: ${({ theme }) => theme.radius.small};
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        text-align: center;
        padding: 2rem;
        color: ${({ theme }) => theme.colors.white};

        font-family: "Montserrat";
        font-weight: 700;
    }
`

const SideBanner = () => {
    return <StyledSideBanner>Заказы по стройке во всей стране</StyledSideBanner>
}

export default SideBanner
