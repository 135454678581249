import styled from "styled-components"

export const StyledFooter = styled.footer`
    background-color: ${({ theme }) => theme.colors.black};
    display: flex;
    justify-content: center;
    font-size: 0.875rem;
    padding-bottom: 5.5rem;

    @media (min-width: 1024px) {
        padding: 2.75rem 0;
        margin-top: 2.75rem;
    }

    > div {
        display: grid;
        gap: 1.5rem;

        @media (min-width: 1024px) {
            gap: 0px;
            grid-template-columns: 1fr 2fr 1fr;
        }
    }
`

export const MidFooterItem = styled.section`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
`

export const FooterItem = styled.section`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`

export const List = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    list-style: none;
    padding: 0;
    margin: 0;

    a {
        color: ${({ theme }) => theme.colors.white};
        text-decoration: none;
        font-weight: 400;
        line-height: 1rem;

        &:hover {
            text-decoration: underline;
        }
    }
`

export const FooterText = styled.div`
    color: ${({ theme }) => theme.colors.white};
`
