import styled from "styled-components"

import sideNavControl from "assets/admin/11.svg"
import userIcon from "assets/admin/7.svg"

interface IconProps {
    image: string
}

export const StyledAdminPanelNavigation = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    background-color: ${({ theme }) => theme.colors.admin.whiteSmoke};
    border-radius: ${({ theme }) => theme.radius.huge};

    padding: 1.1875rem 1.4375rem;

    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.05);

    position: relative;

    transition: all 1s linear;
`

export const Navigators = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
`

export const Navigator = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 0.75rem 0.625rem;

    cursor: pointer;

    &.active {
        background-color: ${({ theme }) => theme.colors.white};
        border-radius: ${({ theme }) => theme.radius.huge};
    }

    &:hover {
        background-color: ${({ theme }) => theme.colors.white};
        border-radius: ${({ theme }) => theme.radius.huge};
    }
`

export const Icon = styled.i<IconProps>`
    background-size: cover;
    width: 25px;
    height: 25px;
    background: url("${({ image }) => image}") center / contain no-repeat;
`

export const UserIcon = styled(Navigator)`
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: ${({ theme }) => theme.radius.huge};

    i {
        width: 25px;
        height: 25px;
        background: url("${userIcon}") center center/contain no-repeat;
    }
`

export const NavigationControlClose = styled.div`
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
    border-radius: ${({ theme }) => theme.radius.large};

    height: 32px;
    width: 32px;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
    transform: rotate(-180deg);
    background: url("${sideNavControl}") center / 0.7em no-repeat;
    background-color: ${({ theme }) => theme.colors.white};
`

export const NavigationControlOpen = styled(NavigationControlClose)`
    transform: rotate(0deg);
    position: absolute;
    right: -3rem;
`
