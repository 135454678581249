import { CheckIcon, StyledCheckbox } from "./Checkbox.styled"

interface CheckboxProps {
    checked?: boolean
    //eslint-disable-next-line
    handleChange?: (value?: any) => void
}

const Checkbox: React.FC<CheckboxProps> = ({ checked, handleChange }) => {
    return <StyledCheckbox onClick={handleChange}>{checked && <CheckIcon />}</StyledCheckbox>
}

export default Checkbox
