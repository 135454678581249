import styled from "styled-components"

export const StyledCataloguePage = styled.div`
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    @media (min-width: 1024px) {
        margin-top: 2rem;
        display: grid;
        grid-template-columns: 1fr 3fr;
    }
`
