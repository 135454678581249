import { useNavigate } from "react-router-dom"

import MarketIcons from "modules/components/MarketIcons/MarketIcons"
import useMainContext from "modules/Core/hooks/useMainContext"
import { CategoryType } from "types/common"
import { DOWNLOAD_JBF_ID } from "utils/helpers/variables"

import {
    BannerText,
    Header,
    MainMenuItem,
    MobileAppBanner,
    Navigation,
    NavigationCaption,
    NavigationIcon,
    NavigationIconWrapper,
    NavigationItem,
    StyledMainMenu,
} from "./MainMenu.styled"

import MainMenuCarousel from "../MainMenuCarousel/MainMenuCarousel"

const MainMenu = () => {
    const { categoryList } = useMainContext()
    const navigate = useNavigate()

    const handleIconClick = (id: number) => {
        if (id === DOWNLOAD_JBF_ID) navigate("/documents")
        else navigate(`catalogue/${id}`)
    }
    return (
        <StyledMainMenu>
            <MainMenuItem>
                {/* <Header>Навигация по сайту:</Header> */}
                <Navigation>
                    {categoryList &&
                        categoryList.map((category: CategoryType) => (
                            <NavigationItem key={category.id}>
                                <NavigationIconWrapper onClick={() => handleIconClick(category.id)}>
                                    <NavigationIcon url={category.icon} />
                                </NavigationIconWrapper>
                                <NavigationCaption
                                    to={
                                        category.id === DOWNLOAD_JBF_ID
                                            ? "documents"
                                            : `catalogue/${category.id}`
                                    }
                                >
                                    {category.name}
                                </NavigationCaption>
                            </NavigationItem>
                        ))}
                </Navigation>
            </MainMenuItem>
            <MainMenuItem>
                <MainMenuCarousel />
            </MainMenuItem>
            <MainMenuItem>
                {/* <Banners>
                    <Banner url={firstPic} caption="Логистика" />
                    <Banner url={secondPic} caption="Арендодатели" />
                </Banners> */}
                <MobileAppBanner>
                    <Header>Скачивай приложение</Header>
                    <BannerText>
                        С приложением всё быстрее и удобнее
                        <br />
                        Мы доступны на всех платформах
                    </BannerText>
                    <MarketIcons />
                </MobileAppBanner>
            </MainMenuItem>
        </StyledMainMenu>
    )
}

export default MainMenu
