import { PostType } from "types/common"

export const postStatusOptions = [
    { value: "0", label: "Неактивный" },
    { value: 1, label: "Активный" },
    { value: 2, label: "Модерация" },
    { value: 3, label: "Архивный" },
    { value: 4, label: "Удаленный" },
]

export const postStatusOptionsText = [
    { value: 0, label: "inactive" },
    { value: 1, label: "active" },
    { value: 2, label: "moderation" },
    { value: 3, label: "archive" },
    { value: 4, label: "deleted" },
]

export const getPostStatus = (post: PostType) => {
    const status = postStatusOptions.filter((option) => Number(option.value) === post.status.key)
    return status[0]
}

export const getPostStatusText = (statusKey: number | string) => {
    const status = postStatusOptionsText.filter(
        (option) => Number(option.value) === Number(statusKey)
    )
    return status[0].label
}
