import styled from "styled-components"

export const StyledPostPreview = styled.div`
    display: grid;
    grid-template-columns: 3fr 1fr;
    margin-top: 1.6875rem;
    gap: 1.75rem;
`

export const PreviewButtons = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
`

export const PreviewSideNav = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 4rem;
`

export const StickyOff = styled.div``
