import styled from "styled-components"

import { GHeader } from "styles/Global.styled"

export const StyledLeaveRating = styled.div`
    margin-top: 1.5rem;
`

export const Header = styled(GHeader)`
    font-size: 0.875rem;
`
export const List = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 1.5rem;
    gap: 1rem;
`

export const Item = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 0.75rem;

    input[type="radio"] {
        -ms-transform: scale(1.5);
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
        width: fit-content;
        cursor: pointer;
    }
`
