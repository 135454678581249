import { Button } from "ui/Button/Button.styled"

import {
    StyledTariff,
    TariffBlock,
    TariffConditions,
    TariffHint,
    TariffIcon,
    TariffName,
    TariffPrice,
} from "./Tariff.styled"

import { TARIFF_LIST } from "../../data"

const Tariff = () => {
    return (
        <StyledTariff>
            {TARIFF_LIST.map((tariff) => (
                <TariffBlock key={tariff.name}>
                    <TariffIcon image={tariff.image} />
                    <TariffName>{tariff.name}</TariffName>
                    <TariffPrice>{tariff.price}</TariffPrice>
                    <TariffHint>{tariff.hint}</TariffHint>
                    <TariffConditions>
                        {tariff.conditions.map((condition, index) => (
                            <div data-disabled={!condition.active} key={index}>
                                {condition.caption}
                            </div>
                        ))}
                    </TariffConditions>
                    <Button>Выбрать</Button>
                </TariffBlock>
            ))}
        </StyledTariff>
    )
}

export default Tariff
