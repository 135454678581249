import { AddonPrepend, PriceInput, StyledPriceInputForm } from "./PriceInputForm.styled"

interface PriceInputFormProps {
    label: string
    handleChange: (value: string) => void
}

const PriceInputForm: React.FC<PriceInputFormProps> = ({ label, handleChange }) => {
    return (
        <StyledPriceInputForm>
            <span>
                <AddonPrepend>
                    <span>{label}</span>
                </AddonPrepend>
                <PriceInput
                    type="number"
                    onChange={(event) => handleChange(event.currentTarget.value)}
                />
            </span>
        </StyledPriceInputForm>
    )
}

export default PriceInputForm
