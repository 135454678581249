import useAuth from "./useAuth"

const useAuthorizationHeader = () => {
    const { auth } = useAuth()
    return {
        Authorization: `Bearer ${auth?.accessToken}`,
    }
}

export default useAuthorizationHeader
