import { useState } from "react"

import HomeAbout from "modules/components/AboutComponent/HomeAbout/HomeAbout"
import BottomBanner from "modules/components/BottomBanner/BottomBanner"
import PostList from "modules/components/PostList/PostList"
import usePostList from "modules/Core/hooks/usePostList"
import { Container, Page } from "styles/Global.styled"

import { StyledHomePage } from "./HomePage.styled"
import MainMenu from "./modules/components/MainMenu/MainMenu"

const HomePage = () => {
    const [page, setPage] = useState(1)
    const { postList, showLoadMoreBtn } = usePostList(page)

    const handleLoadMore = () => {
        setPage((current) => current + 1)
    }

    return (
        <Page>
            <StyledHomePage>
                <Container>
                    <MainMenu />
                    <PostList
                        header="Все объявления"
                        postList={postList}
                        handleLoadMore={showLoadMoreBtn ? handleLoadMore : undefined}
                    />
                </Container>
                <BottomBanner />
                <HomeAbout />
            </StyledHomePage>
        </Page>
    )
}

export default HomePage
