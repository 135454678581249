import styled from "styled-components"

import editIcon from "assets/icons/edit.svg"

export const StyledEditButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.gray};
    padding: 0.625rem;
    border-radius: 16px;

    i {
        width: 1.4375rem;
        height: 1.5625rem;
        mask: url("${editIcon}") center / contain no-repeat;

        background-color: white;
    }
`
