import { UserType } from "types/common"

/*eslint-disable-next-line*/
export const checkForNull = (value?: any) => {
    if (value === "null" || value === null || !value) return ""

    return value
}

export const userStatusOptions = [
    { value: "active", label: "Активный" },
    { value: "inactive", label: "Неактивный" },
    { value: "archive", label: "Архивный" },
]

export const getUserStatus = (user: UserType) => {
    if (user.is_archive) return { value: "archive", label: "В архив" }

    return user.is_active
        ? { value: "active", label: "Активный" }
        : { value: "inactive", label: "Неактивный" }
}

export const getUserStatusText = (user: UserType) => {
    if (user.is_archive) return "archive"

    return user.is_active ? "active" : "inactive"
}
