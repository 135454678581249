import styled from "styled-components"

interface AdditionalProps {
    showAll: boolean
}

export const MainHeader = styled.div`
    display: flex;
    flex-direction: column;
`

export const Additional = styled.div<AdditionalProps>`
    max-height: ${({ showAll }) => (showAll ? "unset" : "5rem")};
    overflow: hidden;
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, minmax(auto, 1fr));
    gap: 1.5rem;

    > section > h2 {
        display: block;
        margin-top: 1rem;
    }

    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        z-index: 1;
        display: block;
        width: 100%;
        height: 2.5rem;
        background: ${({ showAll }) =>
            showAll ? "unset" : "linear-gradient(180deg, hsla(0, 0%, 100%, 0) 0, #fff 70%, #fff);"};
    }
`
