import { useState } from "react"

import { Header } from "pages/AdminPanelPage/AdminPanelPage.styled"
import { UserType } from "types/common"

import CreateUserForm from "./components/CreateUserForm"
import UserList from "./components/UserList"
import { UserProvider } from "./context/UserContext"

const Users = () => {
    const [currentState, setCurrentState] = useState("list")
    const [userEdit, setUserEdit] = useState<UserType>()

    return (
        <>
            <Header>Пользователи</Header>
            <UserProvider>
                {currentState === "list" ? (
                    <UserList setCurrentState={setCurrentState} setUserEdit={setUserEdit} />
                ) : (
                    <CreateUserForm setCurrentState={setCurrentState} userEdit={userEdit} />
                )}
            </UserProvider>
        </>
    )
}

export default Users
