import { useEffect, useState } from "react"

import { GET } from "modules/Core/api/REST"
import useAuthorizationHeader from "modules/Core/hooks/useAuthorizationHeader"
import { UserType } from "types/common"

const useUserList = (page: number, ppp: number, q?: string) => {
    const [userList, setUserList] = useState<UserType[]>([])
    const [loading, setLoading] = useState(true)
    const headers = useAuthorizationHeader()

    useEffect(() => {
        const fGetList = async () => {
            const { data } = await GET(
                `/api/admin/users/?page=${page}&post_per_page=${ppp}&q=${q}`,
                {},
                { headers }
            )
            if (data.ok === 1) {
                setUserList(data.data.users)
                setLoading(false)
            }
        }

        fGetList()
    }, [page, ppp, q])

    return { userList, setUserList, loading, setLoading }
}

export default useUserList
