import { useState } from "react"

import Logo from "modules/components/Logo/Logo"
import { Row } from "styles/Global.styled"

import {
    Icon,
    NavigationControlClose,
    NavigationControlOpen,
    Navigator,
    Navigators,
    StyledAdminPanelNavigation,
    UserIcon,
} from "./AdminPanelNavigation.styled"

import { NAVIGATION_ITEMS } from "../../helpers"
import useAdminContext from "../../hooks/useAdminContext"

const AdminPanelNavigation = () => {
    const adminContext = useAdminContext()
    const [isSideNavOpen, setIsSideNavOpen] = useState(false)

    const handleSideNavControl = () => {
        setIsSideNavOpen((current) => !current)
    }

    return (
        <StyledAdminPanelNavigation>
            <Row justify="space-between">
                <Logo color="main" />
                {isSideNavOpen && <NavigationControlClose onClick={handleSideNavControl} />}
            </Row>

            <Navigators>
                {NAVIGATION_ITEMS.map((item) => (
                    <Navigator
                        key={item.name}
                        title={item.title}
                        className={
                            adminContext?.activeNavigation.name === item.name ? "active" : ""
                        }
                        onClick={() => adminContext?.setActiveNavigation(item)}
                    >
                        <Icon image={item.image} />
                        {isSideNavOpen ? item.title : ""}
                    </Navigator>
                ))}
            </Navigators>

            <UserIcon>
                <i />
            </UserIcon>

            {!isSideNavOpen && <NavigationControlOpen onClick={handleSideNavControl} />}
        </StyledAdminPanelNavigation>
    )
}

export default AdminPanelNavigation
