import styled from "styled-components"

interface ButtonProps {
    btnColor?: string
}

export const Button = styled.button<ButtonProps>`
    border: none;
    outline: none;
    color: ${({ theme }) => theme.colors.white};
    border-radius: ${({ theme }) => theme.radius.large};
    background-color: ${({ theme, btnColor }) => theme.colors[btnColor || "main"]};

    width: 100%;
    padding: 0.95rem 1rem;

    /* font-size: 1rem; */
    font-weight: 500;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;

    &:disabled {
        cursor: not-allowed;
        opacity: 0.8;
    }
`
