import { createContext } from "react"

interface AuthFormContextProps {
    setForgetPassword: (value: boolean) => void
    isAdmin: boolean
    showPassword?: boolean
    setShowPassword: (value: boolean) => void
    setErrorMessage: (value: string) => void
}

export const AuthFormContext = createContext<AuthFormContextProps | null>(null)
