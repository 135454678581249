import appstore from "assets/appLinkIcons/appstore.svg"
import googleplay from "assets/appLinkIcons/googleplay.svg"

import { MarketIcon, StyledMarketIcons } from "./MarketIcons.styled"

const MarketIcons = () => {
    const handleClick = (link: string) => {
        window.open(link, "_blank")
    }

    return (
        <StyledMarketIcons>
            <MarketIcon
                url={googleplay}
                onClick={() =>
                    handleClick("https://play.google.com/store/apps/details?id=com.cwb.jbf")
                }
            />
            <MarketIcon
                url={appstore}
                onClick={() => handleClick("https://apps.apple.com/kz/app/jbf/id6451271348")}
            />
            {/* <MarketIcon url={huawei} /> */}
        </StyledMarketIcons>
    )
}

export default MarketIcons
