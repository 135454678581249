import moment from "moment"
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

import Breadcrumbs from "modules/components/Breadcrumbs/Breadcrumbs"
import Loading from "modules/components/Loading/Loading"
import { POST } from "modules/Core/api/REST"
import useAuth from "modules/Core/hooks/useAuth"
import useAuthorizationHeader from "modules/Core/hooks/useAuthorizationHeader"
import {
    StarIcon,
    TrashIcon,
} from "pages/CabinetPage/modules/components/Appeals/modules/components/AppealsList/components/DesktopMessage/DesktopMessage.styled"
import { Container, CustomForm, CustomFormItem, Page } from "styles/Global.styled"
import { CommentType } from "types/common"
import { Button } from "ui/Button/Button.styled"
import { Textarea } from "ui/Textarea/Textarea.styled"

import {
    ButtonRow,
    Header,
    Icons,
    MessageContainer,
    UserAvatar,
    UserInfo,
    UserName,
} from "./InboxMessagePage.styled"
import LeaveRating from "./modules/component/LeaveRating/LeaveRating"
import useMessageDetails from "./modules/hooks/useMessageDetails"
import useOrderDetails from "./modules/hooks/useOrderDetails"

const routes = ["cabinet", "messages", "messagePost"]

/*
0-не началась
1-работа идет
2-работа закончилась
*/

const InboxMessagePage = () => {
    const { auth } = useAuth()
    const { state } = useLocation()
    const navigate = useNavigate()
    const { id, appeal } = state
    const { post } = appeal
    const headers = useAuthorizationHeader()
    const {
        order,
        orderStatus,
        setOrderStatus,
        postRating,
        userRating,
        setPostRating,
        setUserRating,
    } = useOrderDetails(id)
    const { details, setDetails, isLoading } = useMessageDetails(order?.id)

    const userType = post.user_email === auth?.user?.email ? "customer" : "partner"

    const [userComment, setUserComment] = useState("")
    const [isFavourite, setIsFavourite] = useState<boolean>(order?.is_favourite)
    const [jobFinished, setJobFinished] = useState(true)

    useEffect(() => {
        setJobFinished(orderStatus === 2)
    }, [orderStatus])

    const [rating, setRating] = useState(0)

    const handleAnswer = async () => {
        if (!userComment) return

        const request = {
            comment: userComment,
            order_id: order?.id,
        }

        try {
            const { data: response } = await POST(
                "/api/service/order/comment/create/",
                request,
                headers
            )
            if (response.ok === 1) {
                setDetails((current) => [response?.data?.comment, ...current])
                setUserComment("")
            } else {
                throw new Error(JSON.stringify(response.details))
            }
        } catch (err) {
            toast.error("Произошла ошибка при отправке сообщения! Попробуйте позже")
            console.log(err)
        }
    }

    const handleJobStart = async () => {
        const request = {
            order_id: order?.id,
            order_status: 1,
        }

        try {
            const { data: response } = await POST(
                "/api/service/order/change-status/",
                request,
                headers
            )
            if (response.ok === 1) setOrderStatus(1)
            else throw new Error()
        } catch (err) {
            setOrderStatus(0)
        }
    }

    const handleJobFinish = async () => {
        setJobFinished(true)
    }

    const handleRating = async () => {
        if (rating === 0) {
            alert("Поставьте оценку")
            return
        }

        const request = {
            rating: Number(rating),
            order_id: order?.id,
        }

        try {
            const method = `/api/service/order/${
                userType === "customer" ? "close/" : "client-rating/"
            }`

            const { data: response } = await POST(method, request, headers)

            if (response.ok === 1) {
                setOrderStatus(2)

                if (userType === "partner") {
                    setPostRating(Number(rating))
                } else if (userType === "customer") {
                    setUserRating(Number(rating))
                }
                toast.success("Оценка успешно поставлена! Спасибо!")
            } else {
                throw new Error(JSON.stringify(response.details))
            }
        } catch (err) {
            toast.error("Произошла ошибка " + err)
        }
    }

    const handleFavourite = async (event: React.MouseEvent) => {
        event.stopPropagation()

        const request = {
            order_id: order?.id,
            is_favourite: !isFavourite,
        }

        try {
            await POST("/api/service/order/favourite/", request, headers)
            setIsFavourite((current) => !current)
        } catch (err) {
            toast.error("Произошла ошибка " + JSON.stringify(err))
        }
    }

    const handleArchive = async (event: React.MouseEvent) => {
        event.stopPropagation()

        if (window.confirm("Вы действительно хотите удалить данное сообщение?")) {
            try {
                await POST("api/service/orders/archive", { order_ids: [order?.id] }, headers)
                navigate("/cabinet/appeals")
            } catch (err) {
                toast.error("Произошла ошибка " + JSON.stringify(err))
            }
        }
    }

    // console.log(orderStatus, jobFinished, userType, postRating)

    return (
        <Page>
            <Container>
                <Breadcrumbs routes={routes} />

                {isLoading && <Loading />}

                <Header>
                    {post.title}

                    <Icons>
                        <StarIcon liked={isFavourite} onClick={handleFavourite} />
                        <TrashIcon onClick={handleArchive} />
                    </Icons>
                </Header>

                <MessageContainer>
                    <UserInfo>
                        <UserAvatar>{order?.user_name?.charAt(0)}</UserAvatar>
                        <UserName>
                            {order?.user_name}, {moment(order?.date).format("DD MMMM HH:mm")}
                        </UserName>
                    </UserInfo>
                    {order?.comment}
                </MessageContainer>

                {details.length > 0 &&
                    details
                        .slice(0)
                        .reverse()
                        .map((message: CommentType) => (
                            <MessageContainer key={message.id}>
                                <UserInfo>
                                    <UserAvatar postCustomer={message.user_id !== order?.client_id}>
                                        {message.name?.charAt(0)}
                                    </UserAvatar>
                                    <UserName>
                                        {message.name},
                                        {moment(message.date).format("DD MMMM HH:mm")}
                                    </UserName>
                                </UserInfo>
                                {message.comment}
                            </MessageContainer>
                        ))}

                <CustomForm>
                    <CustomFormItem>
                        {orderStatus === 0 && <h2>Работа еще не началась!</h2>}
                        {orderStatus === 1 && <h3>Работа началась!</h3>}
                        {orderStatus === 2 && <h4>Работа завершена!</h4>}
                    </CustomFormItem>
                    <CustomFormItem>
                        <label htmlFor="">Ваш ответ</label>
                        <Textarea
                            rows={4}
                            placeholder="Например: Бригада строителей"
                            value={userComment}
                            onChange={(e) => setUserComment(e.target.value)}
                        />
                    </CustomFormItem>
                </CustomForm>

                {jobFinished && userType === "customer" && !userRating && (
                    <LeaveRating handleRatingResult={setRating} userType={userType} />
                )}

                {jobFinished && userType === "partner" && !postRating && (
                    <LeaveRating handleRatingResult={setRating} userType={userType} />
                )}

                <ButtonRow>
                    {orderStatus === 0 && userType === "customer" && (
                        <Button onClick={handleJobStart}>
                            <i />
                            Начать работу
                        </Button>
                    )}

                    {[0, 1].includes(orderStatus) && !jobFinished && (
                        <Button btnColor="black" onClick={handleAnswer}>
                            Ответить
                        </Button>
                    )}

                    {orderStatus === 1 && userType === "customer" && !jobFinished && (
                        <Button onClick={handleJobFinish}>
                            <i />
                            Закончить работу
                        </Button>
                    )}

                    {jobFinished && userType === "customer" && !userRating && (
                        <Button onClick={handleRating}>Поставить оценку</Button>
                    )}

                    {jobFinished && userType === "partner" && !postRating && (
                        <Button onClick={handleRating}>Поставить оценку</Button>
                    )}
                </ButtonRow>
            </Container>
        </Page>
    )
}

export default InboxMessagePage
