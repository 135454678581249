import styled from "styled-components"

import cartImage from "assets/cabinet/cart.svg"
import inboxImage from "assets/cabinet/inbox.svg"
import listImage from "assets/cabinet/list.svg"
import userImage from "assets/cabinet/user.svg"

export const StyledCabinetPage = styled.div`
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 3rem;

    @media (min-width: 1024px) {
        display: grid;
        grid-template-columns: 1fr 3fr;
        gap: unset;
        margin-top: 2rem;
    }
`

export const List = styled.ul`
    display: flex;
    justify-content: space-evenly;

    list-style: none;
    padding: 0;
    margin: 0;

    @media (min-width: 1024px) {
        flex-direction: column;
        justify-content: unset;
        gap: 1.875rem;
    }

    li {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;

        @media (min-width: 1024px) {
            flex-direction: unset;
            justify-content: unset;
        }
    }

    a {
        color: ${({ theme }) => theme.colors.black};
        text-decoration: none;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1rem;

        @media (min-width: 1024px) {
            font-weight: 600;
        }

        &.active {
            color: ${({ theme }) => theme.colors.main};
        }

        &:hover {
            color: ${({ theme }) => theme.colors.main};
        }
    }
`

const Icon = styled.i`
    width: 22px;
    height: 18px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
`

export const UserIcon = styled(Icon)`
    background-image: url(${userImage});
`
export const InboxIcon = styled(Icon)`
    background-image: url(${inboxImage});
`
export const ListIcon = styled(Icon)`
    background-image: url(${listImage});
`
export const CartIcon = styled(Icon)`
    background-image: url(${cartImage});
`
