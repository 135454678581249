import { ErrorMessage, Field, Form, Formik, FormikProps } from "formik"
import { useRef, useState } from "react"
import { useLocation } from "react-router-dom"

import Breadcrumbs from "modules/components/Breadcrumbs/Breadcrumbs"
import CustomToast from "modules/components/CustomToast/CustomToast"
import { POST_FORM } from "modules/Core/api/REST"
import useAuthorizationHeader from "modules/Core/hooks/useAuthorizationHeader"
import useMainContext from "modules/Core/hooks/useMainContext"
import useOptions from "modules/Core/hooks/useOptions"
import useScrollToTop from "modules/Core/hooks/useScrollToTop"
import { Container, CustomForm, CustomFormItem, GHeader, Page, Row } from "styles/Global.styled"
import { CategoryType, ToastType } from "types/common"
import { Button } from "ui/Button/Button.styled"
import { renderError } from "utils/helpers"
import {
    InputComponent,
    TextareaComponent,
    SelectСomponent,
} from "utils/helpers/components/FormikField"
import { PRIVATE_OPTIONS } from "utils/helpers/list"
import { DOWNLOAD_JBF_ID } from "utils/helpers/variables"

import { CreatePost, Note, PriceToggle, StyledCreatePostPage } from "./CreatePostPage.styled"
import CreatedModal from "./modules/components/CreatedModal/CreatedModal"
import Pictures from "./modules/components/Pictures/Pictures"
import PostPreview from "./modules/components/PostPreview/PostPreview"
import { validationSchema } from "./modules/helpers"
import useContractPrice from "./modules/hooks/useContractPrice"
import useInitialValues from "./modules/hooks/useInitialValues"

const routes = ["catalogue", "new"]

export interface CreatePostForm {
    title: string
    description: string
    category: number
    city: number
    isPrivate: number | string
    price: number | string
    firstName: string
    phone: string
}

const CreatePostPage = () => {
    const { state } = useLocation()
    const headers = useAuthorizationHeader()

    const ref = useRef() as React.MutableRefObject<HTMLDivElement | null>

    const { categoryList, cityList } = useMainContext()

    const { options: categoryOptions } = useOptions(
        categoryList.filter((category: CategoryType) => category.id !== DOWNLOAD_JBF_ID)
    )
    const { options: cityOptions } = useOptions(cityList)
    const { initialValues } = useInitialValues(state?.postDetails)
    const { isContractPrice, fTogglePrice } = useContractPrice(initialValues.price || 0)

    const [isPreview, setIsPreview] = useState(false)
    const [formValues, setFormValues] = useState<CreatePostForm>()
    const [isLoading, setIsLoading] = useState(false)
    const [createdPostID, setCreatedPostID] = useState(0)

    //eslint-disable-next-line
    const [formPostImages, setFormPostImages] = useState<any>({
        main: "",
        imageList: [],
    })

    const [toastProps, setToastProps] = useState<ToastType>({
        display: false,
    })

    useScrollToTop(ref, "smooth", isPreview)

    const handlePreviewToggle = () => {
        setIsPreview((current) => !current)
    }

    const handleSubmit = (values: CreatePostForm) => {
        setIsLoading(true)
        handleSubmitRequest(values)
    }

    const handleSubmitRequest = async (values: CreatePostForm) => {
        const updateMode = state?.postDetails
        const { main, imageList } = formPostImages

        const formData = new FormData()

        if (updateMode) formData.append("post_id", state?.postDetails.post.id)

        formData.append("title", values.title)
        formData.append("is_private", String(values.isPrivate === 2 ? 0 : values.isPrivate))
        formData.append("category_id", String(values.category))
        formData.append("city_id", String(values.city))
        formData.append("price", String(values.price || "0"))
        formData.append("description", values.description)
        formData.append("add_phone", values.phone)
        formData.append("add_name", values.firstName)

        const weights: number[] = []
        const mainImageIndex: number[] = []
        //eslint-disable-next-line
        imageList.forEach((image: any, index: number) => {
            if (!image.id) {
                if (main === image) mainImageIndex.push(index)
                formData.append("images_slider", image)
                weights.push(index)
            }
        })
        formData.append("weights", weights.join(","))

        if (updateMode) {
            if (!main?.id)
                formData.append(
                    "image_index",
                    mainImageIndex.length > 0 ? String(mainImageIndex[0]) : "0"
                )
            else formData.append("image_id", main?.id)
        } else if (main) formData.append("image", main)

        try {
            const link = state?.postDetails ? "update" : "create"
            const response = await POST_FORM(`/api/service/${link}/`, formData, headers)

            if (response.data.detal) {
                setToastProps({
                    display: true,
                    message:
                        "Упс...Возникла ошибка при сохранении объявления! Мы уже работаем над устранением причины!",
                })
            } else {
                setCreatedPostID(response?.data?.data?.post?.id)
            }
            //eslint-disable-next-line
        } catch (err: any) {
            setToastProps({ display: true, message: err.message })
            console.log(err)
        } finally {
            setIsLoading(false)
        }
    }

    const handlePreview = async (formikProps: FormikProps<CreatePostForm>) => {
        const formikValidationResult = await formikProps.validateForm()
        if (Object.keys(formikValidationResult).length > 0) return

        setFormValues(formikProps.values)
        handlePreviewToggle()
    }

    return (
        <Page ref={ref}>
            <CustomToast toastProps={toastProps} />
            {createdPostID > 0 && <CreatedModal postID={createdPostID} />}
            <Container>
                <Breadcrumbs routes={routes} />

                {isPreview && (
                    <PostPreview
                        formValues={formValues || initialValues}
                        formPostImages={formPostImages}
                        handlePreviewToggle={handlePreviewToggle}
                        handleSubmit={handleSubmit}
                    />
                )}
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validateOnBlur={false}
                    enableReinitialize={true}
                    validationSchema={validationSchema}
                >
                    {(formikProps) => (
                        <Form>
                            <StyledCreatePostPage hide={isPreview}>
                                <GHeader>
                                    {initialValues.title
                                        ? "Редактирование объявление"
                                        : "Создать объявление"}
                                </GHeader>

                                <CreatePost>
                                    <CustomForm>
                                        <CustomFormItem>
                                            <label htmlFor="">Название</label>
                                            <Field name="title" component={InputComponent} />
                                            <ErrorMessage name="title" render={renderError} />
                                        </CustomFormItem>
                                        <CustomFormItem>
                                            <label htmlFor="">Категория</label>
                                            <Field
                                                name="category"
                                                component={SelectСomponent}
                                                placeholder="Выберите категорию"
                                                options={categoryOptions}
                                            />
                                            <ErrorMessage name="category" render={renderError} />
                                        </CustomFormItem>
                                        <CustomFormItem>
                                            <label htmlFor="">Описание</label>
                                            <Field
                                                component={TextareaComponent}
                                                name="description"
                                                maxLength={3000}
                                                rows={4}
                                            />
                                            <Note>0/3000</Note>
                                            <ErrorMessage name="description" render={renderError} />
                                        </CustomFormItem>
                                        <CustomFormItem>
                                            <label htmlFor="">Цена</label>
                                            <Field
                                                name="price"
                                                component={InputComponent}
                                                disabled={isContractPrice}
                                            />
                                            <ErrorMessage name="price" render={renderError} />
                                        </CustomFormItem>
                                        <Row gap="2rem">
                                            Договорная:
                                            <PriceToggle
                                                onClick={() => fTogglePrice(formikProps)}
                                                active={isContractPrice}
                                            />
                                        </Row>
                                        <CustomFormItem>
                                            <label htmlFor="">Частный или бизнес</label>
                                            <Field
                                                name="isPrivate"
                                                component={SelectСomponent}
                                                options={PRIVATE_OPTIONS}
                                            />
                                            <ErrorMessage name="isPrivate" render={renderError} />
                                        </CustomFormItem>
                                        <CustomFormItem>
                                            <label htmlFor="">Местоположение</label>
                                            <Field
                                                name="city"
                                                component={SelectСomponent}
                                                placeholder="Выберите город"
                                                options={cityOptions}
                                            />
                                            <ErrorMessage name="city" render={renderError} />
                                        </CustomFormItem>
                                        <CustomFormItem>
                                            <label htmlFor="">Контактное лицо</label>
                                            <Field name="firstName" component={InputComponent} />
                                            <ErrorMessage name="firstName" render={renderError} />
                                        </CustomFormItem>
                                        <CustomFormItem>
                                            <label htmlFor="">Номер телефона</label>
                                            <Field name="phone" component={InputComponent} />
                                            <ErrorMessage name="phone" render={renderError} />
                                        </CustomFormItem>
                                    </CustomForm>

                                    <Pictures setFormPostImages={setFormPostImages} />

                                    <Row gap="1.875rem">
                                        <Button
                                            btnColor="grayC4"
                                            type="button"
                                            disabled={isLoading}
                                            onClick={() => {
                                                handlePreview(formikProps)
                                            }}
                                        >
                                            Предварительный просмотр
                                        </Button>
                                        <Button type="submit" disabled={isLoading}>
                                            {initialValues.title
                                                ? "Сохранить изменения"
                                                : "Подать объявление"}
                                        </Button>
                                    </Row>
                                </CreatePost>
                            </StyledCreatePostPage>
                        </Form>
                    )}
                </Formik>
            </Container>
        </Page>
    )
}

export default CreatePostPage
