import { useEffect, useState } from "react"

import { GET } from "modules/Core/api/REST"
import useAuthorizationHeader from "modules/Core/hooks/useAuthorizationHeader"
import { OrderType } from "types/common"

const useOrderDetails = (id: number) => {
    const [order, setOrders] = useState<OrderType>({} as OrderType)
    const [orderStatus, setOrderStatus] = useState<number>(-1)
    const [postRating, setPostRating] = useState<number | undefined>(undefined)
    const [userRating, setUserRating] = useState<number | undefined>(undefined)
    const [isLoading, setIsLoading] = useState(true)
    const headers = useAuthorizationHeader()

    const fGetDetails = async () => {
        try {
            const { data: response } = await GET(`/api/service/orders/${id}`, {}, { headers })
            setOrders(response?.data?.order)
            setOrderStatus(response?.data?.order.status)
            setPostRating(response?.data?.post_rating?.rating)
            setUserRating(response?.data?.user_rating)
            setIsLoading(false)
        } catch (err) {
            console.error(err)
        }
    }

    useEffect(() => {
        fGetDetails()
    }, [])

    return {
        order,
        setOrders,
        isLoading,
        orderStatus,
        setOrderStatus,
        postRating,
        setPostRating,
        userRating,
        setUserRating,
    }
}

export default useOrderDetails
