/* eslint-disable @typescript-eslint/no-explicit-any */
const mainColor = "#ff7d02"

export const DOWNLOAD_JBF_ID = 10

export const selectStyle = {
    control: (baseStyle: any, state: any) => ({
        ...baseStyle,
        border: "1px solid #F7F7F7",
        borderRadius: "8px",
        boxShadow: "none",
        background: "#F7F7F7",
        paddingTop: "2px",
        paddingBottom: "2px",
        cursor: "pointer",
        "&:hover": {
            borderColor: state.isFocused ? mainColor : baseStyle.borderColor,
        },
    }),
}
