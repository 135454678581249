import { Header } from "pages/AdminPanelPage/AdminPanelPage.styled"

import ComplaintList from "./components/ComplaintList"

const Complaint = () => {
    return (
        <>
            <Header>Жалобы</Header>
            <ComplaintList />
        </>
    )
}

export default Complaint
