import { Field, Form, Formik, ErrorMessage } from "formik"
import { useContext, useState } from "react"
import * as Yup from "yup"

import { POST } from "modules/Core/api/REST"
import { AuthContent, FormItem, TextContainer } from "pages/AuthPage/AuthPage.styled"
import { Button } from "ui/Button/Button.styled"
import { renderError } from "utils/helpers"
import { InputComponent } from "utils/helpers/components/FormikField"

import { AuthFormContext } from "../../context/AuthFormContext"

const initialValues = { email: "" }
const validationSchema = Yup.object({
    email: Yup.string()
        .email("Введите корректный адрес эл.почты")
        .required("Поле E-mail обязательно для заполнения!"),
})

const ForgetPassword = () => {
    const [isLoading, setIsLoading] = useState(false)
    const authContext = useContext(AuthFormContext)
    const [success, setSuccess] = useState(false)

    const handleSubmit = (values: { email: string }) => {
        setIsLoading(true)
        sendRequest(values)
    }

    const sendRequest = async (values: { email: string }) => {
        try {
            const response = await POST("/api/accounts/password/reset", values)
            const { data } = response

            console.log(data)
            if (data.ok === 1) {
                setSuccess(true)
            } else {
                if (data.detail === "The user with emial not found")
                    authContext?.setErrorMessage("Email пользователь не найден!")
                else
                    authContext?.setErrorMessage(
                        "Непредвиденная ошибка при регистрации. Попробуйте позднее"
                    )
            }
        } catch (err) {
            authContext?.setErrorMessage(
                "Непредвиденная ошибка при регистрации. Попробуйте позднее"
            )
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            <Form>
                {success && (
                    <TextContainer align="center" color="green">
                        Проверьте Ваш email! Перейдите по ссылке, которую мы отправили Вам на
                        электронную почту
                    </TextContainer>
                )}

                <AuthContent>
                    <FormItem>
                        <label htmlFor="">E-mail</label>
                        <Field name="email" component={InputComponent} />
                        <ErrorMessage name="email" render={renderError} />
                    </FormItem>
                </AuthContent>

                <TextContainer align="center">
                    На Ваш электронный адрес будет направлено письмо для смены пароля
                </TextContainer>

                <Button type="submit" disabled={isLoading} style={{ marginTop: "1rem" }}>
                    Отправить на почту
                </Button>
            </Form>
        </Formik>
    )
}

export default ForgetPassword
